import React, { useEffect, useState, useMemo, useRef } from "react";
// import React, { Component } from 'react';
import { Form, Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import { adminInstance } from "../../../axios";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill,{Quill} from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./sendmail.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import axios from "axios";
import { server } from "../../../config/keys";



const GamePlatform = () => {
  
  const quill = useRef(null);
  const [loading, setLoading] = useState(false);
  const [rule, setRule] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState();
  // const [response, setResponse] = useState(false);

  // useEffect(() => {}, [response]);

  const [updateImage, setUpdateImage] = useState({
    imageURL: "",
    replaceImage: "",
  });

  const handleRule = (e) => {
    
    setRule(e.target.value);
    if (e.target.value === "NoActiveU") {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleSubjet = (e) => {
    console.log("e.target.value",e.target.value)
    setSubject(e.target.value);
  };
  // const handleMessage = (value) => {
  //   console.log("value",value);
  //   setMsg(value);
  // };
 const handleMessage = (event, editor) => {
  console.log("event, editor",event, editor)
    const data = editor && editor.getData();

    setMsg(data)
  };

  const apiPostNewsImage = async (formData, config) => {
    console.log("hiiii");
    const response = await adminInstance.post("/mailImage", formData, config);
    return response;
    // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
  };
  const    quillFonts  = ['arial', 'raleway', 'calibri',  'courier', 'georgia', 'lucida', 
  'open', 'roboto',  'tahoma',  'times',  'trebuchet',  'verdana'     ];

var fonts = Quill.import('formats/font');
fonts.whitelist = quillFonts;
  const imageHandler = async() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("name", "image")
    input.click();
    console.log(input)
    input.onchange = async () => {
      const file = input.files[0];
      const data = new FormData();
      console.log("file =>", file);
      data.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const Quill = quill.current.getEditor();
      const range = Quill.getSelection(true);
      const res = await apiPostNewsImage(data, config);
      console.log("res", res);
      Quill.insertEmbed(range.index, "image", res.data);
    };
  }
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [
          { header: "1" },
          { header: "2" },
          { header: [3, 4, 5, 6] },
        ],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "video"],
        [ "image", "video"],
        ["clean"],
        ["code-block"],
        [{ 'font': fonts.whitelist }]
      ],
      handlers: {
        'image': imageHandler
      },
    },
  }), [fonts.whitelist, imageHandler])

  console.log('msg',msg);
  function uploadAdapter (loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);
            console.log('hiieiee', file);
            
            console.log("whhhhhhh",body)   

            const response =  adminInstance.post("/mailImage", body);
            response.then(res => res.data)
              .then(res => {
                console.log('res1233', res);
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      rule,
      subject,
      msg,
      startDate,
    };
    console.log("payload",payload)
    if (rule === "") {
      toast.error("Please select Criteria.");
    } else {
      toast.success("Please wait, it may take some time...");
      setLoading(true);
      const { data } = await adminInstance.post("/sendMail", { payload });
      //  if (dummy.current) dummy.current.setContent();
      console.log("data-------", data);
      // setResponse(true);
      if (data.code === 200) {
        setRefresh(data);
        setSubject("");
        setMsg("");
        setLoading(false);

        toast.success("Email successfully sent.");
        window.location.reload(false);
      }
      if (data.code === 404) {
        setLoading(false);
        toast.error("Mail send Fail.");
      }
    }
  };

  const updateContentImage = (imageURL, replaceImage) => {
    setUpdateImage({ imageURL, replaceImage });
  };
  
  return (
    <div className="add-game-content">
      <div className="register-form">
        <Form onSubmit={handleSubmit}>
          <div className="row">
            {refresh ? (
              <div className="col-md-6">
                <Form.Group controlId="formBasicFirst">
                  <Form.Label>Select Recipients</Form.Label>
                  <Form.Control as="select" onChange={handleRule}>
                    <option key="0" value="no" selected>
                      Select Recipients
                    </option>
                    <option key="7" value="Allusers">
                      All Members
                    </option>
                    <option key="8" value="8">
                      Author
                    </option>
                    <option key="1" value="1">
                      Author Premium
                    </option>
                    <option key="17" value="17">
                      Author-ALL
                    </option>
                    <option key="3" value="3">
                      Blogger
                    </option>
                    <option key="6" value="6">
                      Blogger Premium
                    </option>
                    <option key="20" value="20">
                      Blogger-ALL
                    </option>
                    <option key="5" value="5">
                      PR Firm
                    </option>
                    <option key="2" value="2">
                      Reader
                    </option>
                    <option key="4" value="4">
                      Vendor
                    </option>
                    <option key="9" value="9">
                      Expired: ALL
                    </option>
                    <option key="16" value="16">
                      Expired: Author
                    </option>
                    <option key="10" value="10">
                      Expired: Author Premium
                    </option>
                    <option key="19" value="19">
                      Expired: Author-ALL
                    </option>

                    <option key="12" value="12">
                      Expired: Blogger
                    </option>
                    <option key="15" value="15">
                      Expired: Blogger Premium
                    </option>
                    <option key="18" value="18">
                      Expired: Blogger-ALL
                    </option>
                    <option key="14" value="14">
                      Expired: PR Firm
                    </option>
                    <option key="11" value="11">
                      Expired: Reader
                    </option>
                    <option key="13" value="13">
                      Expired: Vendor
                    </option>
                  </Form.Control>
                </Form.Group>
              </div>
            ) : (
              <div className="col-md-6">
                <Form.Group controlId="formBasicFirst">
                  <Form.Label>Select Recipients</Form.Label>
                  <Form.Control as="select" onChange={handleRule}>
                    <option key="0" value="no" selected>
                      Select Recipients
                    </option>
                    <option key="7" value="Allusers">
                      All Members
                    </option>
                    <option key="21" value="21">
                      Login User
                    </option>
                    <option key="26" value="26">
                      Cancelled Member
                    </option>
                    <option key="8" value="8">
                      Author
                    </option>
                    <option key="1" value="1">
                      Author Premium
                    </option>
                    <option key="17" value="17">
                      Author-ALL
                    </option>
                    <option key="3" value="3">
                      Blogger
                    </option>
                    <option key="6" value="6">
                      Blogger Premium
                    </option>
                    <option key="20" value="20">
                      Blogger-ALL
                    </option>
                    <option key="5" value="5">
                      PR Firm
                    </option>
                    <option key="2" value="2">
                      Reader
                    </option>
                    <option key="4" value="4">
                      Vendor
                    </option>
                    <option key="9" value="9">
                      Expired: ALL
                    </option>
                    <option key="16" value="16">
                      Expired: Author
                    </option>
                    <option key="10" value="10">
                      Expired: Author Premium
                    </option>
                    <option key="19" value="19">
                      Expired: Author-ALL
                    </option>

                    <option key="12" value="12">
                      Expired: Blogger
                    </option>
                    <option key="15" value="15">
                      Expired: Blogger Premium
                    </option>
                    <option key="18" value="18">
                      Expired: Blogger-ALL
                    </option>
                    <option key="14" value="14">
                      Expired: PR Firm
                    </option>
                    <option key="11" value="11">
                      Expired: Reader
                    </option>
                    <option key="13" value="13">
                      Expired: Vendor
                    </option>
                  </Form.Control>
                </Form.Group>
              </div>
            )}

            {show === true ? (
              <div className="col-md-6">
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </Form.Group>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Insert email subject....."
                  name="subject"
                  autoComplete="off"
                  value={subject}
                  onChange={(e) => handleSubjet(e)}
                  required
                />
              </Form.Group>
            </div>
    
            <div className="col-md-12">
              <Form.Group controlId="formBasicPassword" className="send-msg-editor">
                <Form.Label>Message</Form.Label>
                {/* <ReactQuill
                  ref={quill}

                 onChange={(e) => handleMessage(e)}
                  placeholder="message you want to send..."
                  modules={modules}
                /> */}
                 <CKEditor 
                        editor={FullEditor}
                        onChange={handleMessage}
                        config={{
                        extraPlugins: [uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            resizeOptions: [
                              {
                                name: 'resizeImage:original',
                                value: null,
                                label: 'Original'
                              },
                              {
                                name: 'resizeImage:40',
                                value: '40',
                                label: '40%'
                              },
                              {
                                name: 'resizeImage:60',
                                value: '60',
                                label: '60%'
                              }
                            ],
                            toolbar: [
                              'resizeImage'
                            ]
                          },
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',                                         
                          ],
                          supportAllValues: true
                        },
                        fontSize: {
                          options: [12,14, 16, 18, 20, 22, 24],
                      }
                        }}
                      />
                {/* <EditorToolbar toolbarId={"t1"} />
                <ReactQuill
                  theme="snow"
                  value={msg}
                  onChange={handleMessage}
                  placeholder={"message you want to send..."}
                  modules={modules("t1")}
                  formats={formats}
                /> */}

                {/* <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="message you want to send..."
                  name="msg"
                  autoComplete="off"
                  value={msg}
                  onChange={handleMessage}
                  required
                /> */}
                {/* <Editor
                 
                  selector="#editorBlast"
                  type="text"
                  class="form-control"
                  onChange={handleMessage}
                  id="email_body"
                  name="msg"
                  apiKey="02bmdr65eds9vmo76ewfyfqeibdtgp3ysm9e4s8z4btzizv4"
                 
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    file_picker_callback: function (callback, value, meta) {
                    
                      var input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "image/*");

                      input.onchange = async function () {
                        var file = this.files[0];

                        console.log(file);
                        const data = new FormData();
                        data.append("file", file);

                        const response = await adminInstance.post(
                          "/mailImage",
                          data
                        );

                        console.log(response);

                        var reader = new FileReader();
                        reader.onload = function () {
                         
                          var id = "blobid" + new Date().getTime();
                          var blobCache =
                            window.tinymce.activeEditor.editorUpload.blobCache;
                          var base64 = reader.result.split(",")[1];
                          var blobInfo = blobCache.create(id, file, base64);
                          blobCache.add(blobInfo);

                          console.log(this.result);
                          updateContentImage(response.data, this.result);

                     
                          callback(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                      };

                      input.click();
                    },

                    file_picker_types: "image",
                    toolbar:
                      "undo redo | formatselect | link image code |bold italic  | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | ",
                  }}
                /> */}
              </Form.Group>
            </div>

            <div className="col-md-12">
              <div className="text-right">
                <Button className="s-btn" type="submit" disabled={loading}>
                  {loading && <Spinner animation="grow" />}
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default GamePlatform;