import React, { useState } from "react";
import "../admin.css";
import AdminHeader from "../adminheader/header";
import AdminMenu from "../adminmenu/menu";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

const AdminWrapper = ({ children }) => {
  const [menutoggle, setMenuToggle] = useState(false);

  return (
    <div className="admin-wrapper">
      <Helmet>
        <body className="admin-view" />
      </Helmet>

      <div className="admin-content-view">
        <AdminMenu menutoggle={menutoggle} />

        <div
          className={`admin-container ${menutoggle ? `wrapper-expand` : ``}`}
        >
          <div className="header-view">
            <AdminHeader
              setMenuToggle={setMenuToggle}
              menutoggle={menutoggle}
            />
          </div>

          <div className="admin-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

AdminWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AdminWrapper;
