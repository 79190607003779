import * as constants from '../constants/userConstants';

export const myProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case constants.USER_DETAILS_REQUEST:
        return { loading: true };
      case constants.USER_DETAILS_SUCCESS:
        return { loading: false, userData: action.payload };
      case constants.USER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
        case '': 
        return {}
      default:
        return state;
    }
  };

  export const editProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case constants.EDIT_DETAILS_REQUEST:
        return { loading: true };
      case constants.EDIT_DETAILS_SUCCESS:
        return { loading: false, editData: action.payload };
      case constants.EDIT_DETAILS_FAIL:
        return { loading: false, error: action.payload };
        case '': 
        return {}
      default:
        return state;
    }
  };

export const entryFeeReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_ENTRY_FEE_REQUEST:
      return { loading: true };
    case constants.GET_ENTRY_FEE_SUCCESS:
      return { loading: false, entryFeeInfo: action.payload };
    case constants.GET_ENTRY_FEE_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
    default:
      return state;
  }
}

export const gameTypeReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_GAME_REQUEST:
      return { loading: true };
    case constants.GET_GAME_SUCCESS:
      return { loading: false, gameTypeInfo: action.payload };
    case constants.GET_GAME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gamePlatformReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_PLATFORM_REQUEST:
      return { loading: true };
    case constants.GET_PLATFORM_SUCCESS:
      return { loading: false, platformInfo: action.payload };
    case constants.GET_PLATFORM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gameVersionReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_GAME_VERSION_REQUEST:
      return { loading: true };
    case constants.GET_GAME_VERSION_SUCCESS:
      return { loading: false, gameVersionInfo: action.payload };
    case constants.GET_GAME_VERSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const uploadImageReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.USER_IMAGE_UPLOAD_REQUEST:
      return { loading: true };
    case constants.USER_IMAGE_UPLOAD_SUCCESS:
      return { loading: false, userImageInfo: action.payload };
    case constants.USER_IMAGE_UPLOAD_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
    default:
      return state;
  }
}



export const editGamerTagReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.EDIT_GAMERTAGS_REQUEST:
      return { loading: true };
    case constants.EDIT_GAMERTAGS_SUCCESS:
      return { loading: false, userTagInfo: action.payload };
    case constants.EDIT_GAMERTAGS_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
      default:
        return state;
    }
  }


export const gameVersionListReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GAME_VERSION_REQUEST:
      return { loading: true };
    case constants.GAME_VERSION_SUCCESS:
      return { loading: false, gameVersionInfo: action.payload };
    case constants.GAME_VERSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getGamerTagReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_GAMERTAGS_REQUEST:
      return { loading: true };
    case constants.GET_GAMERTAGS_SUCCESS:
      return { loading: false, userTagInfo: action.payload };
    case constants.GET_GAMERTAGS_FAIL:
      return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

export const getMessageReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.GET_MESSAGES_REQUEST:
      return { loading: true };
    case constants.GET_MESSAGES_SUCCESS:
      return { loading: false, chats : action.payload };
    case constants.GET_MESSAGES_FAIL:
      return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

export const userRecentTransactionListReducer = (state= { transactionList: [], totalCount: 0 }, action) => {
  switch (action.type) {
    case constants.USER_TRANSACTION_LIST_REQUEST:
      return { loading: true };
    case constants.USER_TRANSACTION_LIST_SUCCESS:
      return { loading: false, transactionList : action.payload.list, totalCount: action.payload.totalCount };
    case constants.USER_TRANSACTION_LIST_FAIL:
      return { loading: false, error: action.payload , transactionList: [], totalCount: 0 };
      default:
        return state;
    }
  }

export const updateKycReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.UPDATE_KYC_REQUEST:
      return { loading: true };
    case constants.UPDATE_KYC_SUCCESS:
      return { loading: false, msg: action.payload };
    case constants.UPDATE_KYC_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
      default:
        return state;
    }
  }
  
  export const instantMatchReducer = (state= {}, action) => {
    switch (action.type) {
      case constants.GET_INSTANT_MATCH_REQUEST:
        return { loading: true };
      case constants.GET_INSTANT_MATCH_SUCCESS:
        return { loading: false, list: action.payload };
      case constants.GET_INSTANT_MATCH_FAIL:
        return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

    export const myUnfinishedMatchesReducer = (state= {}, action) => {
      switch (action.type) {
        case constants.MY_UNFINISHED_MATCH_REQUEST:
          return { loading: true };
        case constants.MY_UNFINISHED_MATCH_SUCCESS:
          return { loading: false, unFinishedList: action.payload.matches, unfinishedTotalCount:action.payload.totalCount };
        case constants.MY_UNFINISHED_MATCH_FAIL:
          return { loading: false, error: action.payload };
          default:
            return state;
        }
      }
  
      export const myFinishedMatchesReducer = (state= {}, action) => {
        switch (action.type) {
          case constants.MY_FINISHED_MATCH_REQUEST:
            return { loading: true };
          case constants.MY_FINISHED_MATCH_SUCCESS:
            return { loading: false, list: action.payload.matches, totalCount:action.payload.totalCount };
          case constants.MY_FINISHED_MATCH_FAIL:
            return { loading: false, error: action.payload };
            default:
              return state;
          }
        }

export const unreadNotificationReducer = (state= {}, action) => {
  switch (action.type) {
    case constants.UNREAD_NOTIFY_REQUEST:
      return { loading: true };
    case constants.UNREAD_NOTIFY_SUCCESS:
      return { loading: false, list: action.payload.notifications, totalCount:action.payload.totalCount };
    case constants.UNREAD_NOTIFY_FAIL:
      return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export const singleMatchReducer = (state= {}, action) => {
    switch (action.type) {
      case constants.GET_MATCH_REQUEST:
        return { loading: true };
      case constants.GET_MATCH_SUCCESS:
        return { loading: false, matchInfo: action.payload };
      case constants.GET_MATCH_FAIL:
        return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

export const allLeaderboardReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.ALL_LEADERBOARD_REQUEST:
      return { loading: true };
    case constants.ALL_LEADERBOARD_SUCCESS:
      return { loading: false, playerInfo: action.payload.matches };
    case constants.ALL_LEADERBOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const gameLeaderboardReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.GAME_LEADERBOARD_REQUEST:
      return { loading: true };
    case constants.GAME_LEADERBOARD_SUCCESS:
      return { loading: false, playerInfo: action.payload.matches };
    case constants.GAME_LEADERBOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const recentFinishedMatchReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.RECENT_FINISHED_MATCH_REQUEST:
      return { loading: true };
    case constants.RECENT_FINISHED_MATCH_SUCCESS:
      return { loading: false, matches: action.payload.matches };
    case constants.RECENT_FINISHED_MATCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const voteDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.VOTE_DETAIL_REQUEST:
      return { loading: true };
    case constants.VOTE_DETAIL_SUCCESS:
      return { loading: false, voteInfo: action.payload };
    case constants.VOTE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const supportMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SUPPORT_MESSAGE_REQUEST:
      return { loading: true };
    case constants.SUPPORT_MESSAGE_SUCCESS:
      return { loading: false, chats: action.payload };
    case constants.SUPPORT_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const onlineUserReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.ONLINE_USER_REQUEST:
      return { loading: true };
    case constants.ONLINE_USER_SUCCESS:
      return { loading: false, onlineUsersList: action.payload };
    case constants.ONLINE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const challengeListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.CHALLENGE_LIST_REQUEST:
      return { loading: true };
    case constants.CHALLENGE_LIST_SUCCESS:
      return { loading: false, list: action.payload.list, count: action.payload.count };
    case constants.CHALLENGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const sendChallengeListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SEND_CHALLENGE_LIST_REQUEST:
      return { loading: true };
    case constants.SEND_CHALLENGE_LIST_SUCCESS:
      return { loading: false, list: action.payload.list, count: action.payload.count };
    case constants.SEND_CHALLENGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const chatUserListReducer = (state = {list:[]}, action) => {
  switch (action.type) {
    case constants.CHAT_USER_LIST_REQUEST:
      return { loading: true };
    case constants.CHAT_USER_LIST_SUCCESS:
      return { loading: false, list: action.payload};
    case constants.CHAT_USER_LIST_FAIL:
      return { loading: false, error: action.payload, list:[] };
    default:
      return state;
  }
}

export const recentChatsReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.RECENT_CHATS_REQUEST:
      return { loading: true };
    case constants.RECENT_CHATS_SUCCESS:
      return { loading: false, list: action.payload };
    case constants.RECENT_CHATS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const matchCardReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.MATCH_CARD_REQUEST:
      return { loading: true };
    case constants.MATCH_CARD_SUCCESS:
      return { loading: false, list: action.payload };
    case constants.MATCH_CARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const playerMatchCardReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.PLAYER_MATCH_CARD_REQUEST:
      return { loading: true };
    case constants.PLAYER_MATCH_CARD_SUCCESS:
      return { loading: false, list: action.payload };
    case constants.PLAYER_MATCH_CARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

