import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUsersDeletedList } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import UsersDeleted from "./usersDeleted";
import UserDeletedTable from "./userDeletedTable";
import { adminInstance } from "../../../axios";
import moment from "moment";

const AdminDeletedUser = () => {
  const [eventKey, setEventKey] = useState("list");
  const [deletedRequest, setDeletedRequest] = useState([]);
  const [deleteRequestInProcess, setDeletedRequestInProcess] = useState([]);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const handleTabSwitch = (k) => {
    setEventKey(k);
    // dispatch(getUsersDeletedList({ page: 1, pagination: 5 }));
  };

  const getDeletedRequest = async () => {
    const response = await adminInstance.get("/getAllDeletedRequest");
    const { list, code } = response.data;
    console.log("response", response.data);
    if (code === 200) {
      if (list) {
        console.log("list-0-----", list);
        setDeletedRequest(
          list.filter((el) => {
            return el.status === 0;
          })
        );
        setDeletedRequestInProcess(
          list.filter((el) => {
            return el.status === 1;
          })
        );
      }
    }
  };

  const handleModal = async (e, id) => {
    setShow(e);
    console.log("id", id);
    const response = await adminInstance.put("/updateDeleteRequest", {
      id,
    });
    const { code, msg } = response.data;
    if (code === 200) {
      getDeletedRequest();
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "userid",
    },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.membership_id === "1" ? "Author Premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger Premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author" : ""}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Date of request ",
      accessor: "created_date",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {moment(cell.row.values.created_date).format("MMM-DD-YYYY")}
          </span>
        );
      },
    },

    // {
    //   Header: "Status",
    //   accessor: "status",
    // },

    // {
    //   Header: "Action",
    //   accessor: "",
    //   Cell: (cell) => {
    //     return (
    //       <div className="action-dropdown action-buttons">
    //         <Button
    //           onClick={() => handleModal("delete", cell.row.values.userid)}
    //           //className="delete-btn"
    //         >
    //           Delete Request{" "}
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const column = [
    // {
    //   Header: "ID",
    //   accessor: "id",
    // },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.membership_id === "1" ? "Author Premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger Premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author" : ""}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Date of request",
      accessor: "created_date",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {moment(cell.row.values.created_date).format("MMM-DD-YYYY")}
          </span>
        );
      },
    },

    {
      Header: "Date OTR complied",
      accessor: "process_date",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {moment(cell.row.values.process_date).format("MMM-DD-YYYY")}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getDeletedRequest();
  }, []);

  return (
    <AdminWrapper>
      <div className="sub-admin">
        <div className="container">
          <h2 className="admin-title">Cancel Memberships</h2>
          <div className="admin-tab">
            <Tabs
              activeKey={eventKey}
              onSelect={(k) => handleTabSwitch(k)}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="list" title="Cancel membership request">
                <UserDeletedTable
                  columns={columns}
                  data={deletedRequest && deletedRequest}
                />
              </Tab>
              <Tab eventKey="add" title="Cancelled membership">
                <UserDeletedTable
                  columns={column}
                  data={deleteRequestInProcess && deleteRequestInProcess}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminDeletedUser;
