import * as constants from "../constants/adminConstants";

export const getCountReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.GET_COUNTS_REQUEST:
      return { loading: true };
    case constants.GET_COUNTS_SUCCESS:
      return { loading: false, countData: action.payload };
    case constants.GET_COUNTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addSubAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.ADD_SUBADMIN_REQUEST:
      return { loading: true };
    case constants.ADD_SUBADMIN_SUCCESS:
      return { loading: false, subAdminInfo: action.payload };
    case constants.ADD_SUBADMIN_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const subAdminListReducer = (state = { list: [], total: 0 }, action) => {
  switch (action.type) {
    case constants.SUBADMIN_LIST_REQUEST:
      return { loading: true };
    case constants.SUBADMIN_LIST_SUCCESS:
      return { loading: false, subAdminList: action.payload };
    case constants.SUBADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const blockSubAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.BLOCK_SUBADMIN_REQUEST:
      return { loading: true };
    case constants.BLOCK_SUBADMIN_SUCCESS:
      return { loading: false, blockAdminStatus: action.payload };
    case constants.BLOCK_SUBADMIN_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { list: [], total: 0 }, action) => {
  switch (action.type) {
    case constants.USER_LIST_REQUEST:
      return { loading: true };
    case constants.USER_LIST_SUCCESS:
      return { loading: false, userList: action.payload };
    case constants.USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const memberDeletedListReducer = (
  state = { list: [], total: 0 },
  action
) => {
  switch (action.type) {
    case constants.DELETED_MEMBER_LIST_REQUEST:
      return { loading: true };
    case constants.DELETED_MEMBER_LIST_SUCCESS:
      return { loading: false, memberDeleted: action.payload };
    case constants.DELETED_MEMBER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteSubAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.DELETE_SUBADMIN_REQUEST:
      return { loading: true };
    case constants.DELETE_SUBADMIN_SUCCESS:
      return { loading: false, deleteAdminStatus: action.payload };
    case constants.DELETE_SUBADMIN_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.DELETE_USER_REQUEST:
      return { loading: true };
    case constants.DELETE_USER_SUCCESS:
      return { loading: false, deleteUserStatus: action.payload };
    case constants.DELETE_USER_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const blockUserReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.BLOCK_USER_REQUEST:
      return { loading: true };
    case constants.BLOCK_USER_SUCCESS:
      return { loading: false, blockUserStatus: action.payload };
    case constants.BLOCK_USER_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const adminforgetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FORGET_PASSWORD_REQUEST:
      return { loading: true };
    case constants.FORGET_PASSWORD_SUCCESS:
      return {
        loading: false,
        forgetInfo: {
          email: action.payload.email,
          username: action.payload.username,
          msg: action.payload.msg,
        },
      };
    case constants.FORGET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const adminverifyOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.VERIFY_OTP_REQUEST:
      return { loading: true };
    case constants.VERIFY_OTP_SUCCESS:
      return {
        loading: false,
        otpInfo: {
          email: action.payload.email,
          username: action.payload.username,
        },
      };
    case constants.VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const adminnewPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.NEW_PASSWORD_REQUEST:
      return { loading: true };
    case constants.NEW_PASSWORD_SUCCESS:
      return {
        loading: false,
        resetMsg: action.payload,
      };
    case constants.NEW_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const editAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.EDIT_ADMIN_REQUEST:
      return { loading: true };
    case constants.EDIT_ADMIN_SUCCESS:
      return {
        loading: false,
        resetMsg: action.payload,
      };
    case constants.EDIT_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const adminProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.MY_DETAILS_REQUEST:
      return { loading: true };
    case constants.MY_DETAILS_SUCCESS:
      return { loading: false, userData: action.payload };
    case constants.MY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editAdminProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.EDIT_DETAILS_REQUEST:
      return { loading: true };
    case constants.EDIT_DETAILS_SUCCESS:
      return { loading: false, editData: action.payload };
    case constants.EDIT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const changeAdminPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case constants.CHANGE_PASSWORD_SUCCESS:
      return { loading: false, editData: action.payload };
    case constants.CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case "":
      return {};
    default:
      return state;
  }
};

export const getAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.GET_ADMIN_REQUEST:
      return { loading: true };
    case constants.GET_ADMIN_SUCCESS:
      return { loading: false, adminDetails: action.payload };
    case constants.GET_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allRecentTransactionListReducer = (
  state = { transactionList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.RECENT_TRANSACTION_LIST_REQUEST:
      return { loading: true };
    case constants.RECENT_TRANSACTION_LIST_SUCCESS:
      return {
        loading: false,
        transactionList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.RECENT_TRANSACTION_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        transactionList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const paymentTransactionListReducer = (
  state = { transactionList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.PAYMENT_TRANSACTION_LIST_REQUEST:
      return { loading: true };
    case constants.PAYMENT_TRANSACTION_LIST_SUCCESS:
      return {
        loading: false,
        transactionList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.PAYMENT_TRANSACTION_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        transactionList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const getKycListReducer = (
  state = { kycList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.GET_KYC_REQUEST:
      return { loading: true };
    case constants.GET_KYC_SUCCESS:
      return {
        loading: false,
        kycList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.GET_KYC_FAIL:
      return {
        loading: false,
        error: action.payload,
        transactionList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const getKycVerifiedListReducer = (
  state = { kycList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.GET_KYC_VERIFIED_REQUEST:
      return { loading: true };
    case constants.GET_KYC_VERIFIED_SUCCESS:
      return {
        loading: false,
        kycList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.GET_KYC_VERIFIED_FAIL:
      return {
        loading: false,
        error: action.payload,
        transactionList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const getKycRejectedListReducer = (
  state = { kycList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.GET_KYC_REJECTED_REQUEST:
      return { loading: true };
    case constants.GET_KYC_REJECTED_SUCCESS:
      return {
        loading: false,
        kycList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.GET_KYC_REJECTED_FAIL:
      return {
        loading: false,
        error: action.payload,
        kycList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const getWithdrawListReducer = (
  state = { withdrawList: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.GET_WITHDRAW_REQUEST:
      return { loading: true };
    case constants.GET_WITHDRAW_SUCCESS:
      return {
        loading: false,
        WithdrawList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.GET_WITHDRAW_FAIL:
      return {
        loading: false,
        error: action.payload,
        WithdrawList: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const getMatchesListReducer = (
  state = { Matches: [], totalCount: 0 },
  action
) => {
  switch (action.type) {
    case constants.GET_MATCHING_REQUEST:
      return { loading: true };
    case constants.GET_MATCHING_SUCCESS:
      return {
        loading: false,
        Matches: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.GET_MATCHING_FAIL:
      return {
        loading: false,
        error: action.payload,
        Matches: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

export const unreadNotificationAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.UNREAD_ADMIN_NOTIFY_REQUEST:
      return { loading: true };
    case constants.UNREAD_ADMIN_NOTIFY_SUCCESS:
      return {
        loading: false,
        list: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case constants.UNREAD_ADMIN_NOTIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const disputeMatchListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.GET_DISPUTE_MATCHES_REQUEST:
      return { loading: true };
    case constants.GET_DISPUTE_MATCHES_SUCCESS:
      return {
        loading: false,
        Matches: action.payload.list,
        totalCount: action.payload.total,
      };
    case constants.GET_DISPUTE_MATCHES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const supportUserReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SUPPORT_USER_REQUEST:
      return { loading: true };
    case constants.SUPPORT_USER_SUCCESS:
      return { loading: false, users: action.payload };
    case constants.SUPPORT_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const blastListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.BLAST_LIST_REQUEST:
      return { loading: true };
    case constants.BLAST_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case constants.BLAST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const expiredMembershipListReducer = (state = {}, action) => {
  console.log("action.payload", action.payload);
  switch (action.type) {
    case constants.EXPIRED_MEMBERSHIP_LIST_REQUEST:
      return { loading: true };
    case constants.EXPIRED_MEMBERSHIP_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case constants.EXPIRED_MEMBERSHIP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListByIdReducer = (state = {}, action) => {
  console.log("action.payload", action.payload);
  switch (action.type) {
    case constants.USER_LIST_BY_ID_REQUEST:
      return { loading: true };
    case constants.USER_LIST_BY_ID_SUCCESS:
      return {
        loading: false,
        userListId: action.payload.list,
        total: action.payload.total,
      };
    case constants.USER_LIST_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const policyListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.POLICY_LIST_REQUEST:
      return { loading: true };
    case constants.POLICY_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case constants.POLICY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const followListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FOLLOW_LIST_REQUEST:
      return { loading: true };
    case constants.FOLLOW_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case constants.FOLLOW_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const blastListByIdReducer = (state = {}, action) => {
  console.log("action.payload", action.payload, action.type);
  switch (action.type) {
    case constants.BLAST_LIST_BY_ID_REQUEST:
      return { loading: true };
    case constants.BLAST_LIST_BY_ID_SUCCESS:
      return {
        loading: false,
        blastListId: action.payload.list,
        total: action.payload.total,
      };
    case constants.BLAST_LIST_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const advertiseListReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.ADVERTISE_LIST_REQUEST:
      return { loading: true };
    case constants.ADVERTISE_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case constants.ADVERTISE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const advertiseListByIdReducer = (state = {}, action) => {
  console.log("action.payload", action.payload, action.type);
  switch (action.type) {
    case constants.ADVERTISE_LIST_BY_ID_REQUEST:
      return { loading: true };
    case constants.ADVERTISE_LIST_BY_ID_SUCCESS:
      return {
        loading: false,
        advertiseListId: action.payload.list,
        total: action.payload.total,
      };
    case constants.ADVERTISE_LIST_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
