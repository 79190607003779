import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUsersDeletedList } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import {  Modal, Form } from "react-bootstrap";
import UsersDeleted from "./usersDeleted";
import UserDeletedTable from "./userDeletedTable";
import CancelUserManual from "./cancelUserManualRequest"
import { adminInstance } from "../../../axios";
import moment from "moment";
import toast from "react-hot-toast";
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);



const AdminDeletedUser = () => {
  
const initialValues = {
  username: "",
  memberid:"",
  email: "",
  date:"",
  note:"",
  manual:"",
  receivemail:""
};
  const [eventKey, setEventKey] = useState("list");
  const [deletedRequest, setDeletedRequest] = useState([]);
  const [deleteRequestInProcess, setDeletedRequestInProcess] = useState([]);
  const [sourceRequest, setSourceRequest] = useState([]);
  const [show, setShow] = useState(false);
  const [selectDelete,setSelectDelete] = useState(false);
  const [selectId,setPopId] =useState(initialValues);


console.log('sdgasdghasds0',selectId)
const [values, setValues] = useState(initialValues);

const handleChangeDelete = (e) => {
  const { name, value } = e.target;
  console.log("jjjjjjj==>>>>>>>>>",selectId,name, value)

  setPopId({
    ...selectId,
    [name]: value,
  });
};

  const dispatch = useDispatch();

  const handleTabSwitch = (k) => {

     
    setEventKey(k);
    if(k==='list')
    {
      getDeletedRequest();
    }
   
    if(k==='showEditManual')
    {
      getSourceDetails();
    }
   
 
    // getHandleRequest();
    // dispatch(getUsersDeletedList({ page: 1, pagination: 5 }));
  };
  const handleclick =async(e)=>{
    console.log("helllo",e)
    try{
    const data1 =e;
    const   {data} = await adminInstance.get(`/getuserbyid/${data1}`);
   
    // const   {data} = await adminInstance.get('/getuserbyid', {data1});
     
    console.log("response11123132", data && data.data[0]);
    setPopId(data.data[0])
    setSelectDelete(true)
    }catch (e) {
      toast.error( e && e.response.data.err);
     
    }


  }
  

  const getHandleRequest = async(e) =>{
    try{
    e.preventDefault();
       console.log("selectId",selectId)
    // const { membership,username,email } = values;
    let email = selectId?.email ? selectId.email:'null';
    let username = selectId?.username ? selectId.username:'null';
    let member_id = selectId?.memberid ? selectId.memberid:'null';

    let receivemail = selectId?.receivemail ? selectId.receivemail:'';
    
    console.log("myvalues",selectId.id)
    const response = await adminInstance.put(`/updateusermanual/${email}/${username}/${member_id}/${receivemail}`);
    console.log("response",response);
    setShow(false)
    setSelectDelete(false)
    getSourceDetails();
    }catch (e) {
      toast.error( e && e.response.data.err);
      //setValues(initialValues);
    }

  }
  const handleCalendarDate =(date) => {
 
    let mydate = moment(date).format('YYYY-MM-DD');
    
      setValues({
        ...values,
        date: mydate,
      });
    }
  const getDeletedRequest = async () => {
    const response = await adminInstance.get("/getAllDeletedRequest");
    const { list, code } = response.data;
    console.log("response", response.data);
    if (code === 200) {
      if (list) {
        console.log("list-0-----", list);
        setDeletedRequest(
          list.filter((el) => {
            return el.deleted === 0;
          })
        );
        setDeletedRequestInProcess(
          list.filter((el) => {
            return el.deleted === 1;
          })
        );
      }
    }
  };

   const handleClose = () => {
 
    
    setSelectDelete(false)

  };

  const getSourceDetails = async () => {
    const response = await adminInstance.get("/getSourceDetails");
    const { list, code } = response.data;
    console.log("response12", response.data);
    if(code === 200)
    {
      setSourceRequest(list)
    }
  
  };

  const handleModal = async (e, id) => {
    setShow(e);
    setSelectDelete(e)
    console.log("id", id);
    const response = await adminInstance.put("/updateDeleteRequest", {
      id,
    });
    const { code, msg } = response.data;
    if (code === 200) {
      getDeletedRequest();
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "userid",
    },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Member email",
      accessor: "email",
    },
    {
      Header: "Provided email",
      accessor: "receivemail",
    },
    {
      Header: "Type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.membership_id === "1" ? "Author Premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger Premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author" : ""}
          </span>
        );
      },
    },
    
    {
      Header: "Date of request ",
      accessor: "cdate",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {console.log("hi jhone",cell.row.values)}
            {moment(cell.row.values.cdate).format(
              "MMM-DD-YYYY"
            )}
          </span>
        );
      },
    },
    {
      Header: "Source",
      accessor: "source",
      Cell: (cell) => {
        return (
          <span>
          {cell.row.values.source === undefined ? '': cell.row.values.source ==="2" ? 'Certified mail (signed for)':
          cell.row.values.source ==="3"?"Express package delivery service":
          cell.row.values.source ==="1" ?"Postal mail":
          cell.row.values.source ==="4" ? "Email to: otrlegal@ontargetromance.com":
          cell.row.values.source ==="5" ? "Email to: 2otrlegal@gmail.com" :
          cell.row.values.source ==="6"? "Message via Contact us (non logged in)" :
          cell.row.values.source ==="7" ? "Message via Contact us (logged-in)":     
          'OTR Website'}
          </span>
        );
      },
     
    },
  
  
    {
      Header: "Notes",
      accessor: "note",
    },

    // {
    //   Header: "Status",
    //   accessor: "status",
    // },

    // {
    //   Header: "Action",
    //   accessor: "",
    //   Cell: (cell) => {
    //     return (
    //       <div className="action-dropdown action-buttons">
            
    //         <Button
    //           onClick={() => handleModal("delete", cell.row.values.userid)}
    //           //className="delete-btn"
    //         >
    //           Delete {" "}
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const column = [
    // {
    //   Header: "ID",
    //   accessor: "id",
    // },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
   
    {
      Header: "Member email",
      accessor: "email",
    },
    {
      Header: "Provided email",
      accessor: "receivemail",
    },
    {
      Header: "Type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.membership_id === "1" ? "Author Premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger Premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author" : ""}
          </span>
        );
      },
    },
    {
      Header: "Date of request",
      accessor: "cdate",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {cell.row.values.cdate}
          </span>
        );
      },
    },

    {
      Header: "Date OTR complied",
      accessor: "delete_member",
      Cell: (cell) => {
        return (
          <span>
            {" "}
            {cell.row.values.delete_member &&
              moment(cell.row.values.delete_member).format("MMM-DD-YYYY")}
          </span>
        );
      },
    },
    {
      Header: "Source",
      accessor: "source",
      Cell: (cell) => {
        return (
          <span>
          {cell.row.values.source === undefined ? '': cell.row.values.source ==="2" ? 'Certified mail (signed for)':
          cell.row.values.source ==="3"?"Express package delivery service":
          cell.row.values.source ==="1" ?"Postal mail":
          cell.row.values.source ==="4" ? "Email to: otrlegal@ontargetromance.com":
          cell.row.values.source ==="5" ? "Email to: 2otrlegal@gmail.com" :
          cell.row.values.source ==="6"? "Message via Contact us (non logged in)" :
          cell.row.values.source ==="7" ? "Message via Contact us (logged-in)":     
          'OTR Web'}
          </span>
        );
      },
     
    },
    {
      Header: "Notes",
      accessor: "note",
    },
  ];
  const columnsource = [
    // {
    //   Header: "ID",
    //   accessor: "id",
    // },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "MemberID",
      accessor: "memberid",
    },

    {
      Header: "Member email",
      accessor: "email",
    },
    {
      Header: "Provided email",
      accessor: "receivemail",
    },
    {
      Header: "Type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.membership_id === "1" ? "Author Premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger Premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author" : ""}
          </span>
        );
      },
    },
    {
      Header: "Date of request",
      accessor: "date",
  
    },
   
    {
      Header: "Source",
      accessor: "source",
      Cell: (cell) => {
        return (
          <span>
          {cell.row.values.source === undefined ? '': cell.row.values.source ==="2" ? 'Certified mail (signed for)':
          cell.row.values.source ==="3"?"Express package delivery service":
          cell.row.values.source ==="1" ?"Postal mail":
          cell.row.values.source ==="4" ? "Email to: otrlegal@ontargetromance.com":
          cell.row.values.source ==="5" ? "Email to: 2otrlegal@gmail.com" :
          cell.row.values.source ==="6"? "Message via Contact us (non logged in)" :
          cell.row.values.source ==="7" ? "Message via Contact us (logged-in)":     
          'OTR Website'}
          </span>
        );
      },
     
    },
    {
      Header: "Notes",
      accessor: "note",
    },
  
        {
      Header: "Action",
      accessor: "",
      Cell: (cell) => {
        return (
          <div className="action-dropdown action-buttons">
             {console.log("hello")}
            <Button
              onClick={() => handleclick( cell.row.values.receivemail)}
              //className="delete-btn"
            >
              Edit {" "}
            </Button>
          </div>
        );
      },
    },
  

  ];

  useEffect(() => {
    getDeletedRequest();
    getSourceDetails();
  }, []);
  

  return (
    <AdminWrapper>
      <div className="sub-admin">
        <div className="container">
          <h2 className="admin-title">Delete Request</h2>
          {console.log("sourceRequest",selectId)}
       
               {/* <div >
            
                 <Button
                    //  onClick={() => handleModal("delete", cell.row.values.userid)}
                  className="delete-btn"
                 >
                 Delete {" "}
                 </Button>
                   </div> */}
          <div className="admin-tab">
      
            <Tabs
              activeKey={eventKey}
              onSelect={(k) => handleTabSwitch(k)}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="list" title="Pending Delete Requests">
                <UserDeletedTable
                  columns={columns}
                  data={deletedRequest && deletedRequest}
                />
              </Tab>
              <Tab eventKey="add" title="Deleted users">
                <UserDeletedTable
                  columns={column}
                  data={deleteRequestInProcess && deleteRequestInProcess}
                />
              </Tab>
              <Tab eventKey="addManual" title="Add Delete Request (manual)">
                <CancelUserManual/>
              </Tab>
              <Tab eventKey="showEditManual" title="Incomplete Delete Requests">
              <UserDeletedTable
                  columns={columnsource}
                  data={sourceRequest && sourceRequest}
                />
              </Tab>
              
              
            
            </Tabs>
          </div>
          <Modal
        className="login-popup delete-req"
        show={selectDelete === true}
        onHide={() => handleClose(false)}
      >
        
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h2 className="admin-title">
       
       Delete Request</h2>
        <label>
          Request Source:
        </label>
        <select
                      class="form-control"
                      // onChange={(e)=>handleclick(e)}
                      value={selectId && selectId.source}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                  
                   <option value="1">Postal mail</option>
                    <option value="2">Certified mail (signed for)</option>
                     <option value="3">Express package delivery service</option>
                    <option value="4">Email to: otrlegal@ontargetromance.com</option>
                    <option value="5">Email to: 2otrlegal@gmail.com</option>
                   <option value="6">Message via Contact us (non logged in)</option>
                    <option value="7">Message via Contact us (logged-in)</option>
             
                    </select>
         
      
                 <Form onSubmit={getHandleRequest}>
              <Form.Group className="my-3" controlId="formBasicName">
                <Form.Label>Username:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  onChange={handleChangeDelete}
                  value={selectId && selectId.username}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Membership ID:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter membership"
                  name="memberid"
                  onChange={handleChangeDelete}
                  value={selectId && selectId.memberid}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Membership email:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter receive"
                  name="receivemail"
                  // onBlur={handleOnBlure}
                  onChange={handleChangeDelete}
                  value={selectId && selectId.receivemail}
                  required
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email provided:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChangeDelete}
                  value={selectId && selectId.email}
                />
              </Form.Group>
              <Form.Group className="mb-3 delreq-date" controlId="formBasicDate">
                <Form.Label>Date:</Form.Label>
                {/* <Form.Control
                  type="date"
                 
                  name="date"
                  onChange={handleChangeDelete}
                  value={selectId && selectId.date}
                /> */}


<DatePicker
                      
                      format="YYYY-MM-DD"
                      placeholderText="mm-dd-yy"
                      highlightDates={new Date()}
                     maxDate={moment().toDate()}
                     //selected={values.date}
                     name="date"
                     locale="en-gb"
                      onChange={(date)=>handleCalendarDate(date)}
                      // value={selectId && selectId.date}
                     value={selectId && selectId.date === "" ? "" : moment(selectId && selectId.date).format(
                        'MM-DD-YYYY'
                      )}
                   
                   
            
                    />

              </Form.Group>



              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Note:</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter note"
                  name="note"
                  onChange={handleChangeDelete}
                  value={selectId && selectId.note}
        
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
        </Modal.Body>
      </Modal>
        </div>
      </div>
   
    </AdminWrapper>
  );
};

export default AdminDeletedUser;
