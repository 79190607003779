import React, { useEffect, useState } from "react";
import menu from "../../../assets/admin/menu-icon.png";
import admin from "../../../assets/user.png";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { DropdownButton, Modal, Dropdown, Badge } from "react-bootstrap";
import isEmpty from "lodash.isempty";
import {
  getMyDetails,
  getUnReadNotificationAdmin,
} from "../../../actions/adminAction";
import { socket } from "../../../config/socket";
import Notification from "../notification";
import Loader from "../../loader/loader";
import user from "../../../assets/user.png";
import { server } from "../../../config/keys";

const AdminHeader = ({ setMenuToggle, menutoggle }) => {
  const [show, setShow] = useState(false);

  const myProfileData = useSelector((state) => state.adminLogin);
  console.log("myProfileData", myProfileData);
  const { error, adminInfo, loading } = myProfileData;

  const myData = useSelector((state) => state.adminProfile);
  const { userData } = myData;
  console.log("userData", userData);
  // console.log('adminInfo',adminInfo);
  // console.log('photo',`${server}/${adminInfo.photo}`);
  const [preview, setPreview] = useState(false);

  const unreadNotification = useSelector(
    (state) => state.unreadNotificationAdmin
  );

  const { totalCount } = unreadNotification;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(myData)) {
      dispatch(getMyDetails());
    }
  }, [dispatch, myData, error, userData]);

  const handleLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.clear();
    window.location.href = "/admin/login";
  };

  const handleModal = (e) => {
    setShow(e);
  };
  return (
    <div className="admin-header">
      {loading && <Loader />}

      <div className="menu-toggle">
        <span onClick={() => setMenuToggle(!menutoggle)}>
          <img src={menu} alt="Menu" />
        </span>
      </div>

      <div className="admin-info">
        {console.log("userphoto", userData && userData.photo)}
        <img
          src={
            userData && userData.photo !== "admin/default.png" && userData.photo
              ? `${server}/${userData && userData.photo}`
              : user
            // adminInfo && adminInfo.photo ? `${server}/${adminInfo.photo}` : user
          }
        />
        <DropdownButton
          id="dropdown-basic-button"
          title={adminInfo && adminInfo.username}
        >
          <Dropdown.Item href="/admin/profile">My Profile</Dropdown.Item>
          {/* <Dropdown.Item href="/setting">Setting</Dropdown.Item> */}
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </DropdownButton>
        <div className="p-name notification-icon">
          <h6 onClick={() => handleModal("notification")}>
            <i className="fa fa-bell">
              {totalCount && totalCount !== 0 ? (
                <Badge pill variant="danger">
                  {totalCount}
                </Badge>
              ) : (
                ""
              )}
            </i>
          </h6>
        </div>
      </div>

      <Modal
        className="login-popup notification-popup"
        show={show === "notification"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Notification handleModal={handleModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

AdminHeader.propTypes = {
  setMenuToggle: PropTypes.func,
  menutoggle: PropTypes.bool,
};

export default AdminHeader;
