import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import AdminWrapper from "../adminwrapper/wrapper";
import {
  getAllRecentTransactionList,
  getPaymentTransction,
} from "../../../actions/adminAction";
import Loader from "../../loader/loader";
import { Tab, Tabs } from "react-bootstrap";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import AdminTransTable from "./adminTransTable";

const AdminTransaction = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [paymentPage, setPaymentPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const recentTransactionListData = useSelector(
    (state) => state.allRecentTransactionList
  );
  const { transactionList, totalCount, loading } = recentTransactionListData;
  console.log("transactionList", transactionList);
  const paymentTransactionList = useSelector(
    (state) => state.paymentTransactionList
  );
  const {
    transactionList: paymentList,
    totalCount: paymentCount,
    loading: paymentLoading,
  } = paymentTransactionList;

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
  };
  useEffect(() => {
    //  dispatch(getAllRecentTransactionList(page, pagination, filterValue));
    dispatch(getAllRecentTransactionList());
    setShow(true);
  }, [dispatch, page, pagination, filterValue]);

  const columns = [
    {
      Header: "Count",
      accessor: "count_country",
    },
    {
      Header: "Country",
      accessor: "name",
    },
  ];

  const handleFilter = (e) => {
    setFilterValue(e);
  };

  return (
    <AdminWrapper>
      <div className="admin-transaction">
        <div className="container">
          <h2 className="admin-title">Country List</h2>
          <div className="admin-tab">
            <div className="transaction-search-box">
              <div className="user-search-section">
                <h2 className="admin-title">{""}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {loading ? (
                  <Loader />
                ) : (
                  transactionList && (
                    <AdminTransTable
                      columns={columns}
                      data={transactionList && transactionList}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminTransaction;
