import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUnReadNotificationAdmin } from '../../../actions/adminAction';
import logoIcon from '../../../assets/logo-icon.png'
import userImg from '../../../assets/user.png'
import { adminInstance } from '../../../axios';

const Notification = () => {
    const dispatch = useDispatch(); 
    const unreadNotification = useSelector(state => state.unreadNotificationAdmin);
    const { list } = unreadNotification;
    const readNotification = async(id, link) => {
        window.location.href = link;
        const res = await adminInstance.post('/readNotification', { notificationId: id });
        if( res.data.code === 200)
        dispatch(getUnReadNotificationAdmin(1, 10))
    }

    const removeNotification = async (id) => {
        const res = await adminInstance.post('/user/removeNotification', { notificationId: id });
        if (res.data.code === 200)
            dispatch(getUnReadNotificationAdmin(1, 10));
    }
    return (
        <div>
                {list && list.length !== 0 ? list.map((item, i) => (
            <div key={`item-${i}`} className="notification" onClick={() => readNotification(item._id, item.link)}>
                <div className="profile-image">
                    <img src={item.fromAdmin ? logoIcon : item.photo !== 'user/default.png'? item.photo: userImg } alt="user" />
                    </div>
                    <div className="notification-text">
                <h6>{item.msg}</h6>
                <p>{item.createdAt.substring(0,10)} <span>{new Date(item.createdAt).toLocaleTimeString()}</span></p>
                </div>
                    <span className="close-noti" onClick={() => removeNotification(item._id)}>x</span>
            </div>
        )) : (
            <div className="notification">
                <div className="notification-text text-center">
            <h6>No Unread notifications</h6>
            </div>
        </div>
        )}
           
        </div>
    )
}

export default Notification
