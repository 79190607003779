import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AdminWrapper from "../adminwrapper/wrapper";
// import DisputeMatches from "./DisputeMatches";
import AdminMatchmaking from "./matchmaking";

const AdminMatchList = () => {
  const [eventKey, setEventKey] = useState("list");
  const [keyword] = useState();

  const handleTabSwitch = (k) => {
    setEventKey(k);
  };
  return (
    <AdminWrapper>
      <div className="sub-admin">
        <div className="container">
          <h2 className="admin-title">Events</h2>

          {/* <div className="admin-tab">
            <div className="user-search">
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="search"
                  placeholder="Search Here..."
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Form.Group>
            </div>
          </div> */}
          <div className="admin-tab">
            <div className="transaction-search-box">
              {/* <div className="user-search-section transaction-search">
              <div className="user-search">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="search"
                    placeholder="Search Here..."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </Button>
              </div>
            </div> */}

              <AdminMatchmaking dataa={keyword} />
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminMatchList;
