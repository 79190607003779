import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { addSubAdmin } from "../../../actions/adminAction";

const AddSubAdmin = ({ handleTabSwitch }) => {
  const { register, handleSubmit, errors, reset } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();
  const [rolesError, setRolesError] = useState(false);
  const [userRoles, setUserRoles] = useState({});
  const addSubAdminData = useSelector((state) => state.addSubAdmin);
  const { loading, error, subAdminInfo } = addSubAdminData;

  const customStyles = {
    option: (provided) => ({
      ...provided,
      borderBottom: "1px solid #efefef",
      color: "#333",
      backgroundColor: "transparent",
    }),
    control: () => ({
      border: "1px solid #343842",
      borderRadius: ".25rem",
      display: "flex",
      padding: "2px 10px",
      backgroundColor: "#383a44",
      color: "#fff",
    }),
  };

  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    if (subAdminInfo) {
      toast.success(subAdminInfo, { id: "A" });
      handleTabSwitch("list");
      reset();
    }
  }, [error, subAdminInfo, reset, handleTabSwitch]);

  const addSubAdminHandler = (values) => {
    if (
      !values.isSuperAdmin &&
      JSON.stringify(userRoles) === JSON.stringify({})
    ) {
      setRolesError(true);
    } else {
      setRolesError(false);
    }

    if (
      values.isSuperAdmin ||
      !(JSON.stringify(userRoles) === JSON.stringify({}))
    ) {
      dispatch(
        addSubAdmin({
          // isSuperAdmin: values.isSuperAdmin,
          email: values.email,
          password: values.password,
          username: values.username,
          role: values.isSuperAdmin ? "superadmin" : JSON.stringify(userRoles),
        })
      );
    }
  };

  const handleCheckBox = (e) => {
    const isCheck = e.target.checked;
    const value = e.target.value;
    if (isCheck) {
      setUserRoles({ ...userRoles, [value]: value });
    } else {
      delete userRoles[value];
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="add-subadmin-form">
          <div className="register-form">
            <Form onSubmit={handleSubmit(addSubAdminHandler)}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="username"
                      autoComplete="off"
                      ref={register({ required: true })}
                    />
                    {errors.username && (
                      <Alert variant="danger">
                        {errors.username.type === "maxLength"
                          ? "Max length is 19"
                          : "This Field Required"}
                      </Alert>
                    )}
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      ref={register({
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Not a valid email",
                        },
                      })}
                    />
                    {errors.email && (
                      <Alert variant="danger">
                        {errors.email.type === "pattern"
                          ? "Not a valid Email"
                          : "This field is required"}
                      </Alert>
                    )}
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="password"
                      name="password"
                      autoComplete="off"
                      ref={register({
                        required: true,
                        pattern: {
                          value: new RegExp(
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                          ),
                          message:
                            "Use 8 or more characters with a mix of letters, numbers & symbols",
                        },
                      })}
                    />
                    {errors.password && (
                      <Alert variant="danger">
                        {errors.password.type === "pattern"
                          ? "Use 8 or more characters with a mix of capital & small letters, numbers & symbols"
                          : "This field is requried"}
                      </Alert>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <h5 className="sub-admin-roles">Roles</h5>
                </div>
                <div className="col-md-12 mb-2">
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="dashboard"
                          value="dashboard"
                          label="DASHBOARD"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="countryList"
                          value="countryList"
                          label="COUNTRY LIST"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="sendMail"
                          value="sendMail"
                          label="SEND MAIL"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="member"
                          value="member"
                          label="MEMBERS"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="follow"
                          value="follow"
                          label="FOLLOW"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="events"
                          label="EVENTS"
                          value="events"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="blasts"
                          label="BLASTS"
                          value="blasts"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="advertise"
                          label="ADVERTISE"
                          value="advertise"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="consentPolicies"
                          label="CONSENT POLICIES"
                          value="consentPolicies"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="addPolicies"
                          label=" ADD POLICIESS"
                          value="addPolicies"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="deleteRequests"
                          label="DELETE REQUESTS"
                          value="deleteRequests"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="expiredMemnberships"
                          label="EXPIRED MEMBERSHIPS"
                          value="expiredMemnberships"
                          onChange={(e) => handleCheckBox(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {rolesError && (
                    <Alert variant="danger">This Field Required</Alert>
                  )}
                </div>

                {/* <div className="col-md-6">
                  <Form.Group controlId="formBasicPassword">
                    <h5 className="sub-admin-roles">Super Admin</h5>
                    <div className="tg-list-item">
                      <input
                        className="tgl tgl-light"
                        id="cb4"
                        type="checkbox"
                        ref={register()}
                        name="isSuperAdmin"
                      />
                      <label className="tgl-btn" htmlFor="cb4"></label>
                    </div>
                  </Form.Group>
                </div> */}

                <div className="col-md-12">
                  <div className="text-center">
                    <Button className="s-btn" type="submit">
                      {loading && <Spinner animation="grow" />}
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

AddSubAdmin.propTypes = {
  handleTabSwitch: PropTypes.func,
};

export default AddSubAdmin;
