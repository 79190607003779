import React, { useState ,useEffect} from "react";
import { Form, Button, Dropdown, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";

import { adminInstance } from "../../../axios";
import AdminWrapper from "../adminwrapper/wrapper";
import {  Modal} from "react-bootstrap";
import PromotionTable from "./promotionTable";
import Loader from "../../loader/loader";
import moment from "moment";
import DatePicker, { registerLocale } from 'react-datepicker';
import {  Alert } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);
const PromotionUser = () => {
  const initialValues = {
    title: "",
    discount: "",
    description:"",
    date:"",
    disclaimer:"",
    subtitle:"",
    ctatitle:"",
    promocode:""
  };

  const [values, setValues] = useState(initialValues);
  const [data,setData] = useState([]);
  const [expirePromoOffer,setExpirePromoOffer] = useState([])
  const [eventKey, setEventKey] = useState("list");
  const [promotion,setPromotion] = useState(false);
  const [promotionUpdate,setPromotionUpdate] =useState({});
  const [error ,setError] = useState('');
  const [disclaimerModel,setDisclaimer] = useState(false);
  const [disclaimerValue ,setDisclaimerValue] = useState('');

  useEffect(() => {
    async function getPromotions () {
    const response = await adminInstance.get("/get-promotion");
    console.log("hiiii",response)
    const {list ,code } = response.data;
    console.log("listhi",list)

    if (code === 200) {
      if (list) {
        console.log("list-0-----", list);
        const createdDate = moment().format('YYYY-MM-DD');

        let checkUser = list.filter((el) => el.date >= createdDate);
        let  checkSecondUser = list.filter((el) => el.date <= createdDate);

        console.log("checkUser",checkUser,checkSecondUser)
        setData(checkUser)
        setExpirePromoOffer( list.filter((el) =>{ 
          return el.date < createdDate}));
      }
    }

    }
    getPromotions();
  }, []);
 const handleCalendarDate =(date) => {

  console.log("mydate",date)
 
    let mydate = moment(date).format('YYYY-MM-DD');
    
      setValues({
        ...values,
        date: mydate,
      });
    }
    const handleTabSwitch = (k) => {
     
     
      setEventKey(k);
    
    };

    const updateDate =(date) => {

      console.log("helooo date",date)
     
        let mydate = moment(date).format('YYYY-MM-DD');
        
        setPromotionUpdate({
            ...promotionUpdate,
            date: mydate,
          });
        }

        const handleOnBlure=(e)=>{
    
          const {name,value}=e.target
          console.log("blure hai",name ,value)
          if(name==="offer" && value===''){
            setError({[name]:"This field is required"})
          }else{
            setError('')
          }
         console.log("eventt--->",e.target.value);
        }

  const onSubmit = async (e) => {
  
      e.preventDefault();

      try {
        const response = await adminInstance.post(
          "/create-promotion",
          values
        );
        console.log("jjjj",values)
        if (response.status === 200) {
          toast.success("Promotion created");
          setValues(initialValues);
        }
        const promotion = [...data];
        console.log("promotion",promotion)
        promotion.push(values)
        const respons = await adminInstance.get("/get-promotion");
        console.log("hiiii",respons)
        setData(respons.data.list)
      
        // setData(promotion)
       
        
      } catch (e) {
        toast.error(e.response.data.err);
        console.log(e.response);
      }
    
  };
  const onSubmitUpdate = async (e) => {
    try {
      e.preventDefault();

      try {
        const response = await adminInstance.put("/get-promotionupdate", {
            promotionUpdate,
          });
          setPromotion(false);
         
        const indexData =  data.findIndex((el) => el.id === promotionUpdate.id );
        if(indexData >=0)
        {
        const copyData = [...data];
        copyData[indexData] = promotionUpdate;
        console.log('dkk',indexData)

        setData(copyData)
        }
       
      } catch (e) {
        toast.error(e.response.data.err);
        console.log(e.response);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
    // setPromotionUpdate({...promotionUpdate, [name]: value })
  };
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    console.log("name, value",name,value)

   
    setPromotionUpdate({...promotionUpdate, [name]: value })
  };

  const handleModal = async(id) => {
   
    console.log("hi data=>>>>>>",id)
    const  res = await adminInstance.get(`/get-promotionbyid/${id}`);
    console.log("heyy",res)
    
    if(res && res?.data?.data?.length >0){
   
    setPromotionUpdate(res.data.data[0])
    }
    setPromotion(true)

  };

  const handelDisclamer = async(data) => {
   
    console.log("handelDisclamer",data)
    setDisclaimerValue(data)
    setDisclaimer(true)
  
   

  };

  const handleClose = async() => {
 
    
    setPromotion(false)

  };
  const handleCloseDisclimer = () => {
 
    
    setDisclaimer(false)

  };
  const handleDelete = async(userid) => {
    try{
    
        const  res = await adminInstance.delete(`/get-promotiondelete/${userid}`);
        const newData = [...data];
        const updateData = newData.filter(el => el.id !== userid);
        setData(updateData);
    }
    catch (er)
    {
        console.log(er);
    }
 
 



  };
  const columns = [
    
  
    {
      Header: "OFFER",
      accessor: "title",    
    },
    {
      Header: "OFFER# 2",
      accessor: "subtitle",    
    },
    {
      Header: "DISCOUNT",
      accessor: "discount",
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
 
    },
    
    {
      Header: "EXPIRATION DATE",
      accessor: "date",
      Cell: (cell) => {
        return (
       <span>
        {moment(cell.row.values.date).format('MM-DD-YYYY')}
       
       </span>
        );
      },
    },
    // {
    //   Header: "CTA BUTTON",
    //   accessor: "ctatitle",
    // },
    {
      Header: "PROMO CODE",
      accessor: "promocode",
    },
    {
      Header: "DISCLAIMER",
      accessor: "disclaimer",
      Cell: (cell) => {
        return (
          <div className="action-buttons">
         
             <Button onClick={() => handelDisclamer(cell.row.values.disclaimer)}>View </Button>
         
          </div>
        );
      },
      
    },
    {
      Header: "ACTION",
      accessor: "id",
      Cell: (cell) => {
        return (
          <div className="action-buttons">
         
             <Button onClick={() => handleModal(cell.row.values.id)}>EDIT </Button>
             <Button onClick={() => handleDelete(cell.row.values.id)}>DELETE </Button>
          </div>
        );
      },
    },
  ];
  const columnsource = [
    
  
    {
      Header: "OFFER",
      accessor: "title",    
    },
    {
      Header: "OFFER# 2",
      accessor: "subtitle",    
    },
    {
      Header: "DISCOUNT",
      accessor: "discount",
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
 
    },
    
    {
      Header: "EXPIRATION DATE",
      accessor: "date",
      Cell: (cell) => {
        return (
       <span>
        {moment(cell.row.values.date).format('MM-DD-YYYY')}
       
       </span>
        );
      },
    },
    // {
    //   Header: "CTA BUTTON",
    //   accessor: "ctatitle",
    // },
    {
      Header: "PROMO CODE",
      accessor: "promocode",
    },
    {
      Header: "DISCLAIMER",
      accessor: "disclaimer",
      Cell: (cell) => {
        return (
          <div className="action-buttons">
         
             <Button onClick={() => handelDisclamer(cell.row.values.disclaimer)}>View </Button>
         
          </div>
        );
      },
      
    },
 
  ];


  return (
    <AdminWrapper>
      <div className="container">
        {console.log('thisalsowork',expirePromoOffer ,data)}
        <h2 className="admin-title">ADD OTR PROMO OFFER</h2>
        <div className="row">
          <div className="col-6">
            <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Offer name*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter offer"
                  name="title"
                  onChange={handleInputChange}
                  value={values.title}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Offer line 2:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter offer"
                  name="subtitle"
                  onChange={handleInputChange}
                  value={values.subtitle}
                  required
                />
              </Form.Group>
              {console.log("error",error)}
              {error && (
                        <Alert variant="danger">This Field required</Alert>
                      )}
                      
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Discount*:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter discount"
                  name="discount"
                  onChange={handleInputChange}
                  value={values.discount}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Promotion"
                  name="description"
                  onChange={handleInputChange}
                  value={values.description}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Disclaimer*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Disclaimer"
                  name="disclaimer"
                  onChange={handleInputChange}
                  value={values.disclaimer}
               
                />
              </Form.Group>
              <Form.Group className="mb-3 delreq-date" controlId="formBasicDate">
                <Form.Label>Expiration date:</Form.Label>
            


              <DatePicker
                     format="YYYY-MM-DD"
                      placeholderText="mm-dd-yyyy"
                      highlightDates={new Date()}
                      minDate={moment().toDate()}
                     //selected={values.date}
                     name="date"
                     locale="en-gb"
                      onChange={(date)=>handleCalendarDate(date)}
 
                     value={values.date}
                   
                   
            
                    />

              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>CTA Button:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter button Info"
                  name="ctatitle"
                  onChange={handleInputChange}
                  value={values.ctatitle}
                  required
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Promo code*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Promo Code"
                  name="promocode"
                  onChange={handleInputChange}
                  value={values.promocode}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
        
      <h2 className="admin-title promotionuser-heading">CURRENT OFFERS</h2>
      </div>
     <div className="container admin-transaction mt-4">
     <div className="admin-tab">
     <h2 className="admin-title promotionuser-tableheading">Search:</h2>
          <div className="row">
                <div className="col-md-12">
                {/* <PromotionTable
                        columns={columnss}
                        data={data && data}
                      /> */}
                      
            <Tabs
              activeKey={eventKey}
              onSelect={(k) => handleTabSwitch(k)}
              id="uncontrolled-tab-example"
            >

         <Tab eventKey="list" title="Promo Offers">
                <PromotionTable
                  columns={columns}
                  data={data && data}
                />
              </Tab>
              <Tab eventKey="add" title="Expired Offer">
             
                         <PromotionTable
                  columns={columnsource}
                  data={expirePromoOffer && expirePromoOffer}
                />
              </Tab>
            
          
            
              
              
              
            
            </Tabs>
                </div>
              </div>
              </div>
              </div>

          <Modal
        className="login-popup edit-user"
        show={promotion === true}
        onHide={() => handleClose(false)}
      >
        
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
        <label className="edit-heading">
EDIT PROMO OFFER
        </label>
   
        <div className="row">
          <div className="col-12">
            <Form onSubmit={onSubmitUpdate}>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Offer name*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="title"
                  onChange={handleUpdate}
                  value={promotionUpdate.title}
                  required
                />
              </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Offer line 2:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter offer"
                  name="subtitle"
                  onChange={handleUpdate}
                  value={promotionUpdate.subtitle}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Discount*:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Name"
                  name="discount"
                  onChange={handleUpdate}
                  value={promotionUpdate.discount}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="promotion name"
                  name="description"
                  onChange={handleUpdate}
                  value={promotionUpdate.description}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Disclaimer:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Disclaimer"
                  name="disclaimer"
                  onChange={handleUpdate}
                  value={promotionUpdate.disclaimer}
                
                />
              </Form.Group>
              <Form.Group className="mb-3 delreq-date" controlId="formBasicDate">
                <Form.Label>Expiration date:</Form.Label>
            


              <DatePicker
                     format="YYYY-MM-DD"
                      placeholderText="mm-dd-yyyy"
                      highlightDates={new Date()}
                      minDate={moment().toDate()}
                     //selected={values.date}
                     name="date"
                     locale="en-gb"
                      onChange={(date)=>updateDate(date)}
 
                     value={promotionUpdate.date}
                   
                   
            
                    />

              </Form.Group>
              {console.log("promotionUpdate",promotionUpdate)}
              {/* <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>CTA Button:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter button Info"
                  name="ctatitle"
                  onChange={handleUpdate}
                  value={promotionUpdate.ctatitle}
                  required
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Promo code*:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Promo Code"
                  name="promocode"
                  onChange={handleUpdate}
                  value={promotionUpdate.promocode}
                  required
                />
              </Form.Group>


              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      
             
        </Modal.Body>
      </Modal>


      <Modal
          className="login-popup"
          show={disclaimerModel === true}
          onHide={() => handleCloseDisclimer(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
         
           <div className="disclaimer-content"
              dangerouslySetInnerHTML={{ __html: disclaimerValue }}
            ></div>
          </Modal.Body>
        </Modal>
    </AdminWrapper>
  );
};

export default PromotionUser;
