import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import {isPushNotificationSupported, registerServiceWorker} from './push-notifications';
// import Home from './components/home/home';
import Page404 from './components/404/404';
// import Forget from './components/forget/forget';
// import VerifyOTP from './components/phoneverify/verify';
// import AccountSetting from './components/myprofile/accountsetting';
// import Gamertags from './components/myprofile/gamertags';
// import DirectChallenge1vs1 from './components/1vs1directchallenge/1vs1directchallenge';
// import UserProfile from './components/userprofile/userprofile';

//  admin components

import AdminLogin from './components/admin/adminlogin/login';
import AdminDashboard from './components/admin/admindashboard/dashboard';
import SubAdmin from './components/admin/subadmin/subadmin';
import AddGame from './components/admin/game/index';
import AdminUser from './components/admin/user/user';
import AdminDeletedUser from './components/admin/user/deletedRequest';
import AdminCancelMembership from './components/admin/user/cancelMembership';

// import AdminForget from './components/admin/adminforgetpassword/forget';
import AdminVerify from './components/admin/adminverify/verify';
import { socket } from './config/socket';
import PoliciesConsent from './components/admin/PoliciesConsent/PoliciesConsent';
import AddPolicies from './components/admin/PoliciesConsent/AddPolicies';

import AdminProfile from './components/admin/adminmyprofile/accountsetting';
import AdminChangePassword from './components/admin/adminmyprofile/changepassword';
// import Login from './components/login/login';
// import Deposit from './components/deposit/deposit';
// import Withdraw from './components/withdraw/withdraw';
// import TransactionHistory from './components/transactionhistory/transactionhistory';
// import KYC from './components/kyc/kyc';
// import AdminKYC from './components/admin/adminkyc/adminkyc';
import AdminTransaction from './components/admin/admintransaction/admintransaction';
import AdminWithdraw from './components/admin/adminwithdraw/withdraw';
// import MyMatches from './components/mymatches/mymatches';
// import Matchdetails from './components/mymatches/matchdetail';
// import InviteFriends from './components/invitefriends/invitefriends';
// import AdminCommission from './components/admin/admincommission/commission';
// import LeaderBoard from './components/leaderboard/leaderboard';
// import AdminSupportChat from './components/admin/adminsupportchat/chat';
import adminMail from './components/admin/adminmail/index';
import AdminMatchList from './components/admin/adminmatchmaking/index';
import MyCalendar from './components/admin/addtomycallendar/mycalendar';
import Blast from './components/admin/adminblast/blast';
import Advertise from './components/admin/advertise/advertise';
import BlastEmail from './components/admin/adminblast/blastemail';

import ExpiredMembership from './components/admin/expiredmembership/expiredmembership';
import SuperUser from './components/admin/superUser/SuperUser';
import FoundingUser from './components/admin/foundingUser/foundingUser';
import PromotionUser from './components/admin/adminpromotion/promotionUser';

import AdminFollow from './components/admin/follow/follow';
import { getMyDetails } from './actions/adminAction';
// import Loader from './components/loader/loadgister';
// import ChallengesListing from './components/challenges';
// import UserChat from './components/userprofile/userchat';
// import Faq from './components/faq/faq';
// import Privacy from './components/legal/privacy';
// import Terms from './components/legal/terms';
// import About from './components/about/about';
// import SiteRule from './components/legal/siterule';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './responsive.css';
// import AdminMail from './components/admin/adminmail/index';

// const pushNotificationSupported = isPushNotificationSupported();

import AdminDiscount from './components/admin/admindiscount/discount';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('token') ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

const AdminPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('admintoken') ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/admin/login" />
      )
    }
  />
);

// const SuperAdminRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       localStorage.getItem("admintoken") ? (
//         localStorage.getItem("adminRoles") === "superadmin" ? (
//           <Component {...props} {...rest} />
//         ) : (
//           <Redirect to="/admin/dashboard" />
//         )
//       ) : (
//         <Redirect to="/admin/login" />
//       )
//     }
//   />
// );

AdminPrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

const App = () => {
  useEffect(() => {
    // if (pushNotificationSupported) {
    //   registerServiceWorker().then(() => {
    //   }).catch(err => console.log('Error in =>',));
    // }
  }, []);
  const location = useLocation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState({});
  // const adminProfile = useSelector(state => state.adminProfile);
  // const { userData, loading } = adminProfile;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);
  // useEffect(() => {
  //   if(userData) {
  //     setRoles(userData.roles)
  //   }
  // },[userData])

  // useEffect(() => {
  //   dispatch(getMyDetails());
  // }, [dispatch])
  return (
    <div className="App">
      <div className="main-wrapper">
        <React.Fragment>
          <main>
            <Switch>
              <Route exact path="/" render={() => <AdminLogin />} />
              <Route exact path="/email" render={() => <BlastEmail />} />

              {/* <Route path='/forgetPassword' render={() => <Forget />} /> */}
              {/* <Route path='/verifyOtp' render={() => <VerifyOTP />} />
                    <Route path='/login' render={() => <Login />} /> */}
              {/* <Route path='/faq' render={() => <Faq />} /> */}
              {/* <Route path='/privacy' render={() => <Privacy />} />
                    <Route path='/termscondition' render={() => <Terms />} /> */}
              {/* <Route path='/siterule' render={() => <SiteRule />} /> */}
              {/* <Route path='/aboutus' render={() => <About />} /> */}
              {/* <Route path='/invite/:id' render={() => <Register />} />
                    <Route path='/signup' render={() => <Register />} /> */}
              {/* <PrivateRoute path='/changePassword' component={() => <VerifyOTP />} /> */}
              {/* <PrivateRoute path='/accountsetting' component={() => <AccountSetting />} /> */}
              {/* <PrivateRoute path='/gamertags' component={Gamertags} /> */}
              {/* <PrivateRoute path='/1vs1directchallenge' component={DirectChallenge1vs1} />
                    <PrivateRoute path='/deposit' component={Deposit} />
                    <PrivateRoute path='/withdraw' component={Withdraw} /> */}

              {/* <PrivateRoute path='/matchdetail' component={Matchdetails} /> */}

              {/* <PrivateRoute path='/transactionhistory' component={TransactionHistory} /> */}
              {/* <PrivateRoute path='/mymatches' component={MyMatches} />
              <PrivateRoute path='/matchdetail' component={Matchdetails} />
              <PrivateRoute path='/invitefriends' component={InviteFriends} />
              <PrivateRoute path='/challenges' component={ChallengesListing} /> */}

              {/* <PrivateRoute path='/kyc' component={KYC} />
               */}
              <Route path="/admin/login" render={() => <AdminLogin />} />
              {/* <Route path='/admin/forgetPassword' render={() => <AdminForget />} /> */}
              <Route path="/admin/verifyOtp" render={() => <AdminVerify />} />
              {/* <Route path='/leaderboard' render={() => <LeaderBoard />} /> */}
              {/* <PrivateRoute path='/userprofile' component={UserProfile} /> */}
              {/* <PrivateRoute path='/userchat' component={UserChat} /> */}

              <AdminPrivateRoute
                path="/admin/dashboard"
                component={AdminDashboard}
              />
              <AdminPrivateRoute path="/admin/subadmin" component={SubAdmin} />

              <AdminPrivateRoute
                path="/admin/profile"
                component={AdminProfile}
              />

              <AdminPrivateRoute
                path="/admin/changePassword"
                component={AdminChangePassword}
              />

              <AdminPrivateRoute path="/admin/game" component={AddGame} />
              <AdminPrivateRoute path="/admin/user" component={AdminUser} />
              <AdminPrivateRoute
                path="/admin/deletedRequest"
                component={AdminDeletedUser}
              />
              <AdminPrivateRoute
                path="/admin/cancelMembership"
                component={AdminCancelMembership}
              />

              <AdminPrivateRoute
                path="/admin/expired-membership"
                component={ExpiredMembership}
              />
              <AdminPrivateRoute
                path="/admin/event"
                component={AdminMatchList}
              />
              <AdminPrivateRoute
                path="/admin/my-calendar"
                component={MyCalendar}
              />

              <AdminPrivateRoute
                path="/admin/event"
                component={AdminMatchList}
              />
              <AdminPrivateRoute path="/admin/follow" component={AdminFollow} />
              <AdminPrivateRoute
                path="/admin/consent"
                component={PoliciesConsent}
              />
              <AdminPrivateRoute
                path="/admin/add-policy"
                component={AddPolicies}
              />

              <AdminPrivateRoute path="/admin/blast" component={Blast} />

              <AdminPrivateRoute
                path="/admin/advertise"
                component={Advertise}
              />

              <AdminPrivateRoute
                path="/admin/country"
                component={AdminTransaction}
              />
              <AdminPrivateRoute
                path="/admin/withdraw"
                component={AdminWithdraw}
              />

              <AdminPrivateRoute
                path="/admin/super-user"
                component={SuperUser}
              />
              <AdminPrivateRoute
                path="/admin/founding-user"
                component={FoundingUser}
              />

              <AdminPrivateRoute
                path="/admin/promotion-user"
                component={PromotionUser}
              />
              <AdminPrivateRoute path="/admin/mail" component={adminMail} />
              {/* <AdminPrivateRoute path="/admin/blast:id" component={Blast} /> */}
              {/* {loading ? <Loader /> : <Route render={() => <Page404 />} />} */}
              <AdminDiscount path="/admin/discount" component={AdminDiscount} />
            </Switch>
          </main>
        </React.Fragment>
      </div>
      <Toaster />
      {/* <Loader /> */}
    </div>
  );
};

export default App;
