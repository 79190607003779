import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { getSubAdminList } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import AddSubAdmin from "./addsubadmin";
import SubAdminList from "./subadminlist";

const SubAdmin = () => {
    const [eventKey, setEventKey] = useState('list');
    const dispatch = useDispatch();
    
    const handleTabSwitch = (k) => {
        setEventKey(k)
        dispatch(getSubAdminList({ page:1, pagination: 5}));
    }

    return (
        <AdminWrapper>

            <div className="sub-admin">
                <div className="container">
                    <h2 className="admin-title">Sub-Admin</h2>
                   <div className="admin-tab">
                      
                        <Tabs
                        activeKey={eventKey}
                            onSelect={(k) => handleTabSwitch(k)} id="uncontrolled-tab-example">
                            <Tab eventKey="list" title="Sub Admin">
                                <SubAdminList />
                            </Tab>
                            <Tab eventKey="add" title="Add Sub Admin">
                                <AddSubAdmin handleTabSwitch={handleTabSwitch}/>
                            </Tab>
                        </Tabs>
                        
                    </div>
                    </div>
            </div>
                
        </AdminWrapper>      
       
    );
  };
  
export default SubAdmin;









