// src/components/pagination.table.js
import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { adminInstance, userInstance } from "../../../axios";
import toast from "react-hot-toast";
import Loader from "../../loader/loader";
import moment from "moment/moment.js";

const DiscountTable = ({ data }) => {
  const [show, setShow] = useState(false);
  const [fixdata, setFixdata] = useState({});
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(true);
  const [emails, setEmails] = useState([]);

  const handleModal = (e) => {
    console.log(e);
    // console.log("all data", data[e - 1]);
    setFixdata(data[e - 1]);
    setShow(true);
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
    // console.log("LLLLL:::", e.which);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Hello Boys");
    const payload = {
      emails: emails,
      promoCode: fixdata.id,
      discount: fixdata.amount,
    };
    if (emails.length && fixdata.id && fixdata.amount) {
      const result = await adminInstance.get(
        `/promoSend?emails=${emails}&promoCode=${fixdata.id}&discount=${fixdata.amount}`
      );
      const { code, msg } = result.data;
      if (code === 200) {
        setEmails([]);
        setShow(false);
        toast.success(msg);
      } else {
        toast.error("some error occured", { id: "A" });
      }
    } else {
      toast.error("Please enter email", { id: "A" });
    }
  };
  const handleTag = async (e) => {
    console.log("keyCode ::", e.keyCode);
    if (e.keyCode === 13) {
      var re = /\S+@\S+\.\S+/;
      const isEmail = re.test(email);
      console.log("isEmail", email);
      if (isEmail) {
        emails.push(email);
        setEmail([]);
      } else {
        const result = await adminInstance.get(`/getEmail?data=${email}`);
        const { code, data } = result.data;
        console.log("resilt", result.data);
        if (code === 200) {
          const email = result.data.data[0].email;
          emails.push(email);
          setEmails(emails);
          setEmail([]);
        }
        if (code === 300) {
          toast.error("username not exist", { id: "A" });
        }
        console.log("data rec", result);
      }
    }
  };
  const emailRemove = (id) => {
    console.log("element pop", id);
    let dummy = [...emails];
    dummy.splice(id, 1);
    setEmails(dummy);
  };
  return (
    <div>
      {data ? (
        <>
          {/* {loader ? (<Loader/>) : ('')} */}
          {console.log("fixdata", fixdata)}
          <table className="table">
            <thead>
              <tr>
                {/* <th>#</th> */}
                <th>Promo code</th>
                <th>Discount</th>
                <th>Name</th>
                <th>cycle</th>
                <th>Date created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length &&
                data.map((el, i) => {
                  const { id, amount, name, numberOfBillingCycles, createdAt } =
                    el;
                  i = i + 1;
                  return (
                    <tr key={i}>
                      {/* <td>{i}</td> */}
                      <td>{id}</td>
                      <td>{amount}</td>
                      <td>{name}</td>
                      <td>{numberOfBillingCycles}</td>
                      <td>{moment(createdAt).format("MMMM DD, YYYY")}</td>
                      <td>
                        <div className="action-buttons">
                          <Button onClick={() => handleModal(i)}>Share </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* <ul className="pagination">
              <li
                className="page-item"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <a className="page-link">First</a>
              </li>
              <li
                className="page-item"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <a className="page-link">{"<"}</a>
              </li>
              <li
                className="page-item"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <a className="page-link">{">"}</a>
              </li>
              <li
                className="page-item"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className="page-link">Last</a>
              </li>
              <li>
                <a className="page-link">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </a>
              </li>
              <li>
                <a className="page-link">
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px", height: "20px" }}
                  />
                </a>
              </li>{" "}
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ width: "120px", height: "38px" }}
              >
                {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </ul> */}
        </>
      ) : (
        <div>
          <span style={{ color: "white" }}>No records Found</span>
        </div>
      )}
      <>
        <Modal
          className="login-popup"
          show={show}
          onHide={() => setShow(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Email address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {console.log("LOL ::", emails)}
            <p>
              {emails &&
                emails.map((el, i) => {
                  return (
                    <>
                      <span style={{ fontWeight: "bold", margin: "5px" }}>
                        {el}
                      </span>
                      <span onClick={(i) => emailRemove(i)}>x</span>
                      <br />
                    </>
                  );
                })}
            </p>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                required
                value={email}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleTag(e)}
              />
              <Form.Text className="text-muted">
                Note: enter email or valid username then press enter.
              </Form.Text>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              SEND
            </Button>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default DiscountTable;
