import React from "react";
import AdminWrapper from "../adminwrapper/wrapper";

import SendMail from "./sendMail";

const AdminMail = () => {
    return (
        <AdminWrapper>
            <div className="add-game">
                <div className="container">
                    <h2 className="admin-title">Send Mail</h2>
                    <div className="admin-tab">
                        <SendMail/>
                    </div>
            </div>
            </div> 
        </AdminWrapper>      
       
    );
  };
  
export default AdminMail;









