import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersList,
  blockUser,
  getExpiredMembershipList,
  getUsersListById,
} from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import Loader from "../../loader/loader";
import ExpiredMembershipTable from "./expiredmembershiptable";
import { useTable } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

const AdminUser = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [keyword, setKeyword] = useState("");
  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
    if (mail) dispatch(getUsersListById(mail.id));
  };
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [listcopy, setListCopy] = useState([]);
  const dispatch = useDispatch();
  const userListData = useSelector((state) => state.expiredMembershipList);
  const { list } = useSelector((state) => state.expiredMembershipList);

  const { error, userList, loading } = userListData;
  console.log("userlist", list);
  //const { list } = userList;

  const userDeleteData = useSelector((state) => state.deleteUser);
  const { deleteUserStatus } = userDeleteData;

  const userBlockData = useSelector((state) => state.blockUser);
  const { blockUserStatus } = userBlockData;

  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    // dispatch(getUsersList({ page, pagination, keyword }));
    const search = window.location.href;
    const id = search.split("=")[1];
    dispatch(getExpiredMembershipList());
    setShow(true);
  }, [
    dispatch,
    error,
    userDeleteData,
    userBlockData,
    pagination,
    page,
    keyword,
  ]);
  useEffect(() => {
    const arr = [];
    list &&
      list.forEach((el) => {
        let memberId = "";
        let membership_type = "";

        if (el)
          if (el.membership_id === "1") {
            membership_type = "Author Premium";
          }
        if (el.membership_id === "2") {
          membership_type = "Reader";
        }
        if (el.membership_id === "3") {
          membership_type = "Blogger";
        }
        if (el.membership_id === "4") {
          membership_type = "Vendor";
        }
        if (el.membership_id === "5") {
          membership_type = "PR Firm";
        }
        if (el.membership_id === "6") {
          membership_type = "Blogger premium";
        }
        if (el.membership_id === "8") {
          membership_type = "Author";
        }

        arr.push({
          ...el,
          // member_id: memberId,
          membership: membership_type,
        });
      });

    console.log("listCopy", arr);
    setListCopy(arr);
  }, [list]);

  useEffect(() => {
    if (deleteUserStatus) {
      toast.success(deleteUserStatus, { id: "A" });
    }
    if (userDeleteData.error) toast(userDeleteData.error, { id: "A" });
  }, [dispatch, deleteUserStatus, userDeleteData]);

  useEffect(() => {
    if (blockUserStatus) {
      toast.success(blockUserStatus, { id: "A" });
    }
    if (userBlockData.error) toast(userBlockData.error, { id: "A" });
  }, [dispatch, blockUserStatus, userBlockData]);

  const searchUser = () => {
    dispatch(getUsersList({ page, pagination, keyword }));
  };

  const columns = [
    // {
    //   Header: "Name",
    //   columns: [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Username",
      accessor: "display_name",
    },
    // {
    //   Header: "Email",
    //   accessor: "email",
    // },

    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Type",
      accessor: "membership",
    },
    {
      Header: "Date expired ",
      accessor: "member_expiry_date",
      Cell: (cell) => {
        return (
          <div>
            {moment(cell.row.values.member_expiry_date).format("MMM-DD-YYYY")}
          </div>
        );
      },
    },
    {
      Header: "Date cancelled ",
      accessor: "cancel_membership_request",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.cancel_membership_request
              ? moment(cell.row.values.cancel_membership_request).format(
                  "MMM-DD-YYYY"
                )
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Expired/Cancelled ",
      accessor: "",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.cancel_membership_request
              ? "Cancelled"
              : "Expired"}
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: (cell) => {
        // console.log("cell", cell.row.values.is_active);
        return (
          <div className="action-buttons">
            <Button
              value={cell.row.values.id}
              //className="edit-btn"
              onClick={() => handleModal("user", cell.row.values)}
            >
              View{" "}
            </Button>
          </div>
        );
      },
    },
    //   ],
    // },
  ];

  //console.log('TTT',userList)
  return (
    <AdminWrapper>
      <div className="admin-user">
        <div className="admin-transaction">
          <div className="container">
            <h2 className="admin-title">Expired Memberships</h2>
            <div className="admin-tab">
              <div className="user-search-box">
                <div className="user-search-section">
                  <div className="user-search">
                    {/* <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="search"
                  placeholder="Search Here..."
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </Form.Group> */}
                    {/* <Button variant="primary" type="button" onClick={searchUser}>
              <i className="fa fa-search" aria-hidden="true"></i>
              </Button> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {loading ? (
                      <Loader />
                    ) : (
                      list && (
                        <ExpiredMembershipTable
                          columns={columns}
                          data={listcopy && listcopy}
                        />
                      )
                    )}
                    {/* {loading ? (
                <Loader />
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>ID</th>
                      <th>Auto Renew</th>
                      <th>Reminder Frequency</th>
                      <th>Send Reminder</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList && userList.list.length ? (
                      userList.list.map((item, i) => (
                        <tr key={`item-${i}`}>
                          <td>{item.display_name}</td>
                          <td>{item.email}</td>
                          <td>{item.follow_code}</td>
                          <td>
                            <span className="togglebox">
                              {item.auto_renew ? "Yes" : "No"}{" "}
                            </span>
                          </td>

                          <td>
                            <sapn className="togglebox">
                              {item.reminder_frequency}
                            </sapn>
                          </td>
                          <td>
                            <span className="togglebox">
                              {item.send_reminder ? "Yes" : "No"}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="action-buttons">
                              <Button
                                className="edit-btn"
                                onClick={() => handleModal("user", item)}
                              >
                                View{" "}
                              </Button>
                              <Button
                                onClick={() => handleModal("delete", item.id)}
                                className="delete-btn"
                              >
                                Delete
                              </Button>
                              <Button
                                onClick={() =>
                                  dispatch(
                                    !item.isBlocked
                                      ? blockUser(item.email)
                                      : blockUser(item.email),
                                    ""
                                  )
                                }
                                className="block-btn"
                              >
                                {item.is_active ? "Unblock" : "Block"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No User Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )} */}
                    {/* <div className="admin-pagination">
                <Pagination>
                  <Pagination.First onClick={() => setPage(1)} />
                  <Pagination.Prev
                    onClick={() => (page > 1 ? setPage(page - 1) : "")}
                  />
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Next
                    onClick={() =>
                      page < Math.ceil(userList.total / pagination)
                        ? setPage(page + 1)
                        : ""
                    }
                  />
                  <Pagination.Last
                    onClick={() =>
                      setPage(Math.ceil(userList.total / pagination))
                    }
                  />
                </Pagination>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "delete"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeletePopup handleModal={handleModal} mail={email} type="user" />
        </Modal.Body>
      </Modal>

      <Modal
        className="login-popup"
        show={show === "user"}
        onHide={() => handleModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"userlisting"} user={email} />
        </Modal.Body>
      </Modal>
    </AdminWrapper>
  );
};

export default AdminUser;
