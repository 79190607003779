import {io} from "socket.io-client";
import {server} from "./keys.js";

const location = window.location.pathname;
const admintoken = localStorage.getItem('admintoken') ? JSON.parse(localStorage.getItem('admintoken')).token: null;
const token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token")).token
  : null;
const sendToken = location.search('admin') !== -1 ? admintoken : token;

const data = server;
const socket = io(data, {
    reconnectionDelayMax: 1000,
    reconnection:true,
    transports: ["websocket", "xhr-polling"],
    rejectUnauthorized: false,
    query: `token=${sendToken}`,
});

socket.on("connect", () => {
    console.log("connected");
    socket.emit('join', {userId: localStorage.getItem('userId')})
});

socket.on("reconnect", () => {
    if (token) {
        console.log("Reconnecting called");
        socket.emit("join", sendToken);
    }
});

const tryReconnect = () => {
    setTimeout(() => {
        socket.io.open((err) => {
            if (err) {
                tryReconnect();
            }
        });
    }, 2000);
}

socket.io.on("close", tryReconnect);


export {socket};