export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_VERIFIED = 'USER_VERIFIED'

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL'

export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL'
export const ADMIN_VERIFIED = 'ADMIN_VERIFIED'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'

export const MY_DETAILS_REQUEST = 'MY_DETAILS_REQUEST'
export const MY_DETAILS_SUCCESS = 'MY_DETAILS_SUCCESS'
export const MY_DETAILS_FAIL = 'MY_DETAILS_FAIL'

export const EDIT_DETAILS_REQUEST = 'EDIT_DETAILS_REQUEST'
export const EDIT_DETAILS_SUCCESS = 'EDIT_DETAILS_SUCCESS'
export const EDIT_DETAILS_FAIL = 'EDIT_DETAILS_FAIL'
