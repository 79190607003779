import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Spinner,Table } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { adminInstance } from '../../../axios';
import { getGameType } from "../../../actions/gameAction";



const GameForm = () => {
    const [loading, setLoading] = useState(false);
    const [pic, setPic] = useState('');
    const dispatch = useDispatch();
    const [preview, setPreview] = useState(false);
    const [picErr, setPicErr] = useState('');
    const { register, handleSubmit, errors, reset } = useForm({ mode: 'onBlur' });

    const gameTypeData = useSelector(state => state.gameType);
    const { gameTypeInfo } = gameTypeData;

    useEffect(() => {
        dispatch(getGameType());
    },[dispatch])
    const addGameHandler = async (values) => {
        if (picErr !== '')
            return 0
        if (pic === '')
            return setPicErr('Image is required');
        else
            setPicErr('')
        setLoading(true);
        var fd = new FormData();
        fd.append('photo', pic);
        fd.append('gameName', values.gameName);
        fd.append('category', values.category);
        fd.append('description', values.description);
        const res = await adminInstance.post('/addGame', fd);
        if (res.data.code === 200) {
            reset();
            setPreview('');
            setLoading(false);
            toast.success('Game Added Successfully.');
            dispatch(getGameType());
        } else if (res.data.code === 401) {
            toast.error('Not Authorized!', {id: 'A'});
            localStorage.removeItem('admintoken');
            window.location.href = '/admin/login';
        }else {
            setLoading(false);
            toast.error('Error Occured');
        }
    }

    const picUploadHandler = (e) => {
        if (e.target.files[0].type.search('image') === -1)
            return setPicErr('Not valid Image file');
        setPic(e.target.files[0]);
        setPreview(e.target.files[0].name);
        setPicErr('');
    }
    return (
            
        <div className="add-game-content">
        <div className="register-form">
                 <Form onSubmit={handleSubmit(addGameHandler)}>
                     <div className="row">

                         <div className="col-md-6">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Title</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Title"
                                     name="gameName"
                                     autoComplete="off"
                                     ref={register({ required: true })}
                                 />
                                 {errors.gameName && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Description</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Description"
                                     name="description"
                                     autoComplete="off"
                                     ref={register({ required: true })}
                                 />
                                 {errors.description && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Category</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Category"
                                     name="category"
                                     autoComplete="off"
                                     ref={register({required: true })}
                                 />
                                 {errors.category && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                             <div className="uplaod-profile-photo">
                                 <Form.Group controlId="formBasicFirst">
                                    <Form.Label>Photo</Form.Label>
                                    <div className="photo-upload-label">
                                     <Form.Control
                                         id="file"
                                         type="file"
                                         placeholder="photo"
                                         name="photo"
                                         autoComplete="off"
                                         onChange={picUploadHandler}
                                     />
                                    <Form.Label className="btn-2" htmlFor='file'>Upload</Form.Label>
                                        {preview && <p>{preview}</p>}
                                    </div>
                                    {picErr !== '' && (<Alert variant='danger'>{picErr}</Alert>)}
                                    
                                 </Form.Group>
                                
                             </div>
                         </div>

                         <div className="col-md-12">
                             <div className="text-right">
                                 <Button className="s-btn" type="submit" disabled={loading}>
                                     {loading && (<Spinner animation='grow' />)}
                                     Add
                                 </Button>
                             </div>

                         </div>


                     </div>
                 </Form>
         </div>
         <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Game Name</th>
                      <th>Description</th>
                      <th>Category</th>
                      <th>Photo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameTypeInfo &&
                      gameTypeInfo.reverse().map((item, i) => (
                        <tr key={`item-${i}`}>
                          <td>
                            {item.gameName}
                          </td>
                          <td>{item.description}</td>
                          <td>{item.category}</td>
                          <td><img src={item.photo} alt='game' className="img-fluid" /></td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
             
     </div>
    );
  };


  export default GameForm;