import React, { useState } from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { adminInstance } from "../../../axios";
import AdminWrapper from "../adminwrapper/wrapper";

const FoundingUser = () => {
  const initialValues = {
    email: "",
    display_name: "",
    membership_id: "",
  };

  const [values, setValues] = useState(initialValues);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const { display_name, email, membership_id } = values;
      const err = [];

      if (!display_name) err.push("Display name is required");
      if (!email) err.push("Email is required");
      if (!membership_id) err.push("Membership is required");

      if (err.length) {
        err.forEach((e) => toast.error(e));
        return;
      }

      try {
        const response = await adminInstance.post(
          "/create-founding-user",
          values
        );
        if (response.status === 200) {
          toast.success("Founding User Created");
          setValues(initialValues);
        }
      } catch (e) {
        toast.error(e.response.data.err);
        console.log(e.response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  return (
    <AdminWrapper>
      <div className="container">
        <h2 className="admin-title">FOUNDING MEMBERS</h2>
        <div className="row">
          <div className="col-6">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="display_name"
                  onChange={handleInputChange}
                  value={values.display_name}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleInputChange}
                  value={values.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Type</Form.Label>
                <br />
                <select
                  onChange={(e) =>
                    setValues({ ...values, membership_id: e.target.value })
                  }
                >
                  <option value="">--Select--</option>
                  {/* <option value="1">Author</option> */}
                  <option value="2">Reader</option>
                  <option value="6">Blogger Premium</option>
                  <option value="8">Author Premium</option>
                  {/* <option value="3">Blogger</option> */}
                  <option value="4">Vendor</option>
                  <option value="5">PR Firm</option>
                </select>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default FoundingUser;
