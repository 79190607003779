import React, { useEffect, useState } from "react";
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { adminverifyOtp, adminnewPassword } from "../../../actions/adminAction";
import toast from "react-hot-toast";

const PhoneVerify = () => {
    const [ showResetPassword, setShowResetPassword ] = useState(false);

    const verifyData = useSelector(state => state.adminverifyOtp);
    const { otpInfo, error } = verifyData;

    const newPasswordData = useSelector(state => state.adminnewPassword);
    const { resetMsg, loading } = newPasswordData;
    
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
console.log("Resetmsg : ",resetMsg)
    useEffect(() => {
        if(error)
            toast.error(error)
        if(newPasswordData.error)
            toast.error(newPasswordData.error);
        if(resetMsg){
            toast.success(resetMsg);
            history.push('/admin/dashboard')
        }
        if(location.pathname === '/admin/changePassword') {
            setShowResetPassword(true);
        }
        if(otpInfo) {
            const urlParams = new URLSearchParams(location.search);
            const redirect = urlParams.get('redirect');
            if(redirect) {
                setShowResetPassword(true);
            }
            else {
                toast.success("Email Verified, Please Login.");
                history.push('/admin/login')
            }
            
        }
    },[history, error, otpInfo,newPasswordData, resetMsg, location])


    const handleVerifyOtp = (values) => {
        if(location.search) {
            const urlParams = new URLSearchParams(location.search);
            const email = urlParams.get('email');
            dispatch(adminverifyOtp(email, values.otp))
        }
    }

    const handleResetPassword = (values) => {
        if(values.newpassword !== values.confirmpassword) 
            return toast.error("Password do not match. retry!");
        const urlParams = new URLSearchParams(location.search);
        const payload = {
            email: urlParams.get('email'),
            password: values.newpassword
        }
        dispatch(adminnewPassword(payload))
    }

    return (
        <div className="admin-forget">
        <div className="forget-password-section">
            <div className="forget-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {!showResetPassword ?
                            (
                                
                            <div className="forget-form">
                                <Link className="back-home" to='/admin/login'><i className='fa fa-arrow-left'></i></Link>
                                <h1>Verify Your Email</h1>
                                <p>Enter 4 digit code here</p>
                                <div className="forget-box">
                                    <Form onSubmit={handleSubmit(handleVerifyOtp)}>
                                    <Form.Group controlId="formBasicloginone">
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter OTP" 
                                        name="otp" 
                                        ref={register({ required: true })}
                                        />
                                        {
                                            errors.otp && (
                                                <Alert variant="danger">This field is required</Alert>
                                            )
                                        }
                                    </Form.Group>

                                    <Button className="reset-btn" type="submit" disabled={verifyData.loading}>
                                        {verifyData.loading && (<Spinner animation='grow' />)}
                                    Verify
                                    </Button>
                                    </Form>
                                </div>
                            </div>
                            ):(
                            <div className="forget-form">
                                <h1>Reset <span>Password</span></h1>
                                <div className="reset-box">
                                <Form onSubmit={handleSubmit(handleResetPassword)}>
                                    <Form.Group controlId="formBasicloginone">
                                        <Form.Control 
                                        type="password" 
                                        placeholder="New Password" 
                                        name="newpassword" 
                                        ref={register({ required: true })}
                                        />
                                        {
                                            errors.otp && (
                                                <Alert variant="danger">This field is required</Alert>
                                            )
                                        }
                                    </Form.Group>

                                    <Form.Group controlId="formBasicloginone">
                                        <Form.Control 
                                        type="password" 
                                        placeholder="Confirm Password" 
                                        name="confirmpassword" 
                                        ref={register({ required: true })}
                                        />
                                        {
                                            errors.otp && (
                                                <Alert variant="danger">This field is required</Alert>
                                            )
                                        }
                                    </Form.Group>

                                    <Button className="reset-btn" type="submit" disabled={loading}>
                                        {loading && (<Spinner animation='grow' />)}
                                        Reset Password
                                            </Button>
                                </Form>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>

    );
};

export default PhoneVerify;
