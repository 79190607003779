import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { deleteUser, deleteAdmin, getSubAdminList } from "../../../actions/adminAction";
import toast from "react-hot-toast";

const DeletePopup = ({ handleModal, mail, type, setShow}) => {
  console.log("mail,type",mail, type)
  const dispatch = useDispatch();

  console.log("jjjjjjjjjjjjjj")

  const handledelete = async(e) => {
    try
    {

    
    console.log("eeeeeeeee==>>>>",e)
    if (type === "user") {
      dispatch(deleteUser(mail));
      handleModal(false);
  
    } 

    if (type === "admin") {
      dispatch(deleteAdmin(mail));
      dispatch(getSubAdminList());
      handleModal(false);
 
    }
  }
  catch (e) {
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhh",e)
    toast.error( e && e.response.data.err);
 
  }

  };

  return (
    <div className="delete-popup new-del-popup">
      <h4>Confirm member is to be permanently deleted from OTR. Email and username permanently barred from site.</h4>
      <div className="new-del-popup-btn">
      <Button className="edit-btn" onClick={() => setShow('')}>
      CANCEL REQUEST{" "}
      </Button>
      <Button onClick={(e) => handledelete(e)} className="delete-btn">
      DELETE MEMBER
      </Button>
      </div>
    </div>
  );
};
DeletePopup.propTypes = {
  handleModal: PropTypes.func.isRequired,
  mail: PropTypes.string,
  type: PropTypes.string,
};
export default DeletePopup;
