import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { getmatches } from "../../../actions/adminAction.js";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Loader from "../../loader/loader";
import UserPopup from "../popup/user";
import EventTable from "./eventtable";
import moment from "moment";

const AdminMatchmaking = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pagination] = useState(5);
  const MatchmakingData = useSelector((state) => state.getMatchesList);
  const { error, Matches, totalCount, loading } = MatchmakingData;
  console.log("Total Data data-->>", Matches);
  //console.log('KKKK>>',dataa);
  const [dataa, setDataa] = useState("");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
  };


  useEffect(() => {
    const search = window.location.href;
    const id = search.split("=")[1];

    if (
      id != "totalBookRelease" &&
      id != "totalGiveway" &&
      id != "totalCoverRevealPromo" &&
      id != "todayReleaseParty" &&
      id != "todayCoverReveal" &&
      id != "todayBookSigning" &&
      id != "todayArcSignUp" &&
      id != "today" &&
      id != "upcoming"
    ) {
      dispatch(getmatches(parseInt(id), 10));
    } else if (id) {
      dispatch(getmatches(id));
    } else {
      console.log("hiiiiiiiiiiiiiiii", id);
      dispatch(getmatches(id));
    }

    setShow(true);
  }, [dispatch, page, pagination, dataa]);

  useEffect(() => {
    if (error) {
      toast.error(error, { id: "A" });
    }
  }, [error]);
  const amPm = (time) => {
    const hour = time.split(":")[0] * 1;
    return hour > 12 ? "pm" : "am";
  };

  const columns = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Author last name",
      accessor: "author_last_name",
    },
    {
      Header: "Author name",
      accessor: "author_first_name",
      Cell: (cell) => {
         return (
          <div>
            {cell.row.values.event_type === 'event_type_6' ?
             "NA":
            ( cell.row.values.author_first_name ?
             cell.row.values.author_first_name :"") +" "+ ( cell.row.values.author_last_name ?
              cell.row.values.author_last_name : "")}
          </div>
        );
      },
    },
    {
      Header: "Type of event",
      accessor: "event_name",
    },
    // {
    //   Header: "Type of event",
    //   accessor: "event_type",
    // },
    {
      Header: "Start date",
      accessor: "start_date",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.start_date &&
              moment(cell.row.values.start_date).format("MMM-DD-YYYY")}
          </div>
        );
      },
    },
    {
      Header: "End date",
      accessor: "end_date",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.end_date ?
              moment(cell.row.values.end_date).format("MMM-DD-YYYY") :"NA"}
          </div>
        );
      },
    },
    {
      Header: "Time",
      accessor: "time",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.time
              ? cell.row.values.time + " " + amPm(cell.row.values.time)
              : "NA"}
          </div>
        );
      },
    },
    {
      Header: "Created by",
      accessor: "display_name",
      Cell: (cell) => {
        return (
         <div>
           {cell.row.values.event_type === 'event_type_6' ?
           (cell.row.values.author_first_name ?
            cell.row.values.author_first_name :"") +" "+ ( cell.row.values.author_last_name ?
             cell.row.values.author_last_name : ""):cell.row.values.display_name}
           
         </div>
       );
     },
    },

    // {
    //   Header: "Action",
    //   accessor: "",
    //   Cell: (cell) => {
    //     // console.log("cell", cell.row.values.is_active);
    //     return (
    //       <div className="action-buttons">
    //         <Button
    //           value={cell.row.values.id}
    //           className="edit-btn"
    //           onClick={() => handleModal("user", cell.row.values)}
    //         >
    //           View{" "}
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="admin-matchmaking">
        <div className="container">
          <div className="user-search-section">
            <h2 className="admin-title">{""}</h2>
            <div className="user-search">
              {/* <Form.Group controlId="formBasicEmail">
              <Form.Control
                type="search"
                placeholder="Search Here..."
                value={dataa}
                onChange={(e) => setDataa(e.target.value)}
              />
            </Form.Group> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <Loader />
              ) : (
                Matches && (
                  <EventTable columns={columns} data={Matches && Matches} />
                )
              )}
              {/* {loading ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Author Name</th>
                      <th>Type of event</th>
                      <th>Hosted By</th>
                      <th>Time</th>
                      <th>Created By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Matches && Matches.length !== 0 ? (
                      Matches.map((item, i) => (
                        <tr key={`item-${i}`}>
                          <td>{item.title || ""}</td>
                          {console.log("item", item)}
                          <td>
                            {item.author_first_name || ""}{" "}
                            {item.author_first_name || ""}{" "}
                          </td>
                          <td>{item.event_name || ""}</td>
                          <td>{item.hosted_by || ""}</td>
                          <td>{item.time || ""}</td>
                          <td>{item.display_name || ""}</td>
                          <td>
                            <div className="action-buttons">
                              <Button
                                className="edit-btn"
                                onClick={() => handleModal("user", item)}
                              >
                                View{" "}
                              </Button>
                              
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="6">
                          No Records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )} */}
              {/* <div className="admin-pagination">
            {totalCount > 0 ? (
              totalCount > pagination ? (
                <Pagination>
                  <Pagination.First onClick={() => setPage(1)} />
                  <Pagination.Prev
                    onClick={() => (page > 1 ? setPage(page - 1) : "")}
                  />
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Next
                    onClick={() =>
                      page < Math.ceil(totalCount / pagination)
                        ? setPage(page + 1)
                        : ""
                    }
                  />
                  <Pagination.Last
                    onClick={() => setPage(Math.ceil(totalCount / pagination))}
                  />
                </Pagination>
              ) : (
                " "
              )
            ) : (
              ""
            )}
          </div> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "user"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"eventlisting"} user={email} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminMatchmaking;
