import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import user from "../../../assets/user.png";
import { server } from "../../../config/keys";

const MyProfileMenu = () => {
  const myProfileData = useSelector((state) => state.adminProfile);
  const { userData } = myProfileData;

  console.log("Helllo =-------------->", userData);
  if (userData) {
    console.log("userData", `${server}/${userData.photo}`);
  }

  return (
    <div className="my-profile-nav">
      <div className="user-info">
        <div className="user-pic">
          <img
            src={
              userData &&
              userData.photo !== "admin/default.png" &&
              userData.photo
                ? `${server}/${userData.photo}`
                : user
            }
          />
        </div>

        <div className="user-detail">
          <h4>{userData && userData.username}</h4>
        </div>
      </div>

      <ul>
        <Nav>
          <NavLink to="/admin/Profile">
            <i className="fa fa-user-o" /> Account Setting
          </NavLink>

          <NavLink to={`/admin/changePassword`}>
            <i className="fa fa-lock" /> Change Password
          </NavLink>

          {/* <Link to="/logout">
            <i className="fa fa-power-off" /> Logout
          </Link> */}
        </Nav>
      </ul>
    </div>
  );
};

export default MyProfileMenu;
