import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { adminInstance } from "../../../axios";
import AdminWrapper from "../adminwrapper/wrapper";

const SuperUser = () => {
  const initialValues = {
    email: "",
    membership_id: 9,
    display_name: "",
  };

  const [values, setValues] = useState(initialValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { display_name, email } = values;
    const err = [];

    if (!display_name) err.push("Display name is required");
    if (!email) err.push("Email is required");

    if (err.length) {
      err.forEach((e) => toast.error(e));
      return;
    }

    try {
      const response = await adminInstance.post("/create-super-user", values);
      if (response.status === 200) {
        toast.success("Super User Created");
        setValues(initialValues);
      }
    } catch (e) {
      toast.error(e.response.data.err);
      console.log(e.response);
    }
  };

  console.log(values);

  return (
    <AdminWrapper>
      <div className="container">
        <h2 className="admin-title">Super User</h2>
        <div className="row">
          <div className="col-6">
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="display_name"
                  onChange={handleInputChange}
                  value={values.display_name}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleInputChange}
                  value={values.email}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default SuperUser;
