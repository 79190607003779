export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const EDIT_DETAILS_REQUEST = 'EDIT_DETAILS_REQUEST'
export const EDIT_DETAILS_SUCCESS = 'EDIT_DETAILS_SUCCESS'
export const EDIT_DETAILS_FAIL = 'EDIT_DETAILS_FAIL'

export const GET_ENTRY_FEE_REQUEST = 'GET_ENTRY_FEE_REQUEST'
export const GET_ENTRY_FEE_SUCCESS = 'GET_ENTRY_FEE_SUCCESS'
export const GET_ENTRY_FEE_FAIL = 'GET_ENTRY_FEE_FAIL'

export const GET_GAME_REQUEST = 'GET_GAME_REQUEST'
export const GET_GAME_SUCCESS = 'GET_GAME_SUCCESS'
export const GET_GAME_FAIL = 'GET_GAME_FAIL'

export const GET_PLATFORM_REQUEST = 'GET_PLATFORM_REQUEST'
export const GET_PLATFORM_SUCCESS = 'GET_PLATFORM_SUCCESS'
export const GET_PLATFORM_FAIL = 'GET_PLATFORM_FAIL'

export const GET_GAME_VERSION_REQUEST = 'GET_GAME_VERSION_REQUEST'
export const GET_GAME_VERSION_SUCCESS = 'GET_GAME_VERSION_SUCCESS'
export const GET_GAME_VERSION_FAIL = 'GET_GAME_VERSION_FAIL'

export const USER_IMAGE_UPLOAD_REQUEST = 'USER_IMAGE_UPLOAD_REQUEST'
export const USER_IMAGE_UPLOAD_SUCCESS = 'USER_IMAGE_UPLOAD_SUCCESS'
export const USER_IMAGE_UPLOAD_FAIL = 'USER_IMAGE_UPLOAD_FAIL'



export const EDIT_GAMERTAGS_REQUEST = 'EDIT_GAMERTAGS_REQUEST'
export const EDIT_GAMERTAGS_SUCCESS = 'EDIT_GAMERTAGS_SUCCESS'
export const EDIT_GAMERTAGS_FAIL = 'EDIT_GAMERTAGS_FAIL'

export const GAME_VERSION_REQUEST = 'GET_GAME_VERSION_REQUEST'
export const GAME_VERSION_SUCCESS = 'GET_GAME_VERSION_SUCCESS'
export const GAME_VERSION_FAIL = 'GET_GAME_VERSION_FAIL'

export const GET_GAMERTAGS_REQUEST = 'GET_GAMERTAGS_REQUEST'
export const GET_GAMERTAGS_SUCCESS = 'GET_GAMERTAGS_SUCCESS'
export const GET_GAMERTAGS_FAIL = 'GET_GAMERTAGS_FAIL'

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL'

export const USER_TRANSACTION_LIST_REQUEST = 'USER_TRANSACTION_LIST_REQUEST'
export const USER_TRANSACTION_LIST_SUCCESS = 'USER_TRANSACTION_LIST_SUCCESS'
export const USER_TRANSACTION_LIST_FAIL = 'USER_TRANSACTION_LIST_FAIL'

export const UPDATE_KYC_REQUEST = 'UPDATE_KYC_REQUEST'
export const UPDATE_KYC_SUCCESS = 'UPDATE_KYC_SUCCESS'
export const UPDATE_KYC_FAIL = 'UPDATE_KYC_FAIL'

export const GET_INSTANT_MATCH_REQUEST = 'GET_INSTANT_MATCH_REQUEST'
export const GET_INSTANT_MATCH_SUCCESS = 'GET_INSTANT_MATCH_SUCCESS'
export const GET_INSTANT_MATCH_FAIL = 'GET_INSTANT_MATCH_FAIL'

export const MY_UNFINISHED_MATCH_REQUEST = 'MY_UNFINISHED_MATCH_REQUEST'
export const MY_UNFINISHED_MATCH_SUCCESS = 'MY_UNFINISHED_MATCH_SUCCESS'
export const MY_UNFINISHED_MATCH_FAIL = 'MY_UNFINISHED_MATCH_FAIL'

export const RECENT_FINISHED_MATCH_REQUEST = 'RECENT_FINISHED_MATCH_REQUEST'
export const RECENT_FINISHED_MATCH_SUCCESS = 'RECENT_FINISHED_MATCH_SUCCESS'
export const RECENT_FINISHED_MATCH_FAIL = 'RECENT_FINISHED_MATCH_FAIL'

export const UNREAD_NOTIFY_REQUEST = 'UNREAD_NOTIFY_REQUEST'
export const UNREAD_NOTIFY_SUCCESS = 'UNREAD_NOTIFY_SUCCESS'
export const UNREAD_NOTIFY_FAIL = 'UNREAD_NOTIFY_FAIL'

export const GET_MATCH_REQUEST = 'GET_MATCH_REQUEST'
export const GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS'
export const GET_MATCH_FAIL = 'GET_MATCH_FAIL'

export const MY_FINISHED_MATCH_REQUEST = 'MY_FINISHED_MATCH_REQUEST'
export const MY_FINISHED_MATCH_SUCCESS = 'MY_FINISHED_MATCH_SUCCESS'
export const MY_FINISHED_MATCH_FAIL = 'MY_FINISHED_MATCH_FAIL'

export const ALL_LEADERBOARD_REQUEST = 'ALL_LEADERBOARD_REQUEST'
export const ALL_LEADERBOARD_SUCCESS = 'ALL_LEADERBOARD_SUCCESS'
export const ALL_LEADERBOARD_FAIL = 'ALL_LEADERBOARD_FAIL'

export const GAME_LEADERBOARD_REQUEST = 'GAME_LEADERBOARD_REQUEST'
export const GAME_LEADERBOARD_SUCCESS = 'GAME_LEADERBOARD_SUCCESS'
export const GAME_LEADERBOARD_FAIL = 'GAME_LEADERBOARD_FAIL'

export const VOTE_DETAIL_REQUEST = 'VOTE_DETAIL_REQUEST'
export const VOTE_DETAIL_SUCCESS = 'VOTE_DETAIL_SUCCESS'
export const VOTE_DETAIL_FAIL = 'VOTE_DETAIL_FAIL'

export const SUPPORT_MESSAGE_REQUEST = 'SUPPORT_MESSAGE_REQUEST'
export const SUPPORT_MESSAGE_SUCCESS = 'SUPPORT_MESSAGE_SUCCESS'
export const SUPPORT_MESSAGE_FAIL = 'SUPPORT_MESSAGE_FAIL'

export const ONLINE_USER_REQUEST = 'ONLINE_USER_REQUEST'
export const ONLINE_USER_SUCCESS = 'ONLINE_USER_SUCCESS'
export const ONLINE_USER_FAIL = 'ONLINE_USER_FAIL'

export const CHALLENGE_LIST_REQUEST = 'CHALLENGE_LIST_REQUEST'
export const CHALLENGE_LIST_SUCCESS = 'CHALLENGE_LIST_SUCCESS'
export const CHALLENGE_LIST_FAIL = 'CHALLENGE_LIST_FAIL'

export const SEND_CHALLENGE_LIST_REQUEST = 'SEND_CHALLENGE_LIST_REQUEST'
export const SEND_CHALLENGE_LIST_SUCCESS = 'SEND_CHALLENGE_LIST_SUCCESS'
export const SEND_CHALLENGE_LIST_FAIL = 'SEND_CHALLENGE_LIST_FAIL'

export const CHAT_USER_LIST_REQUEST = 'CHAT_USER_LIST_REQUEST'
export const CHAT_USER_LIST_SUCCESS = 'CHAT_USER_LIST_SUCCESS'
export const CHAT_USER_LIST_FAIL = 'CHAT_USER_LIST_FAIL'

export const RECENT_CHATS_REQUEST = 'RECENT_CHATS_REQUEST'
export const RECENT_CHATS_SUCCESS = 'RECENT_CHATS_SUCCESS'
export const RECENT_CHATS_FAIL = 'RECENT_CHATS_FAIL'

export const MATCH_CARD_REQUEST = 'MATCH_CARDS_REQUEST'
export const MATCH_CARD_SUCCESS = 'MATCH_CARDS_SUCCESS'
export const MATCH_CARD_FAIL = 'MATCH_CARDS_FAIL'

export const PLAYER_MATCH_CARD_REQUEST = 'PLAYER_MATCH_CARDS_REQUEST'
export const PLAYER_MATCH_CARD_SUCCESS = 'PLAYER_MATCH_CARDS_SUCCESS'
export const PLAYER_MATCH_CARD_FAIL = 'PLAYER_MATCH_CARDS_FAIL'