import React,{useState,useEffect} from "react";
import { Table, Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import AdminWrapper from "../adminwrapper/wrapper";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getWithdraw } from "../../../actions/adminAction";
import {adminInstance } from '../../../axios'
import { socket } from "../../../config/socket";
import Loader from "../../loader/loader";

const AdminWithdraw = () => {
  const [page, setPage] = useState(1);
  const [pagination] = useState(5);
  const [toggle, setToggle] = useState(false);

  const handleAction=async(id,userId ,action)=>{
    setToggle(true)
const payload ={
  action:action ,
   requestId:id,
    Id:userId
  };
const response = await adminInstance.post('/withdrawResponse',payload)
setToggle(false)
const {code, msg} = response.data;
if(code===200){
  toast.success(msg);
  if(action === 'verify')
  socket.emit('notifyUser', {
    msg: 'Your withdraw request is approved, amount will be transfer soon',
    link:'/transactionhistory',
    fromAdmin: true,
    userId
  })
  if(action === 'reject')
  socket.emit('notifyUser', {
    msg: 'Your withdraw request is Rejected by Admin, amount will be transfer to your wallet',
    link:'/transactionhistory',
    fromAdmin: true,
    userId
  })
}
    dispatch(getWithdraw(page, pagination))
  }
 



  const withdrawData = useSelector((state) => state.getWithdrawList);
  const {  error, WithdrawList, totalCount, loading } = withdrawData;
  const dispatch = useDispatch();

useEffect(() => {
 dispatch(getWithdraw(page,pagination))
 if(error){
   toast.error(error)
 }
},[dispatch, page, pagination,error ])

  return (

    <AdminWrapper>
      <div className="admin-kyc admin-withdraw">
        <div className="container">
          <h2 className="admin-title">Withdraw</h2>
          <div className="row">
            <div className="col-md-12">
            {loading ? <Loader  /> :  <Table bordered hover responsive>
                <thead>
              
                  <tr>
                   
                    <th>Email</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
  
                </thead>
                <tbody>
                {WithdrawList && WithdrawList.length !== 0 ? WithdrawList.map((item,i) => (       
                    <tr key={`item-${i}`}>
                    
                      <td>{item.email}</td>
                      <td>{item.amount}</td>
                      <td>
                        <div className="action-buttons">
                        <Button className="block-btn" onClick={() => handleAction(item._id, item.userId, 'verify')} disabled={toggle}>Approve</Button>
                        <Button className="delete-btn" onClick={() => handleAction(item._id, item.userId, 'reject')} disabled={toggle}>Reject</Button>
                        </div>
                      </td>
                    </tr>

                    )): (
                      <tr>
                      <td colSpan='3' className='text-center'>
                        No Withdraw request
                      </td>
                    </tr>
                    ) }          
                </tbody>
              </Table>

                  }
                    <div className="admin-pagination">
              {   totalCount > pagination ? (
              <Pagination>
                  <Pagination.First onClick={() => setPage(1)}/>
                  <Pagination.Prev onClick={() => page > 1 ? setPage(page - 1) : ''}/>
                  <Pagination.Item>{page}</Pagination.Item>
                 <Pagination.Next onClick={() => page < Math.ceil(totalCount/pagination) ? setPage(page + 1) : ''}/>
                 <Pagination.Last onClick={() => setPage(Math.ceil(totalCount/pagination))}/>
                </Pagination>
            ) : ""
 }
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminWithdraw;
