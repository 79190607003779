import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import "./adminedit.css";
import { editSubadmin, getSubAdminList } from "../../../actions/adminAction";

const Edit = ({ page, pagination, selectedAdmin, setShow }) => {
  const [adminRoles, setAdminRoles] = useState({});
  const [adminManagementCheck, setAdminManagementCheck] = useState(false);
  const [calenderManagementCheck, setCalenderManagementCheck] = useState(false);
  const [eventManagementCheck, setEventManagementCheck] = useState(false);
  const [userManagementCheck, setUserManagementCheck] = useState(false);
  const [mailManagementCheck, setMailManagementCheck] = useState(false);

  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const dispatch = useDispatch();

  const editAdmin = useSelector((state) => state.editAdmin);
  const { loading, error, resetMsg } = editAdmin;

  const getAdmin = useSelector((state) => state.getAdmin);
  const { adminDetails } = getAdmin;

  const handleEditAdmin = (values) => {
    const payload = {
      adminId: selectedAdmin.id,
      email: values.email,
      username: values.username,
      role: JSON.stringify(adminRoles),
    };
    dispatch(editSubadmin(payload));
  };

  useEffect(() => {
    if (error) {
      toast.error(error, { id: "A" });
    }
    if (resetMsg) {
      toast.success(resetMsg, { id: "A" });
      setShow("");
      dispatch(getSubAdminList({ page, pagination }));
    }
  }, [dispatch, error, resetMsg]);

  useEffect(() => {
    if (selectedAdmin.role !== "superadmin") {
      const roleObj = JSON.parse(selectedAdmin.role);
      setAdminRoles(roleObj);
      setAdminManagementCheck(!!roleObj.adminManagement);
      setCalenderManagementCheck(!!roleObj.calenderManagement);
      setEventManagementCheck(!!roleObj.eventManagement);
      setUserManagementCheck(!!roleObj.userManagement);
      setMailManagementCheck(!!roleObj.mailManagement);
    }
  }, [selectedAdmin]);

  const handleCheckBox = (e) => {
    const isCheck = e.target.checked;
    const value = e.target.value;
    if (isCheck) {
      setAdminRoles({ ...adminRoles, [value]: value });
    } else {
      delete adminRoles[value];
    }
  };

  return (
    <div className="signup">
      <div className="register-section">
        <div className="container back-color">
          <div className="row">
            <div className="col-md-12">
              <div className="register-form">
                <h1>Edit Sub Admin</h1>
                <div className="rigister-tab">
                  <Form onSubmit={handleSubmit(handleEditAdmin)}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>UserName</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="hfhg"
                            name="username"
                            autoComplete="off"
                            disabled
                            value={selectedAdmin && selectedAdmin.username}
                            ref={register({
                              required: true,
                              maxLength: 20,
                              minLength: 4,
                            })}
                          />
                          {errors.lastName && (
                            <Alert variant="danger">
                              {errors.lastName.type === "maxLength"
                                ? "Maximum Length is 20"
                                : errors.lastName.type === "minLength"
                                ? "Minimim length is 3"
                                : "this field is required"}
                            </Alert>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="email"
                            // value={adminDetails && adminDetails.admininfo.email}
                            name="email"
                            autoComplete="off"
                            defaultValue={selectedAdmin.email}
                            ref={register({
                              required: true,
                              maxLength: 20,
                              minLength: 4,
                            })}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <h5 className="sub-admin-roles">Roles</h5>
                      </div>
                      <div className="col-md-12 mb-2">
                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                name="adminManagement"
                                checked={adminManagementCheck}
                                onChange={(e) => {
                                  setAdminManagementCheck(
                                    !adminManagementCheck
                                  );
                                  handleCheckBox(e);
                                }}
                                value="adminManagement"
                                label="Sub Admin Management"
                                ref={register()}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                name="calenderManagement"
                                checked={calenderManagementCheck}
                                onChange={(e) => {
                                  setCalenderManagementCheck(
                                    !calenderManagementCheck
                                  );
                                  handleCheckBox(e);
                                }}
                                value="calenderManagement"
                                label="Calendar Management"
                                ref={register()}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                name="userManagement"
                                checked={userManagementCheck}
                                onChange={(e) => {
                                  setUserManagementCheck(!userManagementCheck);
                                  handleCheckBox(e);
                                }}
                                value="userManagement"
                                label="User Management"
                                ref={register()}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-4">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                name="eventManagement"
                                checked={eventManagementCheck}
                                onChange={(e) => {
                                  setEventManagementCheck(
                                    !eventManagementCheck
                                  );
                                  handleCheckBox(e);
                                }}
                                value="eventManagement"
                                label="Event Management"
                                ref={register()}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-4">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                type="checkbox"
                                name="mailManagement"
                                checked={mailManagementCheck}
                                onChange={(e) => {
                                  setMailManagementCheck(!mailManagementCheck);
                                  handleCheckBox(e);
                                }}
                                value="mailManagement"
                                label="Mail Management"
                                ref={register()}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="text-center">
                          <Button
                            className="s-btn"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Edit.propTypes = {
  page: PropTypes.number,
  pagination: PropTypes.number,
  roles: PropTypes.object,
  selectedAdmin: PropTypes.object,
  setShow: PropTypes.func,
};
export default Edit;
