export const GET_COUNTS_REQUEST = "GET_COUNTS_REQUEST";
export const GET_COUNTS_SUCCESS = "GET_COUNTS_SUCCESS";
export const GET_COUNTS_FAIL = "GET_COUNTS_FAIL";
export const GET_MY_CALENDAR = "GET_MY_CALENDAR";
export const ADD_SUBADMIN_REQUEST = "ADD_SUBADMIN_REQUEST";
export const ADD_SUBADMIN_SUCCESS = "ADD_SUBADMIN_SUCCESS";
export const ADD_SUBADMIN_FAIL = "ADD_SUBADMIN_FAIL";

export const SUBADMIN_LIST_REQUEST = "SUBADMIN_LIST_REQUEST";
export const SUBADMIN_LIST_SUCCESS = "SUBADMIN_LIST_SUCCESS";
export const SUBADMIN_LIST_FAIL = "SUBADMIN_LIST_FAIL";

export const BLOCK_SUBADMIN_REQUEST = "BLOCK_SUBADMIN_REQUEST";
export const BLOCK_SUBADMIN_SUCCESS = "BLOCK_SUBADMIN_SUCCESS";
export const BLOCK_SUBADMIN_FAIL = "BLOCK_SUBADMIN_FAIL";

export const UNBLOCK_SUBADMIN_REQUEST = "UNBLOCK_SUBADMIN_REQUEST";
export const UNBLOCK_SUBADMIN_SUCCESS = "UNBLOCK_SUBADMIN_SUCCESS";
export const UNBLOCK_SUBADMIN_FAIL = "UNBLOCK_SUBADMIN_FAIL";
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const DELETED_MEMBER_LIST_REQUEST = "DELETED_MEMBER_LIST_REQUEST";
export const DELETED_MEMBER_LIST_SUCCESS = "DELETED_MEMBER_LIST_SUCCESS";
export const DELETED_MEMBER_LIST_FAIL = "DELETED_MEMBER_LIST_FAIL";

export const ADD_GAME_REQUEST = "ADD_GAME_REQUEST";
export const ADD_GAME_SUCCESS = "ADD_GAME_SUCCESS";
export const ADD_GAME_FAIL = "ADD_GAME_FAIL";

export const ADD_PLATFORM_REQUEST = "ADD_PLATFORM_REQUEST";
export const ADD_PLATFORM_SUCCESS = "ADD_PLATFORM_SUCCESS";
export const ADD_PLATFORM_FAIL = "ADD_PLATFORM_FAIL";

export const ADD_GAME_VERSION_REQUEST = "ADD_GAME_VERSION_REQUEST";
export const ADD_GAME_VERSION_SUCCESS = "ADD_GAME_VERSION_SUCCESS";
export const ADD_GAME_VERSION_FAIL = "ADD_GAME_VERSION_FAIL";

export const DELETE_SUBADMIN_REQUEST = "DELETE_SUBADMIN_REQUEST";
export const DELETE_SUBADMIN_SUCCESS = "DELETE_SUBADMIN_SUCCESS";
export const DELETE_SUBADMIN_FAIL = "DELETE_SUBADMIN_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const BLOCK_USER_REQUEST = "BLOCK_USER_REQUEST";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_FAIL = "BLOCK_USER_FAIL";

export const UNBLOCK_USER_REQUEST = "UNBLOCK_USER_REQUEST";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const UNBLOCK_USER_FAIL = "UNBLOCK_USER_FAIL";

// for Admin
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAIL = "NEW_PASSWORD_FAIL";

export const EDIT_ADMIN_REQUEST = "EDIT_ADMIN_REQUEST";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL";

export const MY_DETAILS_REQUEST = "MY_DETAILS_REQUEST";
export const MY_DETAILS_SUCCESS = "MY_DETAILS_SUCCESS";
export const MY_DETAILS_FAIL = "MY_DETAILS_FAIL";

export const EDIT_DETAILS_REQUEST = "EDIT_DETAILS_REQUEST";
export const EDIT_DETAILS_SUCCESS = "EDIT_DETAILS_SUCCESS";
export const EDIT_DETAILS_FAIL = "EDIT_DETAILS_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";

export const BLAST_LIST_REQUEST = "BLAST_LIST_REQUEST";
export const BLAST_LIST_SUCCESS = "BLAST_LIST_SUCCESS";
export const BLAST_LIST_FAIL = "BLAST_LIST_FAIL";

export const POLICY_LIST_REQUEST = "POLICY_LIST_REQUEST";
export const POLICY_LIST_SUCCESS = "POLICY_LIST_SUCCESS";
export const POLICY_LIST_FAIL = "POLICY_LIST_FAIL";

export const RECENT_TRANSACTION_LIST_REQUEST =
  "RECENT_TRANSACTION_LIST_REQUEST";
export const RECENT_TRANSACTION_LIST_SUCCESS =
  "RECENT_TRANSACTION_LIST_SUCCESS";
export const RECENT_TRANSACTION_LIST_FAIL = "RECENT_TRANSACTION_LIST_FAIL";

export const GET_KYC_REQUEST = "GET_KYC_REQUEST";
export const GET_KYC_SUCCESS = "GET_KYC_SUCCESS";
export const GET_KYC_FAIL = "GET_KYC_FAIL";

export const GET_WITHDRAW_REQUEST = "GET_WITHDRAW_REQUEST";
export const GET_WITHDRAW_SUCCESS = "GET_WITHDRAW_SUCCESS";
export const GET_WITHDRAW_FAIL = "GET_WITHDRAW_FAIL";

export const GET_MATCHING_REQUEST = "GET_MATCHING_REQUEST";
export const GET_MATCHING_SUCCESS = "GET_MATCHING_SUCCESS";
export const GET_MATCHING_FAIL = "GET_MATCHING_FAIL";

export const UNREAD_ADMIN_NOTIFY_REQUEST = "UNREAD_ADMIN_NOTIFIY_REQUEST";
export const UNREAD_ADMIN_NOTIFY_SUCCESS = "UNREAD_ADMIN_NOTIFIY_SUCCESS";
export const UNREAD_ADMIN_NOTIFY_FAIL = "UNREAD_ADMIN_NOTIFIY_FAIL";

export const GET_KYC_REJECTED_REQUEST = "GET_KYC_REJECT_REQUEST";
export const GET_KYC_REJECTED_SUCCESS = "GET_KYC_REJECT_SUCCESS";
export const GET_KYC_REJECTED_FAIL = "GET_KYC_REJECT_FAIL";

export const GET_KYC_VERIFIED_REQUEST = "GET_KYC_VERIFIED_REQUEST";
export const GET_KYC_VERIFIED_SUCCESS = "GET_KYC_VERIFIED_SUCCESS";
export const GET_KYC_VERIFIED_FAIL = "GET_KYC_VERIFIED_FAIL";

export const GET_DISPUTE_MATCHES_REQUEST = "GET_DISPUTE_MATCHES_REQUEST";
export const GET_DISPUTE_MATCHES_SUCCESS = "GET_DISPUTE_MATCHES_SUCCESS";
export const GET_DISPUTE_MATCHES_FAIL = "GET_DISPUTE_MATCHES_FAIL";

export const SUPPORT_USER_REQUEST = "SUPPORT_USER_REQUEST";
export const SUPPORT_USER_SUCCESS = "SUPPORT_USER_SUCCESS";
export const SUPPORT_USER_FAIL = "SUPPORT_USER_FAIL";

export const PAYMENT_TRANSACTION_LIST_REQUEST =
  "PAYMENT_TRANSACTION_LIST_REQUEST";
export const PAYMENT_TRANSACTION_LIST_SUCCESS =
  "PAYMENT_TRANSACTION_LIST_SUCCESS";
export const PAYMENT_TRANSACTION_LIST_FAIL = "PAYMENT_TRANSACTION_LIST_FAIL";

export const BLAST_LIST_BY_ID_REQUEST = "BLAST_LIST_BY_ID_REQUEST";
export const BLAST_LIST_BY_ID_SUCCESS = "BLAST_LIST_BY_ID_SUCCESS";
export const BLAST_LIST_BY_ID_FAIL = "BLAST_LIST_BY_ID_FAIL";
export const EXPIRED_MEMBERSHIP_LIST_REQUEST =
  "EXPIRED_MEMBERSHIP_LIST_REQUEST";
export const EXPIRED_MEMBERSHIP_LIST_SUCCESS =
  "EXPIRED_MEMBERSHIP_LIST_SUCCESS";
export const EXPIRED_MEMBERSHIP_LIST_FAIL = "EXPIRED_MEMBERSHIP_LIST_FAIL";

export const USER_LIST_BY_ID_REQUEST = "USER_LIST_BY_ID_REQUEST";
export const USER_LIST_BY_ID_SUCCESS = "USER_LIST_BY_ID_SUCCESS";
export const USER_LIST_BY_ID_FAIL = "USER_LIST_BY_ID_FAIL";
export const FOLLOW_LIST_REQUEST = "FOLLOW_LIST_REQUEST";
export const FOLLOW_LIST_SUCCESS = "FOLLOW_LIST_SUCCESS";
export const FOLLOW_LIST_FAIL = "FOLLOW_LIST_FAIL";

export const ADVERTISE_LIST_REQUEST = "ADVERTISE_LIST_REQUEST";
export const ADVERTISE_LIST_SUCCESS = "ADVERTISE_LIST_SUCCESS";
export const ADVERTISE_LIST_FAIL = "ADVERTISE_LIST_FAIL";

export const ADVERTISE_LIST_BY_ID_REQUEST = "ADVERTISE_LIST_BY_ID_REQUEST";
export const ADVERTISE_LIST_BY_ID_SUCCESS = "ADVERTISE_LIST_BY_ID_SUCCESS";
export const ADVERTISE_LIST_BY_ID_FAIL = "ADVERTISE_LIST_BY_ID_FAIL";

// export const MAIL_REQUEST = 'MAIL_REQUEST'
// export const MAIL_FAIL = 'MAIL_FAIL'
// export const MAIL_SUCCESS = 'MAIL_SUCCESS'
