import React, { useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { login } from "../../../actions/adminAction";
import { adminInstance } from "../../../axios";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
  const adminLoginData = useSelector((state) => state.adminLogin);
  const { loading, error, adminInfo } = adminLoginData;
  useEffect(() => {
    if (error && error.code === 400) {
      toast.error(error.msg);
      setTimeout(() => {
        history.push(`/admin/verifyOtp?email=${error.email}`);
      }, 1000);
    } else if (error) toast.error(error);
    if (adminInfo) window.location.href = "/admin/dashboard";
  }, [history, adminInfo, error]);

  const handleAdminLogin = async (values) => {
    let { email, password, remember } = values || {};
    const { data } = await adminInstance.post("/login", {
      email,
      password,
      remember,
    });
    const { code, msg } = data;
    if (code === 200) {
      const { username, token, roles, adminId,photo } = data;
      const payload = {
        username,
        token,
        adminId,
        roles,
        photo,
      };
      console.log('adminId',adminId);
      localStorage.setItem("admintoken", JSON.stringify(payload));
      // localStorage.setItem("adminId", payload.adminId);
      localStorage.setItem("adminRoles", roles);
      window.location.href = "/admin/dashboard";
    } else {
      toast.error(msg);
    }
  };
  return (
    <div className="admin-login">
      <div className="admin-login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="admin-login-form">
                <div className="admin-login-box">
                  <h2>Login with email</h2>
                  <Form onSubmit={handleSubmit(handleAdminLogin)}>
                    <Form.Group controlId="formBasicloginone">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Your email address"
                        name="email"
                        autoComplete="off"
                        ref={register({
                          required: true,
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Not a valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <Alert variant="danger">Enter valid Email</Alert>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="password-show"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Your password"
                        name="password"
                        autoComplete="off"
                        ref={register({ required: true })}
                      />
                      {errors.password && (
                        <Alert variant="danger">this field required</Alert>
                      )}
                    </Form.Group>
                    <div className="forget-password">
                      <div className="remember-me">
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          name="remember"
                          ref={register}
                        />
                      </div>

                      <Link className="forget-link" to="/admin/forgetPassword">
                        Forget Password?
                      </Link>
                    </div>
                    <div className="login-button">
                      <Button
                        type="submit"
                        className="l-btn"
                        disabled={loading}
                      >
                        {loading ? <Spinner animation="border" /> : "Login"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
