import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AdminWrapper from "../adminwrapper/wrapper";
import GameVersion from "./gameversion";
import GameForm from "./gameform";
import GamePlatform from "./platform";

const AddGame = () => {

    return (
        <AdminWrapper>
            <div className="add-game">
                <div className="container">
                    <h2 className="admin-title">Game</h2>
                    <div className="admin-tab">
                        <Tabs defaultActiveKey="game" id="uncontrolled-tab-example-game">
                            <Tab eventKey="game" title="Game">
                                <GameForm />
                            </Tab>
                            <Tab eventKey="platform" title="Platform">
                                <GamePlatform />
                            </Tab>
                            <Tab eventKey="version" title="Game Version">
                                <GameVersion /> 
                            </Tab>
                        </Tabs>
                    </div>
            </div>
            </div> 
        </AdminWrapper>      
       
    );
  };
  
export default AddGame;









