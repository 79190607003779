import React, { useEffect } from "react";
import AdminWrapper from "../adminwrapper/wrapper";
import { useDispatch, useSelector } from "react-redux";
import { getCountData, getUsersList } from "../../../actions/adminAction";
import Loader from "../../loader/loader";
import { useHistory } from "react-router-dom";
import moment from "moment";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const getCountsData = useSelector((state) => state.getCount);
  const { countData, loading } = getCountsData;

  useEffect(() => {
    dispatch(getCountData());
  }, [dispatch]);
  const history = useHistory();
  const handleUser = (id) => {
    // dispatch(getUsersList());
    id === ""
      ? history.push(`/admin/user`)
      : history.push(`/admin/user?=${id}`);
  };
  const handleEvent = (id) => {
    // dispatch(getUsersList());
    id === ""
      ? history.push(`/admin/event`)
      : history.push(`/admin/event?=${id}`);
  };
  // const handleUpcomingEvent = (id) => {
  //   console.log("Byeeeeeeeeeeeeeeee", id);
  // };
  return loading ? (
    <Loader />
  ) : (
    <AdminWrapper>
      <div className="dashboard">
        <div className="container">
          <h2 className="admin-title ">DASHBOARD</h2>
          <h2 className="admin-title toptitle">Members</h2>
          <div className="row">
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser("");
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Members</h6>
                      <span className="font-weight-600 h4 mb-0">
                        {countData?.allUser?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-user"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(8);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Authors</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.authors?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-ban"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(1);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Author Premiums</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.authors_premium?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-ban"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(2);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Readers</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.readers?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-dot-circle-o"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(3);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Bloggers</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.bloggers?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-gamepad"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(6);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">
                        Total Blogger Premiums
                      </h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.bloggers_premium?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-gamepad"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(4);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Vendors</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.vendors?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-users"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleUser(5);
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total PR Firms</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.prfirms?.count}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-users"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 className="admin-title toptitle">Events</h2>
          <div className="row">
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("");
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Events</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {countData?.allEvents}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("upcoming");
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Total Upcoming Events</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.UpcomingEvents}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      // onClick={() => {
                      //   handleEvent(moment(new Date()).format("YYYY-MM-DD"));
                      //   }}
                      onClick={() => {
                        handleEvent("today");
                      }}
                      role="presentation"
                    >
                      <h6 className="text-muted mb-2">Today’s Events</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.todayEvents}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 className="admin-title toptitle">Event Types</h2>
          <div className="row">
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("totalBookRelease");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Book Releases</h6>
                      <span className="font-weight-600 h4 mb-0">
                        {countData?.totalBookRelease}
                      </span>
                    </div>
                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("totalGiveway");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Giveaways</h6>
                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalGiveaway}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("totalCoverRevealPromo");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Book Promos</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalCoverRevealPromo}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("todayReleaseParty");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Release Parties</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalReleaseParty}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("todayCoverReveal");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Cover Reveals</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalCoverReveal}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("todayBookSigning");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total Book Signings</h6>

                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalBookSigning}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6  col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div
                      className="col"
                      onClick={() => {
                        handleEvent("todayArcSignUp");
                      }}
                    >
                      <h6 className="text-muted mb-2">Total ARC Signups</h6>
                      <span className="font-weight-600 h4 mb-0">
                        {" "}
                        {countData?.totalTodayArcSignUp}
                      </span>
                    </div>

                    <div className="col-auto">
                      <span className="fa fa-money"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminDashboard;
