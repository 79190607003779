import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AdminWrapper from "../adminwrapper/wrapper";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import isEmpty from "lodash.isempty";
import MyProfile from "./myprofile";
import user from "../../../assets/user.png";

import { editMyDetails, getMyDetails } from "../../../actions/adminAction";

import { adminInstance } from "../../../axios";
import { server } from "../../../config/keys";

const AccountSetting = () => {
  const history = useHistory();
  const [preview, setPreview] = useState(false);
  const { register, handleSubmit } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();

  const myProfileData = useSelector((state) => state.adminProfile);
  const { userData } = myProfileData;

  const editProfileData = useSelector((state) => state.editAdminProfile);
  const { error, editData } = editProfileData;

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (editData) {
      toast.success("Profile Updated Successfully.");
      history.push("/admin/dashboard");
    }
    if (isEmpty(myProfileData)) {
      dispatch(getMyDetails());
    }
  }, [dispatch, myProfileData, error, editData, history]);

  const handleEditProfile = (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      email: values.email,
      photo: values.photo,
    };
    dispatch(editMyDetails(payload));
  };

  const handlePicUpload = async (e) => {
    console.log("e", e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    const data = new FormData();

    data.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await adminInstance.post(
      "/uploadAdminImage",
      data,
      config
    );
    if (response.data.code === 200) {
      toast.success("Profile Updated Successfully.");
      dispatch(getMyDetails());
    }
  };
  return (
    <MyProfile>
      <div className="account-setting">
        <div className="edit-profile">
          <div className="edit-profile-content">
            <div className="register-form">
              <Form onSubmit={handleSubmit(handleEditProfile)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="uplaod-profile-photo">
                      <div className="user-pic">
                        <img
                          src={
                            preview
                              ? preview
                              : userData &&
                                userData.photo !== "admin/default.png" &&
                                userData.photo
                              ? `${server}/${userData.photo}`
                              : user
                          }
                          alt="User"
                        />
                      </div>
                      <Form.Group controlId="formBasicFirst">
                        <Form.Control
                          id="file"
                          type="file"
                          placeholder="photo"
                          name="photo"
                          autoComplete="off"
                          hidden
                          onChange={(e) => handlePicUpload(e)}
                        />
                        <Form.Label className="btn-2" for="file">
                          Upload
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                        <Form.Group controlId="formBasicFirst">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                autoComplete="off"
                                                defaultValue={userData && userData.firstName}
                                                ref={register}
                                            />

                                        </Form.Group>
                                    </div> */}
                  {/* <div className="col-md-6">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                autoComplete="off"
                                                defaultValue={userData && userData.lastName}
                                                ref={register}
                                            />
                                        </Form.Group>
                                    </div> */}

                  <div className="col-md-12">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        name="username"
                        autoComplete="off"
                        defaultValue={userData && userData.username}
                        ref={register({ required: true })}
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        defaultValue={userData && userData.email}
                        ref={register({ required: true })}
                        disabled
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <div className="text-center">
                      <Button className="s-btn" type="submit">
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </MyProfile>
  );
};

export default AccountSetting;
