import * as constants from '../constants/authConstants';
export const userRegisterReducers = (state = {}, action) => {
  switch (action.type) {
    case constants.USER_REGISTER_REQUEST:
      return { loading: true };
    case constants.USER_REGISTER_SUCCESS:
      return { loading: false, userData: action.payload };
    case constants.USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case constants.USER_VERIFIED:
      return { loading: false, userData:undefined}
    case '': 
      return {}
    default:
      return state;
  }
};

export const userLoginReducers = (state = {}, action) => {
  switch (action.type) {
    case constants.USER_LOGIN_REQUEST:
      return { loading: true };
    case constants.USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: {
          token: action.payload.token,
          username: action.payload.username,
        },
      };
    case constants.USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case constants.USER_LOGOUT:
      return {};
    case constants.USER_VERIFIED:
      return {};
      case '': 
      return {}
    default:
      return state;
  }
};

export const verifyOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.VERIFY_OTP_REQUEST:
      return { loading: true };
    case constants.VERIFY_OTP_SUCCESS:
      return {
        loading: false,
        otpInfo: action.payload,
      };
    
    case constants.VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload };
    case '': 
      return {}
    default:
      return state;
  }
};

export const forgetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.FORGET_PASSWORD_REQUEST:
      return { loading: true };
    case constants.FORGET_PASSWORD_SUCCESS:
      return {
        loading: false,
        forgetInfo: {
          email: action.payload.email,
          username: action.payload.username,
          msg: action.payload.msg
        },
      };
    case constants.FORGET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
    default:
      return state;
  }
};

export const newPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.NEW_PASSWORD_REQUEST:
      return { loading: true };
    case constants.NEW_PASSWORD_SUCCESS:
      return {
        loading: false,
        resetMsg: action.payload,
      };
    case constants.NEW_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
      case '': 
      return {}
    default:
      return state;
  }
};


export const adminLoginReducers = (state = {}, action) => {
  switch (action.type) {
    case constants.ADMIN_LOGIN_REQUEST:
      return { loading: true };
    case constants.ADMIN_LOGIN_SUCCESS:
      return {
        loading: false,
        adminInfo: {
          token: action.payload.token,
          username: action.payload.username,
          roles: action.payload.roles,
          photo:action.payload.photo
        },
      };
    case constants.ADMIN_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case constants.ADMIN_LOGOUT:
      return {};
    case constants.ADMIN_VERIFIED:
      return {};
    case '' :
      return {};
    default:
      return state;
  }
};