import React from "react";
import { Button } from "react-bootstrap";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useAsyncDebounce,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="input-box">
    <span>
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        //  placeholder="search here"
      />
    </span>
    </div>
  );
}
const handleModal = () => {
  console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiigfgggggggggggggggg");
};
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const UserTable = ({ columns, data }) => {
 
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    globalFilter,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: [
          "id",
          "is_active",
          "emails_frequency",
          "updated_date",
          "reminder_frequency",
          "send_reminder",
          "membership_id",
          "lname",
          "profile_details_with_values",
        ],
      },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    // Render the UI for your table
    return (
      <div>
        {count ? (
          <>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <div className="table-responsive">
              <table className="table" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
            <ul className="pagination">
              <li
                className="page-item"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <a className="page-link">First</a>
              </li>
              <li
                className="page-item"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <a className="page-link">{"<"}</a>
              </li>
              <li
                className="page-item"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <a className="page-link">{">"}</a>
              </li>
              <li
                className="page-item"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className="page-link">Last</a>
              </li>
              <li>
                <a className="page-link">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </a>
              </li>
              <li>
                <a className="page-link">
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px", height: "20px" }}
                  />
                </a>
              </li>{" "}
              <select
                className="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ width: "120px", height: "38px" }}
              >
                {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </ul>
          </>
        ) : (
          <div>
            <span style={{ color: "white" }}>No records Found</span>
          </div>
        )}
      </div>
    );
  }
};

export default UserTable;
