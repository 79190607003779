import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Dropdown } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getSubAdminList, blockAdmin } from "../../../actions/adminAction";
import Loader from "../../loader/loader";
import Edit from "../adminedit/adminedit";
import DeletePopup from "../popup/delete";
import SubAdminTable from "./subAdminTable";
import dots from "../../../assets/dots.png";
const SubAdminList = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);

  const subAdminListData = useSelector((state) => state.subAdminList);
  const { error, subAdminList, loading } = subAdminListData;

  const adminBlockData = useSelector((state) => state.blockSubAdmin);
  const { blockAdminStatus } = adminBlockData;
  console.log("blockAdminStatus", blockAdminStatus);

  const adminDeleteData = useSelector((state) => state.deleteSubAdmin);
  const { deleteAdminStatus } = adminDeleteData;

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState();
  const [username, setusername] = useState();
  const [selectedAdmin, setSelectedAdmin] = useState({});

  const handleModal = (action, object) => {
    console.log("action", action);
    console.log("object", object);

    if (object) {
      setShow(action);
      setSelectedAdmin(object);
    }
  };

  const [roles, setRoles] = useState([]);
  const columns = [
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Action",
      accessor: "Edit",
      Cell: (cell) => {
        return (
          <div className="action-dropdown action-buttons">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <img src={dots} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Button
                    value={cell.row.values.email}
                    className="edit-btn"
                    onClick={() => handleModal("edit", cell.row.values)}
                  >
                    Edit{" "}
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    value={cell.row.values.email}
                    onClick={() => handleModal("delete", cell.row.values)}
                    className="delete-btn"
                  >
                    Delete{" "}
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Button
                    onClick={() =>
                      dispatch(
                        !cell.isBlocked
                          ? blockAdmin(cell.row.values.email)
                          : blockAdmin(cell.row.values.email),
                        ""
                      )
                    }
                    className="block-btn"
                  >
                    {cell.row.original.isdisabled === 0
                      ? "Suspend"
                      : "Un-Suspend"}
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    if (!subAdminList) {
      dispatch(getSubAdminList({ page, pagination }));
    }
  }, [dispatch, error, adminBlockData, adminDeleteData, page, pagination]);

  useEffect(() => {
    if (blockAdminStatus) {
      toast.success(blockAdminStatus, { id: "A" });
      dispatch(getSubAdminList({ page, pagination }));
    }
    if (adminBlockData.error) toast(adminBlockData.error, { id: "A" });
  }, [dispatch, blockAdminStatus, adminBlockData]);

  useEffect(() => {
    if (deleteAdminStatus) {
      toast.success(deleteAdminStatus, { id: "A" });
      setShow(true);
    }

    if (adminDeleteData.error) toast(adminDeleteData.error, { id: "A" });
  }, [dispatch, deleteAdminStatus, adminDeleteData]);

  return (
    <div className="row">
      <div className="col-md-12">
        {loading ? (
          <Loader />
        ) : (
          <SubAdminTable
            columns={columns}
            data={subAdminList ? subAdminList.list : []}
          />
        )}
      </div>

      <Modal
        className="signup-popup subadmin-edit"
        show={show === "edit"}
        onHide={() => setShow("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Edit
            role={roles}
            page={page}
            pagination={pagination}
            selectedAdmin={selectedAdmin}
            setShow={setShow}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="login-popup"
        show={show === "delete"}
        onHide={() => setShow("")}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeletePopup
            handleModal={handleModal}
            mail={selectedAdmin}
            setShow={setShow}
            type="admin"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubAdminList;
