import React, { useEffect, useState } from "react";

import AdminWrapper from "../adminwrapper/wrapper";
import { Button, Table, Modal, Form, Spinner } from "react-bootstrap";
import { adminInstance } from "../../../axios";
import Loader from "../../loader/loader";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import AddPoliciesTable from "./AddPoliciesTable";
import ReactQuill,{Quill}from "react-quill";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import "./policy.css";

const AddPolicy = () => {
  const [termsVersion, setTermsVersion] = useState(1);
  const [policyVersion, setPolicyVersion] = useState(1);
  const [cookieVersion, setCookieVersion] = useState(1);
  const initialState = {
    type: "",
    consent_body: "",
    createdAt: Date.now(),
    reference: "",
  };
  
  const getConsentList = async () => {
    const response = await adminInstance.get("getConsents");
    console.log("This is the reponse data", { response: response.data });

    if (response.status === 200) {
      setConsentList(response.data.result);
      setTermsVersion(response.data.maxTermsVersion);
      setPolicyVersion(response.data.maxPrivacyVersion);
      setCookieVersion(response.data.maxCookieVersion);
      setLoading(false);
    }
  };

  useEffect(() => {
    getConsentList();
  }, []);


  const [consentList, setConsentList] = useState([]);
  const [show, setShow] = useState(false);
  const [showDeets, setShowDeets] = useState(false);
  const [modalDeetsConent, setModalDeetsConent] = useState();
  const [disablePicklist, setDisablePicklist] = useState(false);


  const [values, setValues] = useState(initialState);
 
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setValues({ ...values, [name]: value });

   
  };
  const    quillFonts  = ['arial', 'raleway', 'calibri',  'courier', 'georgia', 'lucida', 
  'open', 'roboto',  'tahoma',  'times',  'trebuchet',  'verdana'     ];

var fonts = Quill.import('formats/font');
fonts.whitelist = quillFonts;

  const handleEditorChange = (event, editor) => {
      const data = editor && editor.getData();
  
      setValues({ ...values, consent_body: data })
    };
    function uploadAdapter (loader) {
      return {
        upload: () => {
          return new Promise((resolve, reject) => {
            const body = new FormData();
            loader.file.then(file => {
              body.append('file', file);
              console.log('hiieiee', file);
              
              console.log("whhhhhhh",body)   
  
              const response =  adminInstance.post("/mailImage", body);
              response.then(res => res.data)
                .then(res => {
                  console.log('res1233', res);
                  resolve({
                    default: res
                  });
                })
                .catch(err => {
                  reject(err);
                });
            });
          });
        }
      };
    };
  
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  const onSubmit = async (e) => {
    e.preventDefault();
   
    try {
      setLoading(true);
      let response;

      switch (values.type) {
        case "Terms & Conditions":
          response = await adminInstance.post("postPolicyContent", {
            ...values,
            version: termsVersion,
          });
          break;

        case "Privacy Policy":
          response = await adminInstance.post("postPolicyContent", {
            ...values,
            version: policyVersion,
          });
          break;

        case "Cookie Policy":
          response = await adminInstance.post("postPolicyContent", {
            ...values,
            version: cookieVersion,
          });

        default:
          break;
      }
      console.log("This is the reponse", response);
      if (response.status === 200) {
        setShow(false);
        setLoading(false);
        setValues(initialState);
        getConsentList();
      }
      console.log(values);
    } catch (e) {
      console.log(e);
    }
  };

  const editPolicy = (policy) => {
    setDisablePicklist(true);
    setValues({
      ...values,
      ...policy,
    });
    setShow(true);
  };

  const viewDeets = (content) => {
    setShowDeets(true);
    setModalDeetsConent(content);
  };
 
  const columns = [
    {
      Header: "Type",
      accessor: "type",
      
    },
    {
      Header: "Date/Time (EST)",
      accessor: "createdAt",
      Cell: (cell) => {
        return (
          <span>
            {moment(parseInt(cell.row.values.createdAt)).format(
              "MMM-DD-YYYY, hh:mm a"
            )}
          </span>
        );
      },
    },
  
    {
      Header: "Reference name",
      accessor: "reference",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.reference ? cell.row.values.reference : "NA"}
          </span>
        );
      },
    },
    {
      Header: "Version",
      accessor: "version",
      Cell: (cell) => {
        
        return (
      
          <div>
             {console.log("value",values.version)}
            {cell.row.values.version &&
            cell.row.values.type === "Terms & Conditions"
              ? `T${cell.row.values.version}`
              : cell.row.values.type === "Privacy Policy"
              ? `P${cell.row.values.version}`
              : cell.row.values.type === "Cookie Policy"
              ? `C${cell.row.values.version}`
              : null}
          </div>
        );
      },
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Edited",
      accessor: "edited",
    },

    {
      Header: "Action",
      accessor: "consent_body",
      Cell: (cell) => {
        return (
          <div>
            <Button onClick={() => viewDeets(cell.row.values)}>View</Button>
          </div>
        );
      },
    },
  ];
  return (

    <AdminWrapper>
      <div className="sub-admin">
        <div className="container">
          <h2 className="admin-title">Add Policies</h2>{" "}
          <button
            onClick={() => {
              setValues(initialState);
              setShow(true);
              setDisablePicklist(false);
            }}
          >
            Add New
          </button>
          <div className="admin-tab">
            <div className="row">
              <div className="col-md-12">
                {loading ? (
                  <Loader />
                ) : (
                  consentList && (
                    <AddPoliciesTable
                      columns={columns}
                      data={consentList &&  consentList.filter((el) => el.type !== 'Cookie Policy')}
                    />
                  )
                  // <Table striped bordered hover responsive>
                  //   <thead>
                  //     <tr>
                  //       <th>Type</th>
                  //       <th>Body</th>
                  //       <th>Version</th>
                  //       <th>Editable</th>
                  //       <th>Added Date</th>
                  //     </tr>
                  //   </thead>
                  //   <tbody>
                  //     {consentList.length ? (
                  //       consentList.map(
                  //         (
                  //           { type, version, consent_body, edited, createdAt },
                  //           i
                  //         ) => (
                  //           <tr key={`item-${i}`}>
                  //             <td>{type}</td>
                  //             <td>
                  //               <Button
                  //                 onClick={() =>
                  //                   viewDeets({ type, consent_body })
                  //                 }
                  //               >
                  //                 View
                  //               </Button>
                  //             </td>
                  //             <td> {version && `v${version}`}</td>
                  //             <td>
                  //               {!edited ? (
                  //                 <button
                  //                   onClick={() => editPolicy(consentList[i])}
                  //                 >
                  //                   Edit
                  //                 </button>
                  //               ) : (
                  //                 ""
                  //               )}
                  //             </td>
                  //             <td>
                  //               {console.log(typeof createdAt)}
                  //               {moment(parseInt(createdAt)).format(
                  //                 "MMMM Do YYYY, h:mm:ss A"
                  //               )}
                  //             </td>
                  //           </tr>
                  //         )
                  //       )
                  //     ) : (
                  //       <tr>
                  //         <td colSpan="4">No Policies Found</td>
                  //       </tr>
                  //     )}
                  //   </tbody>
                  // </Table>
                )}
                <div className="admin-pagination">
                  {/* <Pagination>
                      <Pagination.First onClick={() => setPage(1)} />
                      <Pagination.Prev
                        onClick={() => (page > 1 ? setPage(page - 1) : "")}
                      />
                      <Pagination.Item>{page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() =>
                          page < Math.ceil(userList.total / pagination)
                            ? setPage(page + 1)
                            : ""
                        }
                      />
                      <Pagination.Last
                        onClick={() =>
                          setPage(Math.ceil(userList.total / pagination))
                        }
                      />
                    </Pagination> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <Modal show={showDeets} onHide={() => setShowDeets(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalDeetsConent && modalDeetsConent.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="view-popup">
            {modalDeetsConent && (
              <h3
                dangerouslySetInnerHTML={{
                  __html: modalDeetsConent.reference,
                }}
              ></h3>
            )}
            {modalDeetsConent && (
              <div
                dangerouslySetInnerHTML={{
                  __html: modalDeetsConent.consent_body,
                }}
              ></div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="register-form">
            <Form onSubmit={onSubmit}>
              <div>
                <Form.Group controlId="formBasicFirst">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    value={values.type}
                    name="type"
                    disabled={disablePicklist}
                  >
                    <option value="">--Select--</option>
                    <option>Terms & Conditions</option>
                    <option>Privacy Policy</option>
                    {/* <option>Cookie Policy</option> */}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="policy-quill">
                <div>
                  <Form.Group>
                    <Form.Label>Reference name</Form.Label>
                    <Form.Control
                      as="input"
                      onChange={handleChange}
                      value={values.reference}
                      name="reference"
                    ></Form.Control>
                  </Form.Group>
                </div>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Content</Form.Label>
                     <CKEditor 
                        editor={FullEditor}
                        value={values.consent_body}
                        onChange={handleEditorChange}
                        config={{
                        extraPlugins: [uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            resizeOptions: [
                              {
                                name: 'resizeImage:original',
                                value: null,
                                label: 'Original'
                              },
                              {
                                name: 'resizeImage:40',
                                value: '40',
                                label: '40%'
                              },
                              {
                                name: 'resizeImage:60',
                                value: '60',
                                label: '60%'
                              }
                            ],
                            toolbar: [
                              'resizeImage'
                            ]
                          },
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',                                         
                          ],
                          supportAllValues: true
                        },
                        fontSize: {
                          options: [12,14, 16, 18, 20, 22, 24],
                      }
                        }
                      
                      
                      }
                      />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="text-right">
                  <Button className="s-btn" type="submit" disabled={loading}>
                    {loading && <Spinner animation="grow" />}
                    Add
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </AdminWrapper>
  );
};

export default AddPolicy;
