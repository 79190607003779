import React from "react";

const BlastEmail = (object) =>
  `<html
        data-editor-version="2"
        class="sg-campaigns"
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
          />
          <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
          <link rel="preconnect" href="https://fonts.gstatic.com">
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap" rel="stylesheet">
          <style>
             body{
              font-family: 'Poppins', sans-serif;
              background-color: #f7f7f7;
              padding: 0px;
              margin: 0px;
             }
             table tr td {
          font-size: 14px;
      }
      
      
         </style>
          </head>
          <body>
      <table style="margin: 0px auto;width:100%; max-width: 600px;padding-bottom:50px;padding:20px 25px ;color: #ddd; background-color: #383a44;border-radius: 5px;line-height: 1.8;">
        <tr>
          <td style="text-align: center; border-bottom:1px dashed #5d616d; padding-bottom: 10px;">
            <img
              src="https://www.gamersring.co/static/media/logo.ff07083c.png"
              alt="logo"
              width="250px"
            />
          </td>
        </tr>

        <tr>
          <td style="padding-top: 50px;"></td>
        </tr>
        <tr>
          <td style="padding-top: 10px; text-align: justify;">
            Welcome to Gamers Ring, in order to continue using our website, we
            need to verify your email address. Please use the following OTP to
            complete verification:
          </td>
        </tr>
        <tr>
          <td style="text-align: center; padding-top: 20px;">
            <span
              style="margin-top: 10px; display:inline-block;cursor: pointer; border: 1px solid #009bff; color: white; padding: 10px 15px; border-radius: 5px;"
              type="submit"
            ></span>
          </td>
        </tr>
        <tr>
          <td style="padding-top:15px">
            Thanks for playing on Gamers Ring
            <br />
            Gamers Ring Team
          </td>
        </tr>

        <tr>
          <td style="border-top:1px dashed #5d616d; text-align: center;padding-top:30px;padding-bottom:30px;">
            <img
              src="https://www.gamersring.co/static/media/logo.ff07083c.png"
              alt="logo"
              width="150px"
            />
          </td>
        </tr>
      </table>
    </body>
 </html>`;

export default BlastEmail;
