import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgetPasswordReducer,
  userLoginReducers,
  userRegisterReducers,
  verifyOtpReducer,
  newPasswordReducer,
  adminLoginReducers,
} from "./reducers/authReducers";
import {
  editProfileReducer,
  entryFeeReducer,
  gamePlatformReducer,
  gameTypeReducer,
  gameVersionListReducer,
  gameVersionReducer,
  myProfileReducer,
  uploadImageReducer,
  editGamerTagReducer,
  getGamerTagReducer,
  getMessageReducer,
  userRecentTransactionListReducer,
  updateKycReducer,
  instantMatchReducer,
  unreadNotificationReducer,
  singleMatchReducer,
  myFinishedMatchesReducer,
  myUnfinishedMatchesReducer,
  allLeaderboardReducer,
  gameLeaderboardReducer,
  recentFinishedMatchReducer,
  voteDetailReducer,
  supportMessageReducer,
  onlineUserReducer,
  challengeListReducer,
  recentChatsReducer,
  chatUserListReducer,
  sendChallengeListReducer,
  matchCardReducer,
  playerMatchCardReducer,
} from "./reducers/userReducers";
import {
  addSubAdminReducer,
  getCountReducer,
  subAdminListReducer,
  blockSubAdminReducer,
  userListReducer,
  deleteSubAdminReducer,
  deleteUserReducer,
  memberDeletedListReducer,
  blockUserReducer,
  adminforgetPasswordReducer,
  adminverifyOtpReducer,
  adminnewPasswordReducer,
  editAdminReducer,
  adminProfileReducer,
  editAdminProfileReducer,
  changeAdminPasswordReducer,
  getAdminReducer,
  allRecentTransactionListReducer,
  getKycListReducer,
  getWithdrawListReducer,
  getMatchesListReducer,
  unreadNotificationAdminReducer,
  getKycRejectedListReducer,
  getKycVerifiedListReducer,
  disputeMatchListReducer,
  supportUserReducer,
  paymentTransactionListReducer,
  blastListReducer,
  expiredMembershipListReducer,
  userListByIdReducer,
  policyListReducer,
  followListReducer,
  blastListByIdReducer,
  advertiseListReducer,
  advertiseListByIdReducer,
} from "./reducers/adminReducers";

const reducer = combineReducers({
  userRegister: userRegisterReducers,
  userLogin: userLoginReducers,
  verifyOtp: verifyOtpReducer,
  forgetPassword: forgetPasswordReducer,
  newPassword: newPasswordReducer,
  myProfile: myProfileReducer,
  editProfile: editProfileReducer,
  adminLogin: adminLoginReducers,
  getCount: getCountReducer,
  entryFee: entryFeeReducer,
  gameType: gameTypeReducer,
  gamePlatform: gamePlatformReducer,
  gameVersion: gameVersionReducer,
  addSubAdmin: addSubAdminReducer,
  subAdminList: subAdminListReducer,
  blockSubAdmin: blockSubAdminReducer,
  userList: userListReducer,
  memberDeleted: memberDeletedListReducer,
  uploadImage: uploadImageReducer,
  deleteSubAdmin: deleteSubAdminReducer,
  deleteUser: deleteUserReducer,
  blockUser: blockUserReducer,
  adminforgetPassword: adminforgetPasswordReducer,
  adminverifyOtp: adminverifyOtpReducer,
  adminnewPassword: adminnewPasswordReducer,
  editGamerTag: editGamerTagReducer,
  gameVersionList: gameVersionListReducer,
  getGamerTag: getGamerTagReducer,
  editAdmin: editAdminReducer,
  adminProfile: adminProfileReducer,
  editAdminProfile: editAdminProfileReducer,
  changeAdminPassword: changeAdminPasswordReducer,
  getMessage: getMessageReducer,
  getAdmin: getAdminReducer,
  userRecentTransactionList: userRecentTransactionListReducer,
  allRecentTransactionList: allRecentTransactionListReducer,
  updateKyc: updateKycReducer,
  instantMatch: instantMatchReducer,
  getKycList: getKycListReducer,
  getKycVerifiedList: getKycVerifiedListReducer,
  getKycRejectedList: getKycRejectedListReducer,
  getWithdrawList: getWithdrawListReducer,
  getMatchesList: getMatchesListReducer,
  unreadNotification: unreadNotificationReducer,
  unreadNotificationAdmin: unreadNotificationAdminReducer,
  singleMatch: singleMatchReducer,
  myFinishedMatches: myFinishedMatchesReducer,
  myUnfinishedMatches: myUnfinishedMatchesReducer,
  allLeaderboard: allLeaderboardReducer,
  gameLeaderboard: gameLeaderboardReducer,
  disputeMatchList: disputeMatchListReducer,
  recentFinishedMathes: recentFinishedMatchReducer,
  voteDetail: voteDetailReducer,
  supportMessage: supportMessageReducer,
  supportUser: supportUserReducer,
  onlineUsers: onlineUserReducer,
  challengeList: challengeListReducer,
  recentChats: recentChatsReducer,
  chatUserList: chatUserListReducer,
  sendChallengeList: sendChallengeListReducer,
  matchCardList: matchCardReducer,
  playerMatchCard: playerMatchCardReducer,
  paymentTransactionList: paymentTransactionListReducer,
  blastList: blastListReducer,
  expiredMembershipList: expiredMembershipListReducer,
  userListById: userListByIdReducer,
  policyList: policyListReducer,
  followList: followListReducer,
  blastListById: blastListByIdReducer,
  advertiseList: advertiseListReducer,
  advertiseListById: advertiseListByIdReducer,
});

const initialState = {
  userLogin: {
    userInfo: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null,
  },
  adminLogin: {
    adminInfo: localStorage.getItem("admintoken")
      ? JSON.parse(localStorage.getItem("admintoken"))
      : null,
  },
};

const middleWare = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
