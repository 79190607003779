import React, { useEffect, useState } from "react";
import { Form, Button, Alert, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { getGamePlatform, getGameVersion } from "../../../actions/gameAction";
import { adminInstance } from  '../../../axios';

const GameVersion = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [ platformId, setPlatformId] = useState('');
    const [addRule, setAddRules] = useState([]);
    const [addFormate, setAddFormate] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [title1, setTitle1] = useState('');
    const [description1, setDescription1] = useState('');
    const [errTitleRule, setErrTitleRule] = useState('');
    const [errTitleFormate, setErrTitleFormate] = useState('');
    const [errDescRule, setErrDescRule] = useState('');
    const [errDescFormate, setErrDescFormate] = useState('');
    const [ tags, setTags] = useState([]);
    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });

    const gameTypeData = useSelector(state => state.gameType);
    const { gameTypeInfo } = gameTypeData;

    const gamePlatformData = useSelector(state => state.gamePlatform);
    const { platformInfo } = gamePlatformData;

    const gameVersionListData = useSelector(state => state.gameVersionList);
    const { gameVersionInfo } = gameVersionListData;

    useEffect(() => {
        dispatch(getGameVersion())
    }, [dispatch])

    const addGameVersionHandler = async(values) => {
        setLoading(true);
        const res = await adminInstance.post('/addGameVersion', {
            gameName: values.gameName,
            description: values.description,
            category: values.category,
            rules: addRule,
            formate: addFormate,
            platformId: platformId
            });
        if(res.data.code === 200) {
            setAddRules([]);
            setAddFormate([]);
            setLoading(false);
            toast.success('Platform Added Successfully.');
            dispatch(getGameVersion())
        } else if (res.data.code === 401) {
            
            toast.error('Not Authorized!', {id: 'A'});
            localStorage.removeItem('admintoken');
            window.location.href = '/admin/login';
        }else {
            setLoading(false);
            toast.error('Error Occured');
        }
    }

    const handleChange = (e) => {
        console.log('------->',e.target.name);
        if(e.target.name ==='platform')
            setPlatformId(e.target.value);
        if(e.target.name === 'game')
        dispatch(getGamePlatform(e.target.value));
    }

    const addRulesHandler = () => {
        let data = addRule;
        if(title === '' ){
           return setErrTitleRule('This Field is Required');
        } if(description === '') {
           return setErrDescRule('This Field is Required');
        }
        data.push({
            title,
            description
        });
        setAddRules(data);
        setTitle('');
        setDescription('');
        setErrTitleRule('')
        setErrDescRule('');

    }

    const addFormateHandler = () => {
        let data = addFormate;
        if(title1 === '' ){
           return setErrTitleFormate('This Field is Required');
        } if(tags.length === 0) {
           return setErrDescFormate('This Field is Required');
        }
        data.push({
            title: title1,
            description: tags
        });
        setTags([]);
        setAddFormate(data);
        setTitle1('');
        setDescription1('');
        setErrTitleFormate('')
        setErrDescFormate('');
    }

    const titleHandler = (e) => {
        setTitle(e.target.value)
    }

    const descriptionHandler = (e) => {
        setDescription(e.target.value);
    }
    const title1Handler = (e) => {
        setTitle1(e.target.value)
    }

    const description1Handler = (e) => {
        setDescription1(e.target.value);
    }

    const addTags = (event) => {
        event.preventDefault();
        if (event.key === "Enter" && event.target.value !== "") {
          setTags([...tags, event.target.value]);
          event.target.value = "";
          setDescription1('')
        }
      };
      const removeTags = (index) => {
        setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
      };
    return (
            
        <div className="add-game-content">
        <div className="register-form">
                 <Form onSubmit={handleSubmit(addGameVersionHandler)}>
                     <div className="row">
                         <div className="col-md-6">
                             <Form.Group controlId="formBasicFirst">
                                 <Form.Label>Game</Form.Label>
                                 <Form.Control as="select" name='game' onChange={(e) => handleChange(e)} ref={register({ required: true })}>
                                 <option>Select Game</option>
                                     {gameTypeInfo && gameTypeInfo.map(item => (
                                         <option key={item._id} value={item._id}>{item.gameName}</option>
                                     ))}
                                 </Form.Control>
                                 {errors.platform && (<Alert variant='danger'>Please Select Platform</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                             <Form.Group controlId="formBasicFirst">
                                 <Form.Label>Platform</Form.Label>
                                 <Form.Control as="select" name='platform' onChange={(e) => handleChange(e)} ref={register({ required: true })}>
                                 <option>Select Platform</option>
                                     {platformInfo && platformInfo.map(item => (
                                         <option key={item._id} value={item._id}>{item.name}</option>
                                     ))}
                                 </Form.Control>
                                 {errors.platform && (<Alert variant='danger'>Please Select Platform</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-12">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Game Name</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Game Name"
                                     name="gameName"
                                     autoComplete="off"
                                     ref={register({ required: true })}
                                 />
                                  {errors.gameName && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                         <div className="format-form">
                             <h6>Rules</h6>
                             {addRule.length > 0 && (<Table><thead><th>Title</th><th>Description</th></thead>
                                <tbody>
                                    {addRule.map((item, i) => (
                                        <tr key={`{item-${i}}`}>
                                            <td>{item.title}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                             </Table>)}
                             <Form>
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Title</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Title"
                                     name="ruleTitle"
                                     autoComplete="off"
                                     value={title}
                                    onChange = {titleHandler}
                                 />
                                  {errTitleRule !== '' && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Description</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Description"
                                     name="ruleDescription"
                                     autoComplete="off"
                                     value={description}
                                     onChange={descriptionHandler}
                                 />
                                  {errDescRule !== '' && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                             <div className="text-right">
                                 <Button className="s-btn" type="button" disabled={loading} onClick={addRulesHandler}>
                                     {loading && (<Spinner animation='grow' />)}
                                     Add Rule
                                 </Button>
                             </div>
                             </Form>
                         </div>
                         </div>

                         <div className="col-md-6">
                        <div className="format-form">
                         <h6>Format</h6>
                             {addFormate.length > 0 && (<Table><thead><th>Title</th><th>Description</th></thead>
                                <tbody>
                                    {addFormate.map((item, i) => (
                                        <tr key={`{item-${i}}`}>
                                            <td>{item.title}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                             </Table>)}
                             <Form>
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Title</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Title"
                                     name="formateTitle"
                                     autoComplete="off"
                                     value={title1}
                                    onChange={title1Handler}
                                 />
                                   {errTitleFormate !== '' && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                            
                             <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="fromateDescription"
                        value={description1}
                        onKeyUp={(event) => addTags(event)}
                        onChange={description1Handler}
                        placeholder="press Enter to add multiple description"
                      />
                      <div className="tags-input">
                        <ul>
                          {tags.map((tag, index) => (
                            <li key={index}>
                              <span>{tag}</span>
                              <i
                                className="fa fa-times"
                                onClick={() => removeTags(index)}
                              ></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {errDescFormate !== '' && (<Alert variant='danger'>This field is required</Alert>)}
                    </Form.Group>
                             <div className="text-right">
                                 <Button className="s-btn" type="button" onClick={addFormateHandler} disabled={loading}>
                                     {loading && (<Spinner animation='grow' />)}
                                     Add Format
                                 </Button>
                             </div>
                             </Form>
                         </div>
                       </div>
                       <div className="col-md-12">
                             <div className="text-right">
                                 <Button className="s-btn" type="submit" disabled={loading}>
                                     {loading && (<Spinner animation='grow' />)}
                                     Add
                                 </Button>
                             </div>

                             </div>
                              

                     </div>
                 </Form>
         </div>
         <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Game Version Name</th>
                      <th>Rules</th>
                      <th>Format</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameVersionInfo &&
                      gameVersionInfo.map((item) => (
                        <tr key={item._id}>
                          <td>
                            {item.gameName}
                          </td>
                          <td>{item.rules.map((rule, i) => `${rule.title} ${item.rules.length-1  !==  i ?',': ''} `)}</td>
                          <td>{item.formate.map((rule, i) => `${rule.title} ${item.formate.length-1  !==  i ?',': ''} `)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
           
     </div>
    );
  };


  export default GameVersion;