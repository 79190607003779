import React, { useState, useEffect } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUsersDeletedList } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import {  Modal, Form } from "react-bootstrap";
import UsersDeleted from "./usersDeleted";
import UserDeletedTable from "./userDeletedTable";
import toast from "react-hot-toast";
import {  Alert, Spinner } from "react-bootstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { adminInstance } from "../../../axios";
import moment from "moment";
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);

const CancelUserManual = () => {
  const [selectDelete,setSelectDelete] = useState(false);

  const initialValues = {
    username: "",
    membershipid:"",
    email: "",
    manual:"",
    date:"",
    textarea:"",
    receivemail:""
  };
  
  const [values, setValues] = useState(initialValues);
  const [error ,setError] = useState('');

  const handleclick =(e)=>{
    console.log("helllo",e.target.value)
    setValues({
      ...values,
      manual: e.target.value,
    });
    setSelectDelete(true)
  }
  const handleCalendarDate =(date) => {
 
  let mydate = moment(date).format('YYYY-MM-DD');
  
    setValues({
      ...values,
      date: mydate,
    });
  }

const handleOnBlure=(e)=>{
  let regex=/^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/
  const {name,value}=e.target
  if(name==="email" && value===''){
    setError({[name]:"This field is required"})
  }else if(name==='email' && !regex.test(value)){
    setError({[name]:"Email is not valid"})
  }else{
    setError('')
  }
 console.log("eventt--->",e.target.value);
}
console.log("error is here--->",error)
  const handleChangeDelete = (e) => {
    const { name, value } = e.target;
    
  
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getHandleRequest = async(e) =>{
    e.preventDefault(); 
    const err = [];

    
    const { membership,username,email,manual ,date,textarea} = values;

    let datee;

    // if (date) {
    //   datee = moment(date).format('YYYY-MM-DD');
    // }

    // if (!username) return toast.error("Username is required",{toastId:1});
    // if (!membership) return toast.error("Membership is required");
    // if (!email) return toast.error("Email is required");


    console.log("myvalues",values)

    try{

    
    const response = await adminInstance.post("/mydeleterequest",values);
    console.log("response",response)
    if (response.status === 200) {
      toast.success("Delete Request added.");
      setValues(initialValues);
    }
    } catch (e) {
      toast.error( e && e.response.data.err);
      // setValues(initialValues);
    }


  }


  return (
    <>
      <div className="container">
      <h2 className="admin-title">
       
Add Delete Request</h2>
        <label>
          Request Source:
        </label>
        <select
                      class="form-control"
                      onChange={(e)=>handleclick(e)}
                      value={values.manual}
                    >
                      <option value="" selected disabled>
                        Select
                      </option>
                  
                   <option value="1">Postal mail</option>
                    <option value="2">Certified mail (signed for)</option>
                     <option value="3">Express package delivery service</option>
                    <option value="4">Email to: otrlegal@ontargetromance.com</option>
                    <option value="5">Email to: 2otrlegal@gmail.com</option>
                   <option value="6">Message via Contact us (non logged in)</option>
                    <option value="7">Message via Contact us (logged-in)</option>
             
                    </select>
        <div className="row">
          <div className="col-6">
       {selectDelete === true ? ( <>    <Form onSubmit={getHandleRequest}>
              <Form.Group className="my-3" controlId="formBasicName">
                <Form.Label>Username:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  onChange={handleChangeDelete}
                  value={values.username}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Membership ID:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter membership"
                  name="membershipid"
                  onChange={handleChangeDelete}
                  value={values.membershipid}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Membership email:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter receive"
                  name="receivemail"
                  // onBlur={handleOnBlure}
                  onChange={handleChangeDelete}
                  value={values.receivemail}
                  required
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email provided:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  name="email"
                  onBlur={handleOnBlure}
                  onChange={handleChangeDelete}
                  value={values.email}
                  
                />
              </Form.Group>
              {error && (
                        <Alert variant="danger">{error.email ? error.email :''}</Alert>
                      )}
              <Form.Group className="mb-3 delreq-date" controlId="formBasicDate">
                <Form.Label>Date:</Form.Label>
                {/* <Form.Control
                  type="date"
                  
                  name="date"
                  onChange={handleChangeDelete}
                  value={values.date}
        
                  
                /> */}
                 <DatePicker
                      
                      dateFormat="MM-YYYY-DD"
                      placeholderText="mm-dd-yy"
                      highlightDates={new Date()}
                      maxDate={moment().toDate()}
                    //  selected={values.date}
                    
                     name="date"
                     locale="en-gb"
                      onChange={(date)=>handleCalendarDate(date)}
                      value={values.date === "" ? "" : moment(values.date).format(
                        'MM-DD-YYYY'
                      )}
               
            
                    />

              </Form.Group>
            

              <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Note:</Form.Label>
                <Form.Control
                  type="textarea"
                  placeholder="Enter note"
                  name="textarea"
                  onChange={handleChangeDelete}
                  value={values.textarea}
        
                />
              </Form.Group>

              <Button variant="primary" type="submit">
              Add Delete Request
              </Button>
            </Form> </>):''}
      
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelUserManual;
