import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { getmycalendar } from "../../../actions/adminAction.js";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import Loader from "../../loader/loader";
import UserPopup from "../popup/user";
import AdminWrapper from "../adminwrapper/wrapper.jsx";

const MyCalendar = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const mycalendarData = useSelector((state) => state.unreadNotificationAdmin);
  console.log("mycalendarData", mycalendarData);
  const { error, list, totalCount, loading } = mycalendarData;
  console.log("Total Data data-->>", list);
  //console.log('KKKK>>',dataa);
  const [dataa, setDataa] = useState("");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
  };
  console.log("data==============a", dataa);
  //   useEffect(() => {
  //     dispatch(getmatches(page, pagination, dataa));
  //     setShow(true);
  //   }, [dispatch, page, pagination, dataa]);
  useEffect(() => {
    dispatch(getmycalendar(page, pagination, dataa));
    setShow(true);
  }, [dispatch, page, pagination, dataa]);

  useEffect(() => {
    if (error) {
      toast.error(error, { id: "A" });
    }
  }, [error]);

  return (
    <>
      <AdminWrapper>
        <div className="admin-matchmaking">
          <div className="user-search-section">
            <h2 className="admin-title">{""}</h2>
            <div className="user-search">
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="search"
                  placeholder="Search Here..."
                  value={dataa}
                  onChange={(e) => setDataa(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="container">
            {loading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>EventId</th>
                        <th>UserId</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list && list.length !== 0 ? (
                        list.map((item, i) => (
                          <tr key={`item-${i}`}>
                            <td>{item.id || ""}</td>
                            {console.log("item", item)}
                            <td>
                              {item.author_last_name || ""}{" "}
                              {/* {item.author_first_name || ""}{" "} */}
                            </td>
                            <td>{item.email || ""}</td>

                            <td>
                              <div className="action-buttons">
                                <Button
                                  className="edit-btn"
                                  onClick={() => handleModal("user", item)}
                                >
                                  View{" "}
                                </Button>
                                {/* <Button
                                // onClick={() =>
                                //   handleModal("delete", item.email)
                                // }
                                className="delete-btn"
                              >
                                Delete
                              </Button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan="6">
                            No Records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            <div className="admin-pagination">
              {totalCount > 0 ? (
                totalCount > pagination ? (
                  <Pagination>
                    <Pagination.First onClick={() => setPage(1)} />
                    <Pagination.Prev
                      onClick={() => (page > 1 ? setPage(page - 1) : "")}
                    />
                    <Pagination.Item>{page}</Pagination.Item>
                    <Pagination.Next
                      onClick={() =>
                        page < Math.ceil(totalCount / pagination)
                          ? setPage(page + 1)
                          : ""
                      }
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(totalCount / pagination))
                      }
                    />
                  </Pagination>
                ) : (
                  " "
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <Modal
          className="login-popup"
          show={show === "user"}
          onHide={() => handleModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserPopup forwarded={"mycalendarEvents"} user={email} />
          </Modal.Body>
        </Modal>
      </AdminWrapper>
    </>
  );
};

export default MyCalendar;
