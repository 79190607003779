import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import AdminWrapper from "../adminwrapper/wrapper";
import { getAllBlastList } from "../../../actions/adminAction";
import Loader from "../../loader/loader";
import { Tab, Tabs } from "react-bootstrap";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import BlastTable from "./blasttable";

import { useTable } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

const AdminBlast = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [paymentPage, setPaymentPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const [listcopy, setListCopy] = useState([]);
  const blastListData = useSelector((state) => state.blastList);
  console.log("blastListData", blastListData);
  const { list, totalCount, loading } = blastListData;
  //   blastListData?.blastListData.list?.blastListData.list[0];
  //   console.log(blastListData);
  const paymentTransactionList = useSelector(
    (state) => state.paymentTransactionList
  );
  const {
    blastList: paymentList,
    totalCount: paymentCount,
    loading: paymentLoading,
  } = paymentTransactionList;

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const handleModal = (e, mail) => {
    setShow(e);
    setEmail(mail);
  };
  console.log("list===", list);

  useEffect(() => {
    console.log("hhiji");
    const search = window.location.href;
    const id = search.split("=")[1];
    dispatch(getAllBlastList(id));
    setShow(true);

    // list.filter(el => {
    //   const header = JSON.parse(el.description);
    //   if(header[1])
    // })
  }, [dispatch]);

  useEffect(() => {
    const arr = [];

    list &&
      list.forEach((el) => {
        if (el)
          arr.push({
            ...el,
            description: JSON.parse(el.description)[1].header,
            email_subject: el.email_details[0].email_subject,
            email_body: el.email_details[0].email_body,
          });
      });

    console.log("listCopy", arr);
    setListCopy(arr);
  }, [list]);

  // useEffect(() => {
  //   dispatch(getPaymentTransction(paymentPage, pagination,filterValue));
  // }, [dispatch, paymentPage, pagination, filterValue]);

  const handleFilter = (e) => {
    setFilterValue(e);
  };
  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const columns = [
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Member type",
      accessor: "membership_id",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.membership_id === "1" ? "Author premium" : ""}
            {cell.row.values.membership_id === "2" ? "Reader" : ""}
            {cell.row.values.membership_id === "3" ? "Blogger" : ""}
            {cell.row.values.membership_id === "4" ? "Vendor" : ""}
            {cell.row.values.membership_id === "5" ? "PR Firm" : ""}
            {cell.row.values.membership_id === "6" ? "Blogger premium" : ""}
            {cell.row.values.membership_id === "8" ? "Author " : ""}
          </div>
        );
      },
    },
    {
      Header: "Subject",
      accessor: "email_subject",
    },
    {
      Header: "Body",
      accessor: "email_body",
    },
    {
      Header: "Blast date",
      accessor: "blast_date",
      Cell: (cell) => {
        return (
          <div>{moment(cell.row.values.blast_date).format("MMM-DD-YYYY")}</div>
        );
      },
    },
    {
      Header: "Blast date",
      accessor: "title",
    },
    {
      Header: "Blast type",
      accessor: "blast_type",
      Cell: (cell) => {
        return (
          <span>
            {cell.row.values.blast_type === "release" ? "Book release " : ""}
            {cell.row.values.blast_type === "earned" ? "Follow " : ""}
            {cell.row.values.blast_type === "purchased" ? "Purchase " : ""}
          </span>
        );
      },
    },
    {
      Header: "Details",
      accessor: "blastTarget",
      Cell: (cell) => {
        console.log("Cell", cell);
        return (
          <span>
            {cell.row.values.blast_type === "release"
              ? text_truncate(cell.row.values.title, 30)
              : ""}
            {cell.row.values.blast_type === "earned"
              ? "Followers-" + cell.row.values.blastTarget
              : ""}
            {cell.row.values.blast_type === "purchased"
              ? "Purchase" +
                " " +
                moment(cell.row.values.purchased_date).format("MMM-DD-YY")
              : ""}
          </span>
        );
      },
    },
    {
      Header: "No. of Recipients",
      accessor: "reciever_count.length",
    },
    // {
    //   Header: "Blast subject",
    //   accessor: "",
    //   Cell: (cell) => {
    //     return <div>{text_truncate(cell.row.values.email_subject, 60)}</div>;
    //   },
    // },
    {
      Header: "Action",
      accessor: "",
      Cell: (cell) => {
        // console.log("cell", cell.row.values.is_active);
        return (
          <div className="action-buttons">
            <Button
              value={cell.row.values.id}
              // className="edit-btn"
              onClick={() => handleModal("user", cell.row.values)}
            >
              View{" "}
            </Button>
          </div>
        );
      },
    },
  ];

  // console.log('transactionList', transactionList)
  return (
    <AdminWrapper>
      <div className="admin-transaction">
        <div className="container">
          <h2 className="admin-title">Blasts</h2>
          <div className="admin-tab">
            <div className="transaction-search-box">
              <div className="user-search-section">
                <div className="user-search">
                  {/* <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="search"
                      placeholder="Search Here..."
                      value={filterValue}
                      onChange={(e) => handleFilter(e.target.value)}
                    />
                  </Form.Group> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {loading ? (
                    <Loader />
                  ) : (
                    list && (
                      <BlastTable
                        columns={columns}
                        data={listcopy && listcopy}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "delete"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeletePopup handleModal={handleModal} mail={email} type="user" />
        </Modal.Body>
      </Modal>

      <Modal
        className="login-popup blast-view-popup"
        show={show === "user"}
        onHide={() => handleModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"blastlisting"} user={email} />
        </Modal.Body>
      </Modal>
    </AdminWrapper>
  );
};

export default AdminBlast;
