import React, { useState } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { adminInstance } from  '../../../axios';


const GamePlatform = () => {
    const [loading, setLoading] = useState(false);
    const [gameId, setGameId] = useState('');
    const { register, handleSubmit, errors, reset } = useForm({ mode: 'onBlur' });

    const gameTypeData = useSelector(state => state.gameType);
    const { gameTypeInfo } = gameTypeData;

    const addPlatformHandler = async(values) => {
        setLoading(true);
        const res = await adminInstance.post('/addPlatform', {
            name: values.name,
            description: values.description,
            gameId: gameId
            });
        if(res.data.code === 200) {
            reset();
            setLoading(false);
            toast.success('Platform Added Successfully.');
        } else if (res.data.code === 401) {
            
            toast.error('Not Authorized!', {id: 'A'});
            localStorage.removeItem('admintoken');
            window.location.href = '/admin/login';
        }else {
            reset();
            setLoading(false);
            toast.error('Error Occured');
        }
    }

    const handleChange = (e) => {
        setGameId(e.target.value);
    }

    return (
            
        <div className="add-game-content">
        <div className="register-form">
                 <Form onSubmit={handleSubmit(addPlatformHandler)}>
                     <div className="row">
                        <div className="col-md-6">
                             <Form.Group controlId="formBasicFirst">
                                 <Form.Label>Game</Form.Label>
                                 <Form.Control as="select" onChange={(e) => handleChange(e)}>
                                 <option>Select Game</option>
                                     {gameTypeInfo && gameTypeInfo.map(item => (
                                         <option key={item._id} value={item._id}>{item.gameName}</option>
                                     ))}
                                 </Form.Control>
                             </Form.Group>
                         </div>

                         <div className="col-md-6">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Name</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Name"
                                     name="name"
                                     autoComplete="off"
                                     ref={register({ required: true })}
                                 />
                                 {errors.name && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                         <div className="col-md-12">
                             <Form.Group controlId="formBasicPassword">
                                 <Form.Label>Description</Form.Label>
                                 <Form.Control
                                     type="text"
                                     placeholder="Description"
                                     name="description"
                                     autoComplete="off"
                                     ref={register({ required: true })}
                                 />
                                 {errors.description && (<Alert variant='danger'>This field is required</Alert>)}
                             </Form.Group>
                         </div>

                        

                         <div className="col-md-12">
                             <div className="text-right">
                                 <Button className="s-btn" type="submit" disabled={loading}>
                                 {loading && (<Spinner animation='grow' />)}
                                     Add
                                 </Button>
                             </div>

                         </div>


                     </div>
                 </Form>
         </div>
     </div>
    );
  };


  export default GamePlatform;