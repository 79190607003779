import React from "react";
import "./myprofile.css";

import Layout from "./layout";
import MyProfileMenu from "./menu";
import AdminWrapper from "../adminwrapper/wrapper";

const AdminMyProfile = (prop) => {
  return (
    <AdminWrapper>
      <div className="my-profile">
        <div className="container">
          <h2 className="admin-title">Account Setting</h2>

          <div className="my-profile-container">
            <div className="my-profile-sidebar">
              <MyProfileMenu />
            </div>

            <div className="my-profile-content">{prop.children}</div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminMyProfile;
