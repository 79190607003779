import React, { useState, useEffect } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { FollowList, blockUser } from "../../../actions/adminAction";
import AdminWrapper from "../adminwrapper/wrapper";
import UserPopup from "../popup/user";
import DeletePopup from "../popup/delete";
import Loader from "../../loader/loader";
import FollowTable from "./followTable";

//import { useTable } from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";

const Follow = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [keyword, setKeyword] = useState("");

  const handleModal = (e, mail) => {
    console.log("e, mail", e, mail);
    setShow(e);
    setEmail(mail);
  };
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);
  const [listcopy, setListCopy] = useState([]);
  const dispatch = useDispatch();
  const { error, list, loading } = useSelector((state) => state.followList);
  console.log("list", list);

  //const { list } = userList;
  useEffect(() => {
    if (error) toast.error(error, { id: "A" });
    dispatch(FollowList());
    setShow(true);
  }, [dispatch, error, pagination, page, keyword]);

  useEffect(() => {
    const arr = [];
    list &&
      list.forEach((el) => {
        let memberType = "";
        if (el.membership_id === "1") {
          memberType = "Author Premium";
        }
        if (el.membership_id === "2") {
          memberType = "Reader";
        }
        if (el.membership_id === "3") {
          memberType = "Blogger";
        }
        if (el.membership_id === "4") {
          memberType = "Vendor";
        }
        if (el.membership_id === "5") {
          memberType = "PR Firm";
        }
        if (el.membership_id === "6") {
          memberType = "Blogger premium";
        }
        if (el.membership_id === "8") {
          memberType = "Author";
        }

        arr.push({
          ...el,
          membership_id: memberType,
        });
      });
    setListCopy(arr);
  }, [list]);

  console.log("listCopy", listcopy);

  const columns = [
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: " ID",
      accessor: "id",
    },
    {
      Header: "Username",
      accessor: "display_name",
    },
    {
      Header: "Type",
      accessor: "membership_id",
    },

    {
      Header: "Follow count",
      accessor: "follow_count",
    },
    {
      Header: "INVITE#",
      accessor: "follow_code",
    },
    {
      Header: "Times code used",
      accessor: "countusedinvitecode",
    },
    // {
    //   Header: "Action",
    //   accessor: "",
    //   Cell: (cell) => {
    //     // console.log("cell", cell.row.values.is_active);
    //     return (
    //       <div className="action-buttons">
    //         <Button
    //           value={cell.row.values.id}
    //           // className="edit-btn"
    //           onClick={() => handleModal("follow", cell.row.values)}
    //         >
    //           View{" "}
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <AdminWrapper>
      <div className="admin-user">
        <div className="container">
          <div className="user-search-section">
            <h2 className="admin-title">Follow(INVITE#)</h2>
            <div className="user-search"></div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <Loader />
              ) : (
                list && (
                  <FollowTable columns={columns} data={listcopy && listcopy} />
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="login-popup"
        show={show === "follow"}
        onHide={() => handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserPopup forwarded={"follow"} user={email} />
        </Modal.Body>
      </Modal>
    </AdminWrapper>
  );
};

export default Follow;
