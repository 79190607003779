import * as userConstants from '../constants/userConstants';
import { gameInstance } from '../axios';
export const getEntryFee = () => async (dispatch) => {
    dispatch({
      type: userConstants.GET_ENTRY_FEE_REQUEST,
    });
  
    const { data } = await gameInstance.get('/getAllEntryFee');
    const { code } = data;
    if (code === 200) {
      dispatch({
        type: userConstants.GET_ENTRY_FEE_SUCCESS,
        payload: data.data,
      });
    }else if(code === 401) {
      dispatch(notAuthorized())
      dispatch({ 
        type: userConstants.GET_ENTRY_FEE_FAIL,
        payload: data.msg
      })
    }else{
      dispatch({ 
        type: userConstants.GET_ENTRY_FEE_FAIL,
        payload: data.msg
      })
    }
  };

  export const getGameType = () => async (dispatch) => {
    dispatch({
      type: userConstants.GET_GAME_REQUEST,
    });
  
    const { data } = await gameInstance.get('/getAllGame');
    const { code } = data;
    if (code === 200) {
      dispatch({
        type: userConstants.GET_GAME_SUCCESS,
        payload: data.games,
      });
    }else if(code === 401) {
      dispatch(notAuthorized())
      dispatch({ 
        type: userConstants.GET_GAME_FAIL,
        payload: data.msg
      })
    }else{
      dispatch({ 
        type: userConstants.GET_GAME_FAIL,
        payload: data.msg
      })
    }
  };

  export const getGamePlatform = (gameId) => async (dispatch) => {
    dispatch({
      type: userConstants.GET_PLATFORM_REQUEST,
    });
    const { data } = await gameInstance.post('/getAllPlatformByGameId', { gameId });
    const { code } = data;
    if (code === 200) {
      dispatch({
        type: userConstants.GET_PLATFORM_SUCCESS,
        payload: data.platforms,
      });
    }else if(code === 401) {
      dispatch(notAuthorized())
      dispatch({ 
        type: userConstants.GET_PLATFORM_FAIL,
        payload: data.msg
      })
    }else{
      dispatch({ 
        type: userConstants.GET_PLATFORM_FAIL,
        payload: data.msg
      })
    }
  };

  export const getGameVersionByPlatform = (platformId) => async (dispatch) => {
    dispatch({
      type: userConstants.GET_GAME_VERSION_REQUEST,
    });
    const { data } = await gameInstance.post('/getAllGameVersionByPlatform', { platformId });
    const { code } = data;
    if (code === 200) {
      dispatch({
        type: userConstants.GET_GAME_VERSION_SUCCESS,
        payload: data.games,
      });
    }else if(code === 401) {
      dispatch(notAuthorized())
      dispatch({ 
        type: userConstants.GET_GAME_VERSION_FAIL,
        payload: data.msg
      })
    }else{
      dispatch({ 
        type: userConstants.GET_GAME_VERSION_FAIL,
        payload: data.msg
      })
    }
  };

  export const getGameVersion = () => async (dispatch) => {
    dispatch({
      type: userConstants.GAME_VERSION_REQUEST,
    });
    const { data } = await gameInstance.get('/getAllGameVersion');
    const { code } = data;
    if (code === 200) {
      dispatch({
        type: userConstants.GAME_VERSION_SUCCESS,
        payload: data.games,
      });
    }else if(code === 401) {
      dispatch(notAuthorized())
      dispatch({ 
        type: userConstants.GAME_VERSION_FAIL,
        payload: data.msg
      })
    }else{
      dispatch({ 
        type: userConstants.GAME_VERSION_FAIL,
        payload: data.msg
      })
    }
  };

  const notAuthorized = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('admintoken');
    
  }