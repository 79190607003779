import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminInstance } from "../../../axios";
import AdminWrapper from "../adminwrapper/wrapper";
import Loader from "../../loader/loader";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment";
import PoliciesTable from "./PoliciesTable";
import { getAllPolicyList } from "../../../actions/adminAction";

const AdminMatchList = () => {
  const dispatch = useDispatch();

  const [eventKey, setEventKey] = useState("list");
  const [keyword] = useState();
  const [page, setPage] = useState(1);
  const [pagination] = useState(10);

  const { list, loading } = useSelector((state) => state.policyList);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const search = window.location.href;
    const id = search.split("=")[1];
    dispatch(getAllPolicyList(id));
    setShow(true);
  }, [page, pagination]);

  const convertToShort = (string) => {
    if (string.length > 9) {
      string = string.substring(0, 9) + "...";
    }
    return string;
  };

  console.log(list);

  const columns = [
    {
      Header: "Date/Time (EST)",
      accessor: "createdAt",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.createdAt &&
              moment(cell.row.values.createdAt).format("MMM-DD-YYYY, hh:mm a")}
          </div>
        );
      },
    },
    {
      Header: "Member ID",
      accessor: "member_id",
    },
    {
      Header: "Username",
      accessor: (row) => (row.display_name ? row.display_name : row.betaUser),
    },

    // {
    //   Header: "Time",
    //   accessor: "",
    // },
    {
      Header: "Consent Type",
      accessor: "consent_type",
    },
    {
      Header: "Cause",
      accessor: "reason",
    },
    {
      Header: "Reference name",
      accessor: "reference",
    },
    {
      Header: "Version",
      accessor: "version",
      Cell: (cell) => {
        return (
          <div>
            {cell.row.values.version &&
            cell.row.values.consent_type === "Terms & Conditions"
              ? `T${cell.row.values.version}`
              : cell.row.values.consent_type === "Privacy Policy"
              ? `P${cell.row.values.version}`
              : cell.row.values.consent_type === "Cookie Policy"
              ? 'N/A'
              : null}
          </div>
        );
      },
    },
    {
      Header: "Source",
      accessor: "source",
    },

    // {
    //   Header: "Consent Body",
    //   accessor: "consent_body",
    //   Cell: (cell) => {
    //     return <p>{convertToShort(cell.row.values.consent_body)}</p>;
    //   },
    // },
    {
      Header: "IP address",
      accessor: "ip",
    },
  ];

  return (
    <AdminWrapper>
      <div className="sub-admin">
        <div className="user-search-section">
          <h2 className="admin-title">{""}</h2>
        </div>
        <div className="container">
          <h2 className="admin-title"> Consent Policies</h2>

          <div className="admin-tab">
            <div className="transaction-search-box">
              <div className="row">
                <div className="col-md-12">
                  {loading ? (
                    <Loader />
                  ) : (
                    list && (
                      <PoliciesTable columns={columns} data={list && list} />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default AdminMatchList;
