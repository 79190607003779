import React, { useEffect } from "react";
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import "../adminforgetpassword/forget.css";

import MyProfile from "./myprofile";
import { adminchangePassword } from "../../../actions/adminAction";




const Forget = () => {
    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
    const dispatch = useDispatch();
 
    const history = useHistory();
    const changePasswordData = useSelector(state => state.changeAdminPassword);
    const { loading, error, editData } = changePasswordData;

    const handleChangePassword = (values) => {
        if(values.newpassword === values.confirmpassword)
         {
             dispatch(adminchangePassword(values.newpassword))
        } 
else{
        toast.error("Password do not match! retry")
}
    }

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
        if (editData) {
            toast.success(editData);
            localStorage.removeItem('admintoken');
            history.push('/admin/login')
          
        }
    }, [error,  changePasswordData, editData, history])

    return (
        <MyProfile>
      <div className="forget-form">
        <h1>
          Reset <span>Password</span>
        </h1>
        <div className="reset-box">
          <Form onSubmit={handleSubmit(handleChangePassword)}>
            <Form.Group controlId="formBasicloginone">
              <Form.Control
                type="password"
                placeholder="New Password"
                name="newpassword"
                ref={register({ required: true })}
              />
             
            </Form.Group>

            <Form.Group controlId="formBasicloginone">
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="confirmpassword"
                ref={register({ required: true })}
              />
              {errors.otp && (
                <Alert variant="danger">This field is required</Alert>
              )}
            </Form.Group>

            <Button className="reset-btn" type="submit" disabled={loading}>
            { loading ? (<Spinner animation="border" />) : "Reset Password"}
              
            </Button>
          </Form>
        </div>
      </div>
      </MyProfile>
    );
};

export default Forget;